import axios from 'axios'
import Store from '../../store'
import {
  errorHandler,
  generateNoOptionsQuery,
  successHandler
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/airtime`

export default {
  airtime() {
    return {
      getAll: async ({ populate = [] }) => {
        const endpoint = `${URL}/user${generateNoOptionsQuery(populate)}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
