var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mb-5"},[_vm._v("Declined Purchase")]),_c('user-details-min',{attrs:{"userId":_vm.minUserId,"open":_vm.openMin},on:{"update:open":function($event){_vm.openMin=$event}}}),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.arrangedTransaction,"footer-props":{
            itemsPerPageOptions: [5, 10, 15, 25, 50]
          },"server-items-length":_vm.apiData.total,"loading":_vm.loadingTransaction,"items-per-page":_vm.perPage},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.buyer",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block",on:{"click":function($event){return _vm.openMinDialog(item.buyer_id)}}},[_vm._v(" "+_vm._s(item.buyer)+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                  ? 'success'
                  : item.status == 'pending'
                  ? 'warning'
                  : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.currency",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.currency))])]}},{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_c('small',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.date))])]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" "),_c('small',{staticClass:"grey--text text--darken-2"},[_vm._v("NGN")])])]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v("$")]),_vm._v(" "+_vm._s(item.amount))])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":function($event){return _vm.selectTransaction(item)}}},[_vm._v("View Details")])]}}],null,true)}),_c('transaction-details',{attrs:{"open":_vm.transactionOpen,"transaction":_vm.transaction,"type":"purchase"},on:{"update:open":function($event){_vm.transactionOpen=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }