<template>
  <v-container>
    <h1 class="mb-5">Airtime</h1>
    <v-dialog v-model="createDialog" max-width="600">
      <v-form ref="createAccount" @submit.prevent="validateCreateAccount">
        <v-card>
          <v-card-title>Create Airtime</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Provider Name"
                  v-model="newAirtime.name"
                  filled
                  readonly
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Provider Code"
                  hint="01 for MTN, 02 for GLO, 03 for 9mobile, 04 for Airtel"
                  readonly
                  v-model="newAirtime.code"
                  :rules="[rules.required]"
                  filled
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  filled
                  label="Amounts"
                  hint="Enter amounts available, separate with commas"
                  persistent-hint
                  suffix="NGN"
                  v-model="newAirtime.amounts"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                type="submit"
                :loading="loadingCreate"
                color="accent"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card flat>
      <v-container>
        <v-toolbar flat>
          <v-card-title>Available Airtime</v-card-title>
          <v-spacer></v-spacer>
          <!-- <v-btn @click.stop="createDialog = true" color="accent" depressed>
            <v-icon left>mdi-plus</v-icon>
            Create New</v-btn
          > -->
        </v-toolbar>
        <!-- <v-card-subtitle
          >Active airtime accounts will show up when users whats to make
          payment</v-card-subtitle
        > -->
        <v-card-text>
          <v-data-table
            :loading="airtime.length == 0"
            :items="airtime"
            :headers="headers"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="editAirtime(item)" ripple>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            label="Airtime Rate"
            suffix="%"
            persistent-hint
            hint="Percentage to be used from airtime prices"
            filled
            type="number"
            :disabled="loadingData"
            v-model="siteData.airtimeRate"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Data Rate"
            suffix="%"
            :disabled="loadingData"
            persistent-hint
            hint="Percentage to be used from data rates"
            filled
            type="number"
            v-model="siteData.dataRate"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-btn :loading="loadingData" color="accent" @click="updateData"
      >Update</v-btn
    >
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../../api/systemData'
import rules from '../../../utils/rules'

export default {
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    deleteType: {},
    createDialog: false,
    loadingCreate: false,
    newAirtime: {},
    rules: {
      required: rules.required
    },
    deleteProgress: 'starting',
    message: '',
    data: {},
    headers: [
      {
        value: 'name',
        text: 'Carrier Name'
      },
      {
        value: 'amounts',
        text: 'Amounts'
      },
      {
        value: 'action',
        text: 'Actions'
      }
    ],
    airtime: [],
    selectedBank: {},
    loadingEdit: false,
    loadingData: false
  }),
  created() {
    this.getAirtime()
  },
  computed: {
    ...mapState({
      siteData: (state) => state.admin.siteSettings
    })
  },
  methods: {
    editAirtime(card) {
      this.newAirtime = {
        ...card,
        amounts: card.amounts ? card.amounts.join(',') : ''
      }
      this.createDialog = true
    },
    validateCreateAccount() {
      if (this.$refs.createAccount.validate()) this.editBankAccount()
    },
    async editBankAccount() {
      this.loadingCreate = true
      const res = await dataApi.data().editSystemData(
        { type: 'mobilenetwork', typeId: this.newAirtime._id },
        {
          ...this.newAirtime,
          amounts: this.newAirtime.amounts.split(',')
        }
      )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })

        return
      }

      this.getAirtime()
      this.$store.dispatch('alert', {
        message: 'Airtime updated successfully',
        error: false,
        status: true
      })
      this.loadingCreate = false
      this.createDialog = false
    },
    async getAirtime() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'mobilenetwork' })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable to get airtime list, please check your Internet',
          error: true,
          status: true
        })
        setTimeout(() => {
          this.getAirtime()
        }, 5000)
        return
      }

      this.airtime = res.data.data.data
    },
    async updateData() {
      this.loadingData = true
      const res = await dataApi.data().updateSite(this.siteData)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loadingData = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Updated successfully',
        error: false,
        status: true
      })
      this.loadingData = false
    }
  }
}
</script>

<style></style>
