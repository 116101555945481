import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/wallet`

export default {
  data() {
    return {
      getWallet: async () => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      initiateWalletFunding: async (amount) => {
        const endpoint = `${URL}/fund-request`
        try {
          const res = await axios.post(
            endpoint,
            { amount },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyWalletFunding: async ({ id }) => {
        const endpoint = `${URL}/fund-request/${id}/verify`
        try {
          const res = await axios.post(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      withdrawFromWallet: async ({
        amount,
        withdrawMethodId,
        transactionPin,
        bankId,
        otp
      }) => {
        const endpoint = `${URL}/withdraw`
        const data = { amount, withdrawMethodId, transactionPin, bankId, otp }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getFundingRequest: async () => {
        const endpoint = `${URL}/user-fund-requests`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      walletHistory: async (params) => {
        const endpoint = `${URL}/history`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
