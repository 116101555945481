<template>
  <v-container>
    <h1 class="mb-5">Pending Purchase</h1>
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <v-row>
      <v-col>
        <v-card flat color="white">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="arrangedTransaction"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
            }"
            @update:items-per-page="updateItemPerPage"
            @update:page="updatePage"
            :server-items-length="apiData.total"
            :items-per-page="perPage"
          >
            <template v-slot:no-data>
              <div class="py-3 text-center">
                <v-img
                  src="@/assets/img/undraw_empty.svg"
                  width="325"
                  class="mx-auto"
                  alt="empty"
                />
                <h2 class="mt-3">No pending Purchase</h2>
                <p>You are done here</p>
              </div>
            </template>
            <template v-slot:[`item.buyer`]="{ item }">
              <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.buyer_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.buyer }}</router-link
              > -->
              <span
                class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                @click="openMinDialog(item.buyer_id)"
              >
                {{ item.buyer }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
              <span class="font-weight-medium">{{ item.currency }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <small class="font-weight-medium">{{ item.date }}</small>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span
                >{{ item.price }}
                <small class="grey--text text--darken-2">NGN</small></span
              >
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>
                <span class="grey--text text--darken-2">$</span>
                {{ item.amount }}</span
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small depressed @click="selectTransaction(item)"
                >View Details</v-btn
              >
            </template>
          </v-data-table>
          <!-- <delete-dialog :open.sync="dialogDelete" type="purchase" /> -->
          <transaction-details
            :progress.sync="progress"
            :open.sync="transactionOpen"
            :transaction="transaction"
            type="purchase"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import deleteDialog from '../../../components/deleteDialog.vue'
import TransactionDetails from '../../../components/transactionDetails.vue'
import transactionApi from '../../../api/admin/transactions'
import { numberWithCommas } from '../../../utils/helpers'
import UserDetailsMin from '../../../components/userDetailsMin.vue'

export default {
  components: {
    // deleteDialog,
    TransactionDetails,
    UserDetailsMin
  },
  data: () => ({
    openMin: false,
    minUserId: '',
    search: '',
    loading: false,
    dialogDelete: false,
    transactionOpen: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Buyer',
        sortable: false,
        value: 'buyer'
      },
      {
        text: 'Currency',
        value: 'currency',
        sortable: false
      },
      {
        text: 'Price (NGN)',
        value: 'price'
      },
      {
        text: 'Price (USD)',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false
      }
    ],
    transactions: [],
    selectedTransaction: {},
    transaction: {},
    progress: 'starting',
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1
  }),
  created() {
    this.getTransactions(0)
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') this.getTransactions()
      this.progress = 'starting'
    }
  },
  computed: {
    arrangedTransaction() {
      if (this.transactions.length !== []) {
        return this.transactions.map((transaction) => ({
          id: transaction._id,
          date: transaction.date_string,
          buyer: transaction.user.username,
          buyer_id: transaction.user._id,
          status: transaction.status,
          currency: transaction.coin
            ? transaction.coin.name
            : transaction.currency
            ? transaction.currency.name
            : '',
          price: numberWithCommas(transaction.main_amo),
          amount: numberWithCommas(transaction.amount)
        }))
      }
      return []
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loading = true
      const res = await transactionApi.transactions().getAll({
        type: 'purchase',
        status: 'pending',
        populate: ['user', 'coin', 'method'],
        category: 'coin',
        limit: this.perPage,
        offset: length
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loading = false
    },
    selectTransaction(transx) {
      const transaction = this.transactions.find((trx) => trx._id === transx.id)

      this.transaction = {
        ...transaction,
        image: transaction.image,
        date: transaction.date_string,
        time: transaction.time_string,
        status: transaction.status,
        transactionInfo: [
          {
            title: 'Transaction Type',
            text: `${transaction.type}`
          },
          {
            title: 'Amount',
            text: `${numberWithCommas(transaction.amount)} USD`
          },
          {
            title: 'Cryptocurrency',
            text: `${
              transaction.coin
                ? transaction.coin.name
                : transaction.currency
                ? transaction.currency.name
                : ''
            }`
          },
          {
            title: 'Amount in NGN',
            text: `${numberWithCommas(transaction.price)} NGN`
          },
          {
            title: 'Transaction ID',
            text: `${transaction.trx}`
          },
          {
            title: 'Details',
            text: `${transaction.type} Cryptocurrency`
          }
        ],
        transactionDetails: [
          {
            title: 'Payment Method',
            text: `${transaction.method ? transaction.method.name : ''}`
          },
          {
            title: `Amount Paid by ${
              transaction.depositor ?? transaction.user.username
            }`,
            text: `${numberWithCommas(transaction.amountpaid)} NGN`
          },
          {
            title: 'Transfer Fee Charge',
            text: `${numberWithCommas(transaction.charge)} NGN`
          },
          // {
          //   title: 'Transfer Fee Charge',
          //   text: `${numberWithCommas(transaction.tcharge)} NGN`
          // },
          {
            title: 'Stamp Duty',
            text: `0 NGN`
          },
          {
            title: `${transaction?.coin?.symbol} to Receive`,
            text: `${transaction.getamo}`
          },
          {
            title: 'Amount to Credit',
            text: `${numberWithCommas(transaction.amount)} worth of ${
              transaction.coin !== null ? transaction.coin.name : ''
            }`
          },
          {
            title: 'Payment To Wallet',
            text: `${transaction.wallet}`
          },
          {
            title: 'Network Type',
            text: `${transaction?.cart?.network?.name}`
          },
          {
            title: "Depositor's Name",
            text: `${transaction.depositor ?? transaction.user.username}`
          }
        ]
      }
      this.transactionOpen = true
    }
    // async getATransaction(id) {
    //   const res = await transactionApi.transactions().getSingle(id)
    //   // console.log(res)
    // }
  }
}
</script>

<style></style>
