var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.uploadingImage,"opacity":"0.8"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('v-toolbar',{attrs:{"flat":"","outlined":""}},[_c('h2',[_vm._v("Create Giftcard")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left")]),_vm._v(" Back")],1)],1),_c('v-sheet',{staticClass:"pt-4"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Card Name"},model:{value:(_vm.card.name),callback:function ($$v) {_vm.$set(_vm.card, "name", $$v)},expression:"card.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"loading":_vm.sendingImage,"filled":"","label":"Card Image"},model:{value:(_vm.card.imageFile),callback:function ($$v) {_vm.$set(_vm.card, "imageFile", $$v)},expression:"card.imageFile"}})],1)],1),_c('v-divider'),_c('div',{staticClass:"my-4"},[_c('p',[_vm._v(" Select a country and a currency, click on "),_c('b',[_vm._v("Add Card Options")]),_vm._v(" to add other card details ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"filled":"","loading":_vm.countries.length === 0,"items":_vm.countries,"item-text":"name","item-value":"_id","label":"Select County"},model:{value:(_vm.temp.country),callback:function ($$v) {_vm.$set(_vm.temp, "country", $$v)},expression:"temp.country"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"filled":"","loading":_vm.currencies.length === 0,"items":_vm.currencies,"item-text":"name","item-value":"_id","label":"Select Currency"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" | "+_vm._s(item.symbol)+" ")])]}}]),model:{value:(_vm.temp.currency),callback:function ($$v) {_vm.$set(_vm.temp, "currency", $$v)},expression:"temp.currency"}})],1)],1)],1),_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{attrs:{"disabled":_vm.temp.country === '' || _vm.temp.currency === '',"depressed":"","text":"","color":"accent"},on:{"click":_vm.addCardOption}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Card Options ")],1),(_vm.card.options.length > 0)?_c('v-btn',{attrs:{"depressed":"","text":"","color":"error"},on:{"click":function () {
                _vm.card.options.pop()
              }}},[_vm._v(" Undo ")]):_vm._e()],1),_c('div',{staticClass:"extended-tabs"},[_c('v-tabs',{on:{"change":_vm.changeCountryTab},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.card.options),function(option,i){return _c('v-tab',{key:i},[_vm._v(_vm._s(_vm.countries.find(function (c) { return c._id === option.country; }).name)+" | "+_vm._s(_vm.currencies.find(function (c) { return c._id === option.currency; }).symbol)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.card.options),function(option,i){return _c('v-tab-item',{key:i},[_c('v-container',[_c('h5',[_vm._v(" County: "+_vm._s(_vm.countries.find(function (c) { return c._id === option.country; }).name)+" ")]),_c('h5',[_vm._v(" Currency: "+_vm._s(_vm.currencies.find(function (c) { return c._id === option.currency; }).name)+" ")]),_c('p',{staticClass:"my-2"},[_vm._v(" Select a card type, you can add more one by clicking on add more ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.giftcardTypes,"loading":_vm.giftcardTypes.length === 0,"filled":"","item-value":"_id","item-text":"name","label":"Select card type","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"tile":"","color":"accent"}},[_c('v-img',{attrs:{"src":item.image,"alt":"item.name"}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.name)}})]}}],null,true),model:{value:(_vm.temp.type),callback:function ($$v) {_vm.$set(_vm.temp, "type", $$v)},expression:"temp.type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Minimum Sell","filled":"","suffix":"$"},model:{value:(_vm.temp.minimumSell),callback:function ($$v) {_vm.$set(_vm.temp, "minimumSell", $$v)},expression:"temp.minimumSell"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Default Naira Rate","hint":"Default Naira rate to use for giftcard type","filled":"","suffix":"N"},model:{value:(_vm.temp.defaultValue),callback:function ($$v) {_vm.$set(_vm.temp, "defaultValue", $$v)},expression:"temp.defaultValue"}})],1)],1),_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{attrs:{"disabled":_vm.temp.type === '' ||
                      _vm.temp.minimumSell === '' ||
                      _vm.temp.defaultValue === '',"depressed":"","text":"","color":"accent"},on:{"click":function($event){return _vm.addCardOptionType(i)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Card Type Options ")],1),(_vm.card.options[i].cardTypes.length > 0)?_c('v-btn',{attrs:{"depressed":"","text":"","color":"error"},on:{"click":function () {
                        _vm.card.options[i].cardTypes.pop()
                      }}},[_vm._v(" Undo ")]):_vm._e()],1),_c('div',{staticClass:"extended-tab"},[_c('v-tabs',{model:{value:(_vm.tabsCardTypes[i]),callback:function ($$v) {_vm.$set(_vm.tabsCardTypes, i, $$v)},expression:"tabsCardTypes[i]"}},_vm._l((_vm.card.options[i].cardTypes),function(option){return _c('v-tab',{key:option._id},[_vm._v(_vm._s(option.cardType)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabsCardTypes[i]),callback:function ($$v) {_vm.$set(_vm.tabsCardTypes, i, $$v)},expression:"tabsCardTypes[i]"}},_vm._l((_vm.card.options[i].cardTypes),function(option,a){return _c('v-tab-item',{key:a},[_c('v-container',[_c('h5',[_vm._v("Card Type: "+_vm._s(option.cardType))]),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"range"},[_c('div',[_c('v-row',[_c('v-col',{staticClass:"border-r",staticStyle:{"border-right":"1px solid #333"},attrs:{"cols":"12","md":"6"}},[_c('p',[_vm._v("Add Rate")]),_vm._l((_vm.card.options[i]
                                      .cardTypes[a].options),function(rate,r){return _c('div',{key:r},[(
                                        !_vm.card.options[i].cardTypes[a].options[
                                          r
                                        ].default &&
                                        _vm.card.options[i].cardTypes[a].options[
                                          r
                                        ].isVariable
                                      )?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"prefix":_vm.prefixToUse,"label":"Minimum price"},model:{value:(
                                            _vm.card.options[i].cardTypes[a]
                                              .options[r].minimum
                                          ),callback:function ($$v) {_vm.$set(_vm.card.options[i].cardTypes[a]
                                              .options[r], "minimum", $$v)},expression:"\n                                            card.options[i].cardTypes[a]\n                                              .options[r].minimum\n                                          "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"prefix":_vm.prefixToUse,"label":"Maximum price"},model:{value:(
                                            _vm.card.options[i].cardTypes[a]
                                              .options[r].maximum
                                          ),callback:function ($$v) {_vm.$set(_vm.card.options[i].cardTypes[a]
                                              .options[r], "maximum", $$v)},expression:"\n                                            card.options[i].cardTypes[a]\n                                              .options[r].maximum\n                                          "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"prefix":"₦","label":"Naira Rate"},model:{value:(
                                            _vm.card.options[i].cardTypes[a]
                                              .options[r].nairaRate
                                          ),callback:function ($$v) {_vm.$set(_vm.card.options[i].cardTypes[a]
                                              .options[r], "nairaRate", $$v)},expression:"\n                                            card.options[i].cardTypes[a]\n                                              .options[r].nairaRate\n                                          "}})],1)],1):_vm._e()],1)}),_c('div',{staticClass:"d-flex flex-col justify-end align-end"},[_c('p',{staticClass:"mx-2 cursor-pointer font-weight-medium",on:{"click":function($event){return _vm.addMorePrices(i, a)}}},[_vm._v(" Add more ")])])],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('p',[_vm._v("Add Prices")]),_vm._l((_vm.card.options[i]
                                      .cardTypes[a].options),function(rate,r){return _c('div',{key:r},[(
                                        !_vm.card.options[i].cardTypes[a].options[
                                          r
                                        ].default &&
                                        !_vm.card.options[i].cardTypes[a].options[
                                          r
                                        ].isVariable
                                      )?_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"prefix":_vm.prefixToUse,"label":"Value"},model:{value:(
                                            _vm.card.options[i].cardTypes[a]
                                              .options[r].value
                                          ),callback:function ($$v) {_vm.$set(_vm.card.options[i].cardTypes[a]
                                              .options[r], "value", $$v)},expression:"\n                                            card.options[i].cardTypes[a]\n                                              .options[r].value\n                                          "}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"prefix":"₦","label":"Rate"},model:{value:(
                                            _vm.card.options[i].cardTypes[a]
                                              .options[r].nairaRate
                                          ),callback:function ($$v) {_vm.$set(_vm.card.options[i].cardTypes[a]
                                              .options[r], "nairaRate", $$v)},expression:"\n                                            card.options[i].cardTypes[a]\n                                              .options[r].nairaRate\n                                          "}})],1)],1):_vm._e()],1)}),_c('div',{staticClass:"d-flex flex-col justify-end align-end"},[_c('p',{staticClass:"mx-2 cursor-pointer font-weight-medium",on:{"click":function($event){return _vm.addMorePricesFixed(i, a)}}},[_vm._v(" Add more ")])])],2)],1)],1)]),_c('div',{staticClass:"d-flex flex-col mt-5 justify-center align-end"},[(
                                _vm.card.options[i].cardTypes[a].options.length >
                                0
                              )?_c('p',{staticClass:"mx-2 error--text cursor-pointer font-weight-medium",on:{"click":function () {
                                  _vm.card.options[i].cardTypes[a].options.pop()
                                }}},[_vm._v(" Undo ")]):_vm._e()])])])],1)}),1)],1)],1)],1)}),1)],1)]),_c('v-btn',{staticClass:"ma-4",attrs:{"color":"primary"},on:{"click":_vm.createGiftcard}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }