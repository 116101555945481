import axios from 'axios'
import Store from '../store'
import { errorHandler, successHandler } from './helpers/handler'
import { BASEURL } from './const'

const URL = `${BASEURL}/notifications`

export default {
  notification() {
    return {
      getForUser: async (userId) => {
        const endpoint = `${URL}?user=${userId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      markAsReadUser: async (ids = []) => {
        const endpoint = `${URL}/mark/read`
        try {
          const res = await axios.post(
            endpoint,
            { idArr: ids },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getForAdmin: async () => {
        const endpoint = `${URL}/admin?forAdmin=true`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      markAsReadAdmin: async (ids = []) => {
        const endpoint = `${URL}/admin/mark/read`
        try {
          const res = await axios.post(
            endpoint,
            { idArr: ids },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendNotificationToAllUsers: async ({ title, message }) => {
        const endpoint = `${URL}/send-to-all`
        try {
          const res = await axios.post(
            endpoint,
            { title, message },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
