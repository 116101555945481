<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="700px"
  >
    <v-dialog max-width="600" v-model="reasonDialog">
      <v-card>
        <v-card-title> Reason for Decline </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                filled
                label="Reason for Decline"
                v-model="reason.message"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="error"
            @click="actionOnWithdraw('declined')"
            >Confirm Decline</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="confirmApprove">
      <v-card>
        <v-card-title>Confirm approval</v-card-title>
        <v-card-text
          >You are about to approve this transaction,
          {{ transaction.transaction_id }}, confirm?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            @click="confirmApprove = false"
            color="error"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            @click="actionOnWithdraw('approved')"
            color="success"
          >
            Confirm Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pa-4">
      <v-card-title>Transaction Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Request Date</p>
            <small>{{ transaction.date }}</small>
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Request Status</p>
            <span
              :class="`${
                transaction.status == 'approved'
                  ? 'success--text'
                  : transaction.status === 'pending'
                  ? 'accent--text'
                  : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{ transaction.status }}</span
            >
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">User</p>
            <small class="font-weight-bold">{{ transaction.username }}</small>
            (<small>{{ transaction.email }}</small
            >)
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Request Time</p>
            <small>{{ transaction.time }}</small>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">Transaction Info</p>
        <v-row v-for="info in transaction.transactionInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text-capitalize text--darken-1">{{
              info.title
            }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span
              class="grey--text text-capitalize text--darken-3 font-weight-medium"
              >{{ info.text }}</span
            >
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">Account Details</p>
        <v-row v-for="info in transaction.transactionDetails" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>

        <div
          v-if="checkIfPermission('manage withdrawal', admin.permissions)"
          class="d-flex"
        >
          <v-btn
            @click.stop="reasonDialog = true"
            text
            :loading="loading"
            color="error"
            v-if="transaction.status === 'pending'"
            >Decline</v-btn
          >
          <v-btn
            @click.stop="confirmApprove = true"
            text
            :loading="loading"
            color="success"
            v-if="transaction.status === 'pending'"
            >Approved</v-btn
          >
        </div>

        <v-btn
          :disabled="loading"
          text
          color="accent"
          @click="$emit('update:open', false)"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import withdrawalApi from '../api/admin/withdrawals'

export default {
  name: 'withdrawalDetails',
  data: () => ({
    loading: false,
    reason: {},
    reasonDialog: false,
    confirmApprove: false
  }),
  props: {
    open: Boolean,
    transaction: Object,
    actionProgress: String
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    async actionOnWithdraw(action) {
      this.loading = true
      const res = await withdrawalApi
        .logs()
        .updateWithdraw(this.transaction._id, {
          status: action,
          message: this.reason.message || ''
        })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.$store.dispatch('alert', {
        message: `${action} successful`,
        status: true
      })
      this.loading = false
      this.declineDialog = false
      this.$emit('update:open', false)
      this.$emit('update:actionProgress', 'done')
    }
  }
}
</script>

<style></style>
