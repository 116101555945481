import axios from 'axios'
import Store from '../../store'
import {
  errorHandler,
  generateNoOptionsQuery,
  successHandler
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/profile`

export default {
  profile() {
    return {
      get: async (populate = []) => {
        const endpoint = `${URL}${generateNoOptionsQuery(populate)}`

        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateProfile: async (data) => {
        const endpoint = `${URL}`
        const formData = new FormData()
        for (const key in data) {
          formData.append(key, data[key])
        }
        try {
          const res = await axios.put(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      confirmBankAccount: async ({ accountNumber, bankCode }) => {
        const endpoint = `${BASEURL}/account-name`
        const data = { account_number: accountNumber, bank_code: bankCode }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      createWallet: async () => {
        const endpoint = `${BASEURL}/wallet`
        try {
          const res = await axios.post(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyBVN: async ({ bvn }) => {
        const endpoint = `${BASEURL}/bvn/verify`
        try {
          const res = await axios.post(
            endpoint,
            { bvn },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updatePIN: async ({ pin }) => {
        const endpoint = `${BASEURL}/pin`
        try {
          const res = await axios.post(
            endpoint,
            { pin },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getPinStatus: async () => {
        const endpoint = `${BASEURL}/pin`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      generateAccountStatement: async ({ params }) => {
        const endpoint = `${BASEURL}/account-statement`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      generateCryptoAddress: async (coinId) => {
        const endpoint = `${BASEURL}/crypto-wallets`
        try {
          const res = await axios.post(
            endpoint,
            {
              coinId
            },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCryptoAddress: async (coinId) => {
        const endpoint = `${BASEURL}/crypto-wallets`
        try {
          const res = await axios.get(endpoint, {
            params: {
              coin: coinId
            },
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCryptoBTCAddress: async (coinId) => {
        const endpoint = `${BASEURL}/crypto-wallets/btc`
        try {
          const res = await axios.post(
            endpoint,
            {
              coinId
            },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sellBTC: async ({ coinId, amount }) => {
        const endpoint = `${BASEURL}/cryptocoin/${coinId}/sell-btc`
        try {
          const res = await axios.post(
            endpoint,
            {
              amount
            },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
