<template>
  <v-container>
    <h1 class="mb-5">Declined Sales</h1>
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <v-row>
      <v-col>
        <v-card flat color="white">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrangedTransaction"
            :loading="loadingTransaction"
            :items-per-page="perPage"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 25, 50]
            }"
            @update:items-per-page="updateItemPerPage"
            @update:page="updatePage"
            :server-items-length="apiData.total"
          >
            <template v-slot:[`item.seller`]="{ item }">
              <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.seller_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.seller }}</router-link
              > -->
              <span
                class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                @click="openMinDialog(item.seller_id)"
              >
                {{ item.seller }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
              <span class="font-weight-medium">{{ item.currency }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <small class="font-weight-medium">{{ item.date }}</small>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span
                >{{
                  item.promocodeAmountEarned
                    ? Number(item.main_amo) + Number(item.promocodeAmountEarned)
                    : item.price
                }}
                <small class="grey--text text--darken-2">{{
                  item.currencyCode || 'NGN'
                }}</small></span
              >
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>
                <span class="grey--text text--darken-2">$</span>
                {{ item.amount }}</span
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small depressed @click.stop="selectTransaction(item)"
                >View Details</v-btn
              >
            </template>
          </v-data-table>
          <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
          <transaction-details
            :open.sync="transactionOpen"
            :transaction="transaction"
            type="sales"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import DeleteDialog from '../../../components/deleteDialog.vue'
import transactionDetails from '../../../components/transactionDetails.vue'
import transactionApi from '../../../api/admin/transactions'
import { numberWithCommas } from '../../../utils/helpers'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import _ from 'lodash'

export default {
  components: {
    transactionDetails,
    UserDetailsMin
    //  DeleteDialog
  },
  data: () => ({
    openMin: false,
    minUserId: '',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Seller',
        sortable: false,
        value: 'seller'
      },
      {
        text: 'Currency',
        value: 'currency'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Price (USD)',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'action',
        sortable: false
      }
    ],
    transactions: [],
    transaction: {},
    transactionsM: [],
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1
  }),
  created() {
    this.getTransactions(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    arrangedTransaction() {
      if (this.transactionsM.length !== []) {
        return this.transactionsM.map((transaction) => ({
          ...transaction,
          id: transaction?._id,
          date: transaction?.date_string,
          seller: transaction?.user?.username,
          seller_id: transaction?.user?._id,
          currency: transaction?.coin
            ? transaction.coin.name
            : transaction.currency
            ? transaction.currency.name
            : '',
          price: numberWithCommas(transaction.main_amo),
          amount: numberWithCommas(transaction.amount)
        }))
      }
      return []
    }
  },
  methods: {
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await transactionApi.transactions().getAll({
        type: 'sale',
        status: 'declined',
        category: 'coin',
        populate: ['user', 'coin', 'currency', 'actionby', 'promocode'],
        limit: this.perPage,
        keyword: this.search
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.transactionsM = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await transactionApi.transactions().getAll({
        type: 'sale',
        status: 'declined',
        category: 'coin',
        populate: ['user', 'coin', 'currency', 'actionby'],
        limit: this.perPage,
        offset: length
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.apiData = res.data.data.meta
      this.transactionsM = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
      this.loadingTransaction = false
    },
    selectTransaction(transx) {
      const transaction = this.transactionsM.find(
        (trx) => trx._id === transx.id
      )

      this.transaction = {
        ...transaction,
        image: transaction.image,
        date: transaction.date_string,
        time: transaction.time_string,
        status: transaction.status,
        transactionInfo: [
          {
            title: 'Transaction Type',
            text: `${transaction.type}`
          },
          {
            title: 'Amount',
            text: `${numberWithCommas(transaction.amount)} USD`
          },
          {
            title: 'Cryptocurrency',
            text: `${
              transaction.coin
                ? transaction.coin.name
                : transaction.currency
                ? transaction.currency.name
                : ''
            }`
          },
          {
            title: 'Amount',
            text: `${numberWithCommas(transaction.main_amo)} ${
              transaction.currencyCode ?? 'NGN'
            }`
          },
          {
            title: 'Total',
            text: `${
              transaction.promocodeAmountEarned
                ? numberWithCommas(
                    transaction.main_amo + transaction.promocodeAmountEarned
                  )
                : numberWithCommas(transaction.main_amo)
            } ${transaction.currencyCode ?? 'NGN'}`
          },
          {
            title: 'Bonus from Promo Code',
            text: `${
              transaction.promocodeAmountEarned
                ? transaction.promocodeAmountEarned
                : 'No promo code used'
            }`
          },
          {
            title: 'Promo Code Used',
            text: `${
              transaction.promocodeAmountEarned
                ? transaction.promocode.code
                : 'No promo code used'
            }`
          },
          {
            title: 'Transaction ID',
            text: `${transaction.trx}`
          },
          {
            title: 'Details',
            text: `${transaction.type} Cryptocurrency`
          }
        ],
        transactionDetails: [
          {
            title: `Reason for Decline`,
            text: `${
              transaction.reason ? transaction.reason : 'No reason given'
            }`
          },
          {
            title: `Image for Decline`,
            text: `${
              transaction.declineImage
                ? transaction.declineImage
                : 'No image uploaded'
            }`
          },
          {
            title: 'Amount Paid',
            text: 'No amount paid, transaction Declined'
          },
          {
            title: 'Amount to Receive',
            text: 'No amount received, transaction Declined'
          },
          {
            title: 'Bank Name',
            text: `${transaction.bankname ?? ''}`
          },
          {
            title: 'Bank Account Name',
            text: `${transaction.accountname ?? ''}`
          },
          {
            title: 'Bank Account Number',
            text: `${transaction.accountnumber ?? ''}`
          },
          {
            title: 'Declined By',
            text: `${
              transaction.actionby ? transaction.actionby.name : 'admin'
            }`
          },
          {
            title: 'Declined At',
            text: transaction.actionedAt
              ? new Date(transaction.actionedAt)
              : 'No approved date/time provided'
          }
        ]
      }
      this.transactionOpen = true
    }
  }
}
</script>

<style></style>
