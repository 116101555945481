<template>
  <v-card class="pa-4">
    <v-dialog v-model="transferFunds" max-width="600">
      <v-card>
        <v-card-title>Transfer Funds to {{ user.username }} </v-card-title>
        <v-card-actions>
          <v-container>
            <v-text-field
              type="number"
              label="Amount"
              v-model="amountTransfer"
            />
            <v-btn
              :disabled="amountTransfer === '' || amountTransfer <= 0"
              color="primary"
              :loading="isLoadingTransferFunds"
              @click="transferToUser"
              >Transfer</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="creditWalletDialog" max-width="600">
      <v-card>
        <v-card-title>Credit {{ user.username }} </v-card-title>
        <v-card-actions>
          <v-container>
            <v-text-field
              type="number"
              filled
              label="Amount"
              v-model="credit.amountTransfer"
            />
            <v-textarea
              label="Reason"
              filled
              v-model="credit.reason"
            ></v-textarea>
            <v-btn
              :disabled="!credit.amountTransfer || credit.amountTransfer <= 0"
              color="primary"
              :loading="isCreditLoading"
              @click="creditToUser"
              >Credit</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="debitWalletDialog" max-width="600">
      <v-card>
        <v-card-title>Debit {{ user.username }} </v-card-title>
        <v-card-actions>
          <v-container>
            <v-text-field
              type="number"
              filled
              label="Amount"
              v-model="debit.amountTransfer"
            />
            <v-textarea
              label="Reason"
              filled
              v-model="debit.reason"
            ></v-textarea>
            <v-btn
              :disabled="!debit.amountTransfer || debit.amountTransfer <= 0"
              color="primary"
              :loading="isDebitLoading"
              @click="debitToUser"
              >Debit</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="accountStatementDialog" max-width="600">
      <v-card>
        <v-card-title> Generate Account Statement </v-card-title>
        <v-card-actions>
          <v-container>
            <v-text-field
              type="date"
              filled
              label="From"
              v-model="account.startDate"
            />
            <v-text-field
              type="date"
              filled
              label="To"
              v-model="account.endDate"
            />
            <v-btn
              color="primary"
              :loading="isGeneratingStatement"
              @click="generateAccountStatement"
              >Generate</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title>
      <span>User Details</span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="arrangeUserData.activeStatus === 'deactivated'"
        @click="activateAccount"
        color="primary"
        :loading="changingAccountStatus"
        class="mr-4"
        >Active Account</v-btn
      >
      <v-btn
        v-if="arrangeUserData.activeStatus === 'active'"
        @click="deactivateAccount"
        color="warning"
        :loading="changingAccountStatus"
        class="mr-4"
        >Delete Account</v-btn
      >
      <!-- <v-btn
        v-if="checkIfPermission('fund user', admin.permissions)"
        @click="transferFunds = true"
        color="primary"
        class="mr-4"
        >Transfer Funds</v-btn
      > -->
      <v-btn
        v-if="checkIfPermission('fund user', admin.permissions)"
        @click="creditWalletDialog = true"
        color="accent"
        elevation="0"
        class="mr-4"
        >Credit Wallet</v-btn
      >
      <v-btn
        @click="debitWalletDialog = true"
        elevation="0"
        color="red"
        class="mr-4"
        >Debit Wallet</v-btn
      >
      <v-btn
        @click="accountStatementDialog = true"
        elevation="0"
        color="accent"
        class="mr-4"
        >Account Statement</v-btn
      >
    </v-card-title>
    <div class=""></div>
    <v-overlay v-if="loadingUserData" opacity="0.8">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0 font-weight-medium grey--text">Successful Purchase</p>
          <span class="text-h5"
            >{{ numberWithCommas(totalApprovedPurchase) }}
            <span class="grey--text subtitle-2">{{ userCurrency }}</span></span
          >
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0 font-weight-medium grey--text">Successful Sales</p>
          <span class="text-h5"
            >{{ numberWithCommas(totalApprovedSales) }}
            <span class="grey--text subtitle-2">{{ userCurrency }}</span></span
          >
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0 font-weight-medium grey--text">
            Successful Giftcard Sales
          </p>
          <span class="text-h5"
            >{{ numberWithCommas(totalApprovedGiftcard) }}
            <span class="grey--text subtitle-2">{{ userCurrency }}</span></span
          >
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Balance in Wallet</p>
            <span class="text-h5"
              >{{ arrangeUserData.balance }}
              <span class="grey--text subtitle-2">NGN</span></span
            >
          </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0 font-weight-medium grey--text">User Status</p>
          <span
            :class="`${
              arrangeUserData.status ? 'success--text' : 'error--text'
            } font-weight-bold text-capitalize mt-2 `"
            >{{ arrangeUserData.status ? 'Active' : 'Suspended' }}</span
          >
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-0 font-weight-medium grey--text">Registered Date</p>
          <span class="font-weight-medium overline"
            >{{ new Date(arrangeUserData.createdAt).toDateString() }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <p class="mb-2 font-weight-medium grey--text">Verification Status</p>
          <div class="d-flex">
            <span
              :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                arrangeUserData.email_verify ? 'success' : 'error'
              }`"
            >
              Email
            </span>
            <span
              :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                arrangeUserData.bvnVerified ? 'success' : 'error'
              }`"
            >
              BVN
            </span>
            <span
              :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                arrangeUserData.kycverify ? 'success' : 'error'
              }`"
              >KYC
            </span>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-2 mt-4"></v-divider>
      <p class="overline grey--text">User Info</p>
      <v-row v-for="info in arrangeUserData.info" :key="info.title">
        <v-col cols="5" md="5">
          <span class="grey--text text--darken-1">{{ info.title }}</span>
        </v-col>
        <v-col cols="7" md="7">
          <span class="grey--text text--darken-3 font-weight-medium">{{
            info.text
          }}</span>
        </v-col>
      </v-row>
      <!-- <v-divider class="mb-2 mt-4"></v-divider> -->
      <!-- <p class="overline grey--text">More information</p> -->
      <v-row v-for="info in arrangeUserData.moreInfo" :key="info.title">
        <v-col cols="5" md="5">
          <span class="grey--text text--darken-1">{{ info.title }}</span>
        </v-col>
        <v-col cols="7" md="7">
          <span class="grey--text text--darken-3 font-weight-medium">{{
            info.text
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <p class="overline grey--text">Transaction information</p>
    <v-tabs v-model="tabs">
      <v-tab> Sales </v-tab>
      <v-tab> Purchase </v-tab>
      <v-tab> Giftcard </v-tab>
      <v-tab> Referrals </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <!-- Sales -->
      <v-tab-item>
        <v-data-table
          :loading="loading"
          :items="transactionSales"
          :headers="headersForTransaction"
        >
          <template v-slot:[`item.rate`]="{ item }">
            <span>{{ item.rate.price }}</span>
            <span class="overline"> {{ item.rate.currency }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount.price }}</span>
            <span class="overline"> {{ item.amount.currency }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span>{{ item.price }}</span>
            <span class="overline"> {{ item.currencyCode }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-card
              :color="
                item.status == 'approved'
                  ? 'success'
                  : item.status == 'pending'
                  ? 'warning'
                  : 'error'
              "
              flat
              rounded="md"
              outlined
              class="text-capitalize text-center white--text pa-1"
            >
              {{ item.status }}
            </v-card>
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- Purchase -->
      <v-tab-item>
        <v-data-table
          :loading="loading"
          :items="transactionPurchase"
          :headers="headersForTransaction"
        >
          <template v-slot:[`item.rate`]="{ item }">
            <span>{{ item.rate.price }}</span>
            <span class="overline"> {{ item.rate.currency }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount.price }}</span>
            <span class="overline"> {{ item.amount.currency }}</span>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <span>{{ item.price }}</span>
            <span class="overline"> {{ item.currencyCode }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-card
              :color="
                item.status == 'approved'
                  ? 'success'
                  : item.status == 'pending'
                  ? 'warning'
                  : 'error'
              "
              flat
              rounded="md"
              outlined
              class="text-capitalize text-center white--text pa-1"
            >
              {{ item.status }}
            </v-card>
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- Giftcard -->
      <v-tab-item>
        <v-data-table
          :loading="loading"
          :items="transactionGiftcard"
          :headers="headersForGiftcard"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img :src="item.image" width="45"></v-img>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ item.amount }}</span>
            <span class="overline"> {{ item.currencyCode }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-card
              :color="
                item.status == 'approved'
                  ? 'success'
                  : item.status == 'pending'
                  ? 'warning'
                  : 'error'
              "
              flat
              rounded="md"
              outlined
              class="text-capitalize text-center white--text pa-1"
            >
              {{ item.status }}
            </v-card>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item
        ><v-card flat class="mt-5">
          <v-card-title>User's Referral</v-card-title>
          <v-data-table :headers="headers" :items="arrangeUserData.referrals">
            <template v-slot:[`item.created`]="{ item }">
              <span>{{ new Date(item.createdAt).toDateString() }}</span>
            </template>
          </v-data-table>
        </v-card></v-tab-item
      >
    </v-tabs-items>
    <v-card-actions>
      <v-spacer> </v-spacer>
      <v-btn text color="accent" @click="$router.go(-1)">Back</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { BASEURL } from '../../../api/const'
import Store from '../../../store'
import { numberWithCommas } from '../../../utils/helpers'
import userApi from '../../../api/admin/userManagement'
import transfer from '../../../api/admin/transfer'
import { mapState } from 'vuex'
import { getCurrencyCode } from '../../../utils/countryHelpers'
export default {
  name: 'UserDetails',
  props: {
    open: Boolean
  },
  created() {
    this.userId = this.$route.params.id
    this.getUserDetails()
  },
  watch: {
    user(newValue, oldValue) {
      this.transactionPurchase = []
      this.transactionSales = []
      this.transactionGiftcard = []
      this.totalApprovedPurchase = 0
      this.totalApprovedSales = 0
      this.totalApprovedGiftcard = 0
      this.getTransactionForAUser(newValue._id || newValue.id)
    }
  },
  data: () => ({
    numberWithCommas,
    creditWalletDialog: false,
    accountStatementDialog: false,
    debitWalletDialog: false,
    isCreditLoading: false,
    isDebitLoading: false,
    isGeneratingStatement: false,
    loadingUserData: false,
    userId: '',
    credit: {},
    debit: {},
    data: {},
    user: {},
    account: {
      startDate: '',
      endDate: ''
    },
    tabs: null,
    userTransactions: [],
    loading: false,
    amountTransfer: '',
    transferFunds: false,
    isLoadingTransferFunds: false,
    headers: [
      {
        text: 'Username',
        value: 'username',
        align: 'start'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Date Joined',
        value: 'created'
      }
      // {
      //   text: 'Total Transaction',
      //   value: 'total'
      // },
      // {
      //   text: 'Bonus Earning',
      //   value: 'bonus'
      // },
      // {
      //   text: 'Status',
      //   value: 'status'
      // }
    ],
    headersForTransaction: [
      {
        text: 'Trx ID.',
        align: 'start',
        sortable: false,
        value: 'trx'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Currency',
        value: 'currency'
      },
      {
        text: 'Amount',
        value: 'amount'
      },

      {
        text: 'Rate',
        value: 'rate'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headersForGiftcard: [
      {
        text: 'Trx ID',
        value: 'trx',
        sortable: false
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Giftcard',
        value: 'image'
      },
      {
        text: 'Name',
        value: 'giftcard.name'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    transactionPurchase: [],
    transactionSales: [],
    transactionGiftcard: [],
    totalApprovedPurchase: 0,
    totalApprovedSales: 0,
    totalApprovedGiftcard: 0,
    changingAccountStatus: false
  }),
  methods: {
    async generateAccountStatement() {
      this.isGeneratingStatement = true

      const params = {}
      if (this.account.startDate) {
        if (new Date(this.account.startDate) > new Date()) {
          this.$store.dispatch('alert', {
            message: 'Start date cannot be greater than today',
            error: true,
            status: true
          })
          return
        }
        params.startDate = this.account.startDate
      }

      if (this.account.endDate) {
        if (new Date(this.account.endDate) > new Date()) {
          this.$store.dispatch('alert', {
            message: 'End date cannot be greater than today',
            error: true,
            status: true
          })
          return
        }
        params.endDate = this.account.endDate
      }

      const req = await userApi.users().generateAccountStatement({
        userId: this.userId,
        params
      })
      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isGeneratingStatement = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Statement of Account Generated Succcessfully',
        status: true,
        error: false
      })
      window.open(req.data.data.link, '_blank')
      this.isGeneratingStatement = false
      this.accountStatementDialog = false
    },

    async creditToUser() {
      this.isCreditLoading = true
      const req = await transfer.data().creditUser({
        amount: this.credit.amountTransfer,
        userId: this.userId,
        reason: this.credit.reason
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        // this.transferFunds = false
        this.isCreditLoading = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Credit Successful',
        status: true,
        error: false
      })
      this.isCreditLoading = false
      this.creditWalletDialog = false
    },
    async debitToUser() {
      this.isDebitLoading = true
      const req = await transfer.data().debitUser({
        amount: this.debit.amountTransfer,
        userId: this.userId,
        reason: this.debit.reason
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        // this.transferFunds = false
        this.isDebitLoading = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Debit Successful',
        status: true,
        error: false
      })
      this.isDebitLoading = false
      this.debitWalletDialog = false
    },
    async getUserDetails() {
      this.loadingUserData = true
      const res = await userApi.users().getSingle({ userId: this.userId })
      this.user = res.data.data
      this.loadingUserData = false
    },
    async getTransactionForAUser() {
      this.loading = true
      const res = await axios.get(
        `${BASEURL}/transactions?user=${this.arrangeUserData._id}&populate=currency&populate=giftcard&limit=0`,
        {
          headers: {
            ...Store.getters['admin/getRequestHeaders'].headers
          }
        }
      )
      this.userTransactions = res.data.data.data
      // Purchase
      this.transactionPurchase = res.data.data.data
        .filter((transaction) => transaction.type === 'purchase')
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: new Date(
            transaction.created_at
              ? transaction.created_at
              : transaction.createdAt
          ).toDateString(),
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionPurchase.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedPurchase = this.totalApprovedPurchase + e.price
        }
      })

      // Sales
      this.transactionSales = res.data.data.data
        .filter(
          (transaction) =>
            transaction.type === 'sale' && transaction.category !== 'giftcard'
        )
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: new Date(
            transaction.created_at
              ? transaction.created_at
              : transaction.createdAt
          ).toDateString(),
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionSales.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedSales = this.totalApprovedSales + e.price
        }
      })

      this.transactionGiftcard = res.data.data.data
        .filter((t) => t.category === 'giftcard')
        .map((trx) => ({
          ...trx,
          date: new Date(
            trx.created_at ? trx.created_at : trx.createdAt
          ).toDateString(),
          id: trx._id,
          image: trx.giftcard ? trx.giftcard.image : '',
          amount: trx.main_amo
        }))

      this.transactionGiftcard.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedGiftcard = this.totalApprovedGiftcard + e.amount
        }
      })
      this.loading = false
    },
    async transferToUser() {
      this.isLoadingTransferFunds = true

      const req = await transfer.data().transferToAUser({
        amount: this.amountTransfer,
        userUsername: this.user.username
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        // this.transferFunds = false
        this.isLoadingTransferFunds = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Transfer Successful',
        status: true,
        error: false
      })

      this.isLoadingTransferFunds = false
      this.transferFunds = false
      this.amountTransfer = ''
    },
    async deactivateAccount() {
      this.changingAccountStatus = true
      const req = await userApi.users().deactivateAccount({
        userId: this.userId
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.changingAccountStatus = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Account Deleted',
        status: true,
        error: false
      })
      this.user.activeStatus = 'deactivated'
      this.changingAccountStatus = false
    },
    async activateAccount() {
      this.changingAccountStatus = true
      const req = await userApi.users().activateAccount({
        userId: this.userId
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.changingAccountStatus = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Account Activated',
        status: true,
        error: false
      })
      this.user.activeStatus = 'active'
      this.changingAccountStatus = false
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangeUserData() {
      if (this.user === {}) return
      return {
        ...this.user,
        purchase: { total: 0.0 },
        sales: { total: 0.0 },
        giftcard: { total: 0.0 },
        info: [
          {
            title: 'Full Name',
            text: `${this.user.fname} ${this.user.lname}`
          },
          {
            title: 'Username',
            text: `${this.user.username}`
          },
          {
            title: 'Email',
            text: `${this.user.email}`
          },
          {
            title: 'Address',
            text: `${this.user.address || ''}`
          },
          {
            title: 'Date of Birth',
            text: `${this.user.dob || ''}`
          },
          {
            title: 'Phone',
            text: `${this.user.phone || ''}`
          }
        ],
        moreInfo: [
          // {
          //   title: 'Last Login',
          //   text: '16 minutes ago'
          // },
          // {
          //   title: 'Last Login IP',
          //   text: '197.218.8.12'
          // },
          // {
          //   title: 'Last Login Location',
          //   text: 'Africa, Nigeria, Lagos'
          // }
        ]
      }
    },
    userCurrency() {
      return getCurrencyCode(this.user.country)
    }
  }
}
</script>

<style></style>
