var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.checkIfPermission('view calculator', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_c('div',[_c('h1',[_vm._v("Transaction Calculator")]),_c('v-dialog',{attrs:{"width":"850"},on:{"click:outside":_vm.handleOutsideClick},model:{value:(_vm.showTransactionSummary),callback:function ($$v) {_vm.showTransactionSummary=$$v},expression:"showTransactionSummary"}},[_c('v-card',{staticClass:"pa-5"},[_c('h3',{staticClass:"mb-5"},[_vm._v("Transaction Summary")]),[_c('div',[(_vm.dates)?_c('p',[_vm._v(" Total Transactions from "),_c('b',[_vm._v(_vm._s(new Date(_vm.dates[0]).toDateString()))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(new Date(_vm.dates[1]).toDateString()))]),_vm._v(": "+_vm._s(_vm.apiData.total)+" ")]):_vm._e(),(
                _vm.transaction.productType &&
                _vm.transaction.productType.toLowerCase() !== 'giftcard'
              )?_c('div',[_c('div',[_vm._v(" Total Dollar Amount (Nigeria): "),_c('b',[_vm._v("$ "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Nigeria ? _vm.totalMoney.Nigeria.usd_total : '' )))])]),_c('div',[_vm._v(" Total Dollar Amount (Ghana): "),_c('b',[_vm._v("$ "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Ghana ? _vm.totalMoney.Ghana.usd_total : '' )))])]),_c('div',[_vm._v(" Total Dollar Amount (Kenya): "),_c('b',[_vm._v("$ "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Kenya ? _vm.totalMoney.Kenya.usd_total : '' )))])])]):_vm._e(),_c('p',[_vm._v(" Total Naira Amount: "),_c('b',[_vm._v(_vm._s(_vm.getCurrencySymbol('nigeria'))+" "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Nigeria ? _vm.totalMoney.Nigeria.total : 0 )))])]),_c('p',[_vm._v(" Total Cedi Amount: "),_c('b',[_vm._v(" "+_vm._s(_vm.getCurrencySymbol('ghana'))+" "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Ghana ? _vm.totalMoney.Ghana.total : 0 )))])]),_c('p',[_vm._v(" Total Shilling Amount: "),_c('b',[_vm._v(" "+_vm._s(_vm.getCurrencySymbol('kenya'))+" "+_vm._s(_vm.numberWithCommas( _vm.totalMoney.Kenya ? _vm.totalMoney.Kenya.total : 0 )))])]),_c('p',[_vm._v(" Total Unique Users (Nigeria): "),_c('b',[_vm._v(" "+_vm._s(_vm.numberWithCommas( _vm.uniqueUsers.Nigeria ? _vm.uniqueUsers.Nigeria.totalUniqueUsers : 0 )))])]),_c('p',[_vm._v(" Total Unique Users (Ghana): "),_c('b',[_vm._v(" "+_vm._s(_vm.numberWithCommas( _vm.uniqueUsers.Ghana ? _vm.uniqueUsers.Ghana.totalUniqueUsers : 0 )))])]),_c('p',[_vm._v(" Total Unique Users (Kenya): "),_c('b',[_vm._v(" "+_vm._s(_vm.numberWithCommas( _vm.uniqueUsers.Kenya ? _vm.uniqueUsers.Kenya.totalUniqueUsers : 0 )))])]),(_vm.gottenTransaction.length === 0 && !_vm.loadingTransaction)?_c('div',[_c('p',[_vm._v("No transaction between the selected dates")])]):_c('div',[(
                  _vm.transaction.productType &&
                  _vm.transaction.productType.toLowerCase() === 'giftcard'
                )?_c('v-data-table',{attrs:{"headers":_vm.headersGiftCard,"items":_vm.arrangedTransactionGiftCard,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15, 25, 50]
                },"server-items-length":_vm.apiData.total,"loading":_vm.loadingTransaction,"items-per-page":_vm.perPage},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.seller",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block"},[_vm._v(" "+_vm._s(item.seller)+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.giftcard",fn:function(ref){
                        var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"width":"45","src":item.giftcard ? item.giftcard.image : ''}}),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.giftcard ? item.giftcard.name : ''))])]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_c('small',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.date))])]}},{key:"item.price",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" "),_c('small',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.currencyCode)+" ")])])]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v("$")]),_vm._v(" "+_vm._s(item.amount))])]}}],null,true)}):_c('v-data-table',{attrs:{"headers":_vm.headersCoin,"items":_vm.arrangedTransaction,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15, 25, 50]
                },"server-items-length":_vm.apiData.total,"loading":_vm.loadingTransaction,"items-per-page":_vm.perPage},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.buyer",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block"},[_vm._v(" "+_vm._s(item.buyer)+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.currency",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.currency))])]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_c('small',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.date))])]}},{key:"item.price",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price)+" "),_c('small',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.currencyCode)+" ")])])]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v("$")]),_vm._v(" "+_vm._s(item.amount))])]}}],null,true)})],1)])]],2)],1),_c('div',{staticClass:"mt-10 px-10"},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4 px-6",attrs:{"outlined":"","color":"white"}},[_c('v-select',{attrs:{"items":['Sale', 'Purchase', 'Giftcard'],"label":"Product Type"},model:{value:(_vm.transaction.productType),callback:function ($$v) {_vm.$set(_vm.transaction, "productType", $$v)},expression:"transaction.productType"}}),_c('v-select',{attrs:{"items":['Approved', 'Pending', 'Declined'],"label":"Transaction Type"},model:{value:(_vm.transaction.transactionType),callback:function ($$v) {_vm.$set(_vm.transaction, "transactionType", $$v)},expression:"transaction.transactionType"}}),_c('v-text-field',{attrs:{"label":"Start From","type":"date"},model:{value:(_vm.dates[0]),callback:function ($$v) {_vm.$set(_vm.dates, 0, $$v)},expression:"dates[0]"}}),_c('v-text-field',{attrs:{"label":"End At","type":"date"},model:{value:(_vm.dates[1]),callback:function ($$v) {_vm.$set(_vm.dates, 1, $$v)},expression:"dates[1]"}}),_c('div',[_vm._v(" Start From: "+_vm._s(_vm.dates[0])+" "),_c('br'),_vm._v(" End At: "+_vm._s(_vm.dates[1])+" ")]),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"block":"","color":"primary","loading":_vm.gettingTransaction},on:{"click":_vm.getAllTransactions}},[_vm._v(" Calculate ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }