<template>
  <v-container class="py-10">
    <div v-if="info.totalUsers" class="flex items-center justify-between mb-5 gap-x-10">
      <div class="bg-yellow-100 rounded-md px-4 py-6 w-full shadow">
        <p class="text-xs text-opacity-75 m-0 mb-0">Total Transaction Amount</p>
        <p class="m-0 mb-0 text-2xl font-medium">{{ info.totalTransactionAmount }}</p>
      </div>
      <div class="bg-blue-100 rounded-md px-4 py-6 w-full shadow">
        <p class="text-xs text-opacity-75 m-0 mb-0">Total Users</p>
        <p class="m-0 mb-0 text-2xl font-medium">{{ info.totalUsers }}</p>
      </div>
      <div class="bg-green-100 rounded-md px-4 py-6 w-full shadow">
        <p class="text-xs text-opacity-75 m-0 mb-0">Total Users With Approved Transactions</p>
        <p class="m-0 mb-0 text-2xl font-medium">{{ info.totalUsersWithApprovedTransactions }}</p>
      </div>
      <div class="bg-red-100 rounded-md px-4 py-6 w-full shadow">
        <p class="text-xs text-opacity-75 m-0 mb-0">Transaction Count</p>
        <p class="m-0 mb-0 text-2xl font-medium">{{ info.transactionCount }}</p>
      </div>
    </div>
    <user-details :open.sync="userDetailsDialog" :user="user" />
    <v-card flat color="white">
      <v-card-title> </v-card-title>
      <div class="px-4 grid grid-cols-4 gap-x-5">
        <div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-select
            :items="[
              {
                text: 'Purchase',
                value: 'purchase'
              },
              {
                text: 'Sale',
                value: 'sale'
              }
            ]"
            label="Transaction Type"
            v-model="type"
          />
        </div>
        <div>
          <v-select
            :items="[
              {
                text: 'Coin',
                value: 'coin'
              },
              {
                text: 'Giftcard',
                value: 'giftcard'
              }
            ]"
            label="Transaction Type"
            v-model="category"
          />
        </div>
        <!-- <div>
          <v-text-field label="Limit" type="number" v-model="perPage" />
        </div> -->
        <div>
          <v-select
            v-model="selectedCountry"
            :items="['Nigeria', 'Ghana', 'Kenya']"
            label="Country"
          />
        </div>
        <div>
          <v-btn color="primary" @click="getUsers()">Filter</v-btn>
        </div>
      </div>
      <v-data-table
        :loading="loadingUser"
        :items="users"
        :search="search"
        :headers="headers"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
        }"
        @update:items-per-page="updateItemPerPage"
        @update:page="updatePage"
        :server-items-length="apiData.total"
      >
        <template v-slot:no-data>
          <div class="px-5 py-4">
            <v-alert :value="true" icon="mdi-alert">
              Pick a filter option
            </v-alert>
          </div>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <router-link
            :to="{ name: 'ViewUser', params: { id: item.userId } }"
            class="font-weight-medium text-capitalize d-block"
            >{{ `${item.fname} ${item.lname}` }}</router-link
          >
          <small
            class="font-weight-regular grey--text text--darken-2 d-block"
            >{{ item.username }}</small
          >
        </template>
        <template v-slot:[`item.registered`]="{ item }">
          <small>{{ new Date(item.dateJoined).toDateString() }}</small>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import userDetails from '../userDetails.vue'
import { mapState } from 'vuex'
import userManagement from '../../api/admin/userManagement'
import { numberWithCommas } from '../../utils/helpers'

export default {
  components: { userDetails },
  name: 'UserListTable',
  data: () => ({
    dates: [],
    order: 'asc',
    limit: 100,
    type: 'sale',
    category: 'coin',
    selectedCountry: 'Nigeria',
    menu: false,
    search: '',
    userDetailsDialog: false,
    actionOnDialog: false,
    user: {},
    userTransactions: [],
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false
      },
      {
        text: 'Country',
        value: 'country',
        sortable: false
      },
      {
        text: 'Registered Date',
        value: 'dateJoined'
      },
      {
        text: 'Trx. Amt.',
        value: 'transactionAmount'
      },
      {
        text: 'Trx. Count',
        value: 'transactionCount'
      }
    ],
    actionType: {},
    actionUserId: '',
    users: [],
    loadingUser: false,
    perPage: 10,
    apiData: {},
    lastPage: 1,
    info: {
      totalTransactionAmount: 0,
      totalUsers: 0,
      totalUsersWithApprovedTransactions: 0,
      transactionCount: 0
    }
  }),
  props: {
    userToGet: String
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    dateRangeText() {
      const [start, end] = this.dates
      if (!start || !end) return ''
      if (start === end) return start
      if (start > end) return `${end} - ${start}`
      return `${start} - ${end}`
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getUsers((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getUsers((this.lastPage - 1) * this.perPage)
    },
    selectUser(user) {
      this.user = user
      this.userDetailsDialog = true
    },

    async getUsers(length = 0) {
      if (!this.dates.length) {
        this.alert({
          message: 'Please select a date range',
          error: true,
          time: 4000,
          status: true
        })
        return
      }

      this.loadingUser = true

      const filters = {
        type: this.type,
        category: this.category,
        country: this.selectedCountry,
        startDate:
          this.dates[0] > this.dates[1] ? this.dates[1] : this.dates[0],
        endDate: this.dates[0] > this.dates[1] ? this.dates[0] : this.dates[1],
        limit: this.perPage,
        offset: length
      }

      const res = await userManagement.users().userCryptoStats(filters)
      if (res.error) {
        this.alert({
          message: 'Unable to fetch users',
          error: true,
          time: 4000,
          status: true
        })
        this.loadingActive = false
        return
      }
      this.users = res.data.data.data.map((d) => ({
        ...d,
        user: `${d.fname} ${d.lname} ${d.username}`,
        transactionAmount: numberWithCommas(
          Number(d.transactionAmount).toFixed(2)
        )
      }))
      this.apiData = res.data.data.meta
      this.info = {
        totalTransactionAmount: numberWithCommas(
          res.data.data.totalTransactionAmount.toFixed(2)
        ),
        totalUsers: res.data.data.totalUsers,
        totalUsersWithApprovedTransactions:
          res.data.data.totalUsersWithApprovedTransactions,
        transactionCount: res.data.data.transactionCount
      }
      this.loadingUser = false
    }
  }
}
</script>

<style></style>
