import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/misc`

export default {
  misc() {
    return {
      /**
       *
       * @param {String}  type
       * withdrawal,transfer,airtime,data,add-account,delete-account,modify-account
       */
      generateOtp: async (type) => {
        const endpoint = `${URL}/generate-otp`
        try {
          const res = await axios.post(
            endpoint,
            { type },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getMessages: async (type) => {
        const endpoint = `${URL}/${type}?user=${Store.state.user.user._id}&admin=false`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
