import Vue from 'vue'
import VueRouter from 'vue-router'

import userRoutes from './user.routes'
import authRoutes from './auth.routes'
import adminRoutes from './admin.routes'
import authAdminRoutes from './authAdmin.routes'
import ViewImage from '../views/admin/viewImage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/view-image',
    component: ViewImage,
    name: 'AdminViewImage',
    meta: {
      title: 'View Image'
    }
  },
  authRoutes,
  authAdminRoutes,
  userRoutes,
  adminRoutes,
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, _from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Snappy Exchange'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin === 1) {
          next()
        } else {
          next({ name: 'userboard' })
        }
      } else {
        next()
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next()
    } else {
      next({ name: 'userboard' })
    }
  } else {
    next()
  }
})

export default router
