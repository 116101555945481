<template>
  <v-container>
    <h1>Profile</h1>
    <v-row>
      <v-col>
        <v-card flat rounded="md" color="white">
          <v-tabs v-model="profileTabs">
            <v-tab>Personal Data</v-tab>
            <v-tab>Security</v-tab>
            <v-tab>PERMISSIONS</v-tab>
          </v-tabs>
          <v-tabs-items v-model="profileTabs">
            <v-tab-item>
              <v-container>
                <!-- <v-card flat>
                  <v-row>
                    <v-col cols="2">
                      <picture-input
                        :hideChangeButton="true"
                        ref="pictureInput"
                        @change="onChange"
                        accept="image/jpeg,image/png"
                        size="10"
                        :removable="true"
                        removeButtonClass="px-3 py-1 white--text error subtitle-2 rounded-lg"
                        :customStrings="{
                          drag: 'Upload Image'
                        }"
                      >
                      </picture-input>
                    </v-col>
                    <v-col cols="8">
                      <v-col>
                        <h4>Change Picture</h4>
                        <p>Maximum file size for Profile Avatar is 20MB</p>
                        <v-btn class="accent" depressed>Upload</v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card> -->
                <v-card flat class="mt-4">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="admin.name"
                        label="Name"
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        label="Email"
                        filled
                        v-model="admin.email"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        dense
                        v-model="admin.phone"
                        label="Phone Number"
                        filled
                      ></v-text-field>
                    </v-col> -->
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      large
                      :loading="loading"
                      depressed
                      @click="updateData"
                      color="accent"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-card flat>
                  <v-card-title>Change Password</v-card-title>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="password"
                        filled
                        label="New Password"
                        type="password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        filled
                        v-model="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="accent"
                      @click="changePassword"
                      :loading="loadingPassword"
                      large
                      depressed
                      >Save Changes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-tab-item>
            <!-- Permission -->
            <v-tab-item>
              <v-container>
                <v-card flat class="pa-4">
                  <p>STAFF MANAGEMENT PERMISSION</p>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      v-for="permission in permissions"
                      :key="permission._id"
                    >
                      <v-switch
                        :disabled="true"
                        v-model="adminPermissions"
                        :label="permission.name"
                        :messages="permission.description"
                        :value="permission._id"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import PictureInput from 'vue-picture-input'
import { mapState } from 'vuex'
import adminApi from '../../api/admin/admin'
import authApi from '../../api/admin/auth'
import { updateProfile } from '../../utils/helpers'
import permissionApi from '../../api/admin/permissions'

export default {
  // components: { PictureInput },
  data() {
    return {
      loading: false,
      profileTabs: null,
      editing: false,
      createStaff: {},
      password: '',
      confirmPassword: '',
      loadingPassword: false,
      permissions: []
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    adminPermissions() {
      return this.admin.permissions.map((permission) => permission._id)
    }
  },
  created() {
    this.getAvailablePermissions()
  },
  methods: {
    async getAvailablePermissions() {
      const res = await permissionApi.permissions().getAll({
        params: {
          limit: 0,
          offset: 0
        }
      })
      this.permissions = res.data.data.data
    },
    async changePassword() {
      this.loadingPassword = true
      const res = await authApi.auth().changePassword({
        password: this.password,
        confirmPassword: this.confirmPassword
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingPassword = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Password Changed',
        status: true
      })
      this.password = ''
      this.confirmPassword = ''
      this.loadingPassword = false
    },
    async updateData() {
      this.loading = true
      const res = await adminApi.users().updateAdminDetails({
        adminId: this.admin._id,
        data: { ...this.admin, status: true }
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      const profile = await adminApi.users().getSingle(this.admin._id)
      // console.log(profile)
      updateProfile({ user: 'admin', profile: profile.data.data })
      this.$store.dispatch('alert', {
        message: 'Profile update successful',
        status: true
      })
      this.loading = false
    },
    onChange() {
      // console.log('New picture selected!')
      if (this.$refs.pictureInput.image) {
        // console.log('Picture loaded.')
      } else {
        // console.log('FileReader API not supported: use the <form>, Luke!')
      }
    }
  }
}
</script>

<style></style>
