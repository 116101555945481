<template>
  <v-container class="mb-10">
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style></style>
