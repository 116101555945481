<template>
  <v-container>
    <v-dialog v-model="factorDialog" max-width="700">
      <v-card :loading="sending" class="text-center">
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="close2FADialog"> mdi-close </v-icon>
        </div>

        <v-stepper v-model="factorStepper">
          <v-stepper-items>
            <!-- Step 1 -->
            <v-stepper-content step="1">
              <v-container>
                <v-img
                  width="105"
                  class="mx-auto"
                  src="@/assets/img/shield.6a1ccd91.svg"
                />
                <v-card-title>
                  <span class="mx-auto"
                    >Please, set up two factor authentication to proceed.</span
                  >
                </v-card-title>
                <v-card-text>
                  <p>
                    2 factor authentication is an additional security layer for
                    user identity confirmation and this is required for all
                    transactions.
                  </p>
                  <p>
                    To set up two-factor authentication, Please click “Get
                    Started” below and follow the instructions carefully.
                  </p>
                  <div class="mt-4">
                    <v-btn @click="factorStepper = 2" color="primary" large
                      >Confirm</v-btn
                    >
                  </div>
                </v-card-text>
              </v-container>
            </v-stepper-content>
            <!-- Step 2 -->
            <v-stepper-content step="2">
              <v-container>
                <v-img
                  width="105"
                  class="mx-auto"
                  src="@/assets/img/shield.6a1ccd91.svg"
                />
                <v-card-title>
                  <span class="mx-auto">2-Factor Authentication</span>
                </v-card-title>
                <v-card-subtitle>
                  <span class="mx-auto">
                    We strongly recommend you to enable 2FA on your account.
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <p>
                    A. 2-factor Authentication is an additional security layer
                    for user authentication and Identity confirmation. It is
                    highly recommended but optional.
                  </p>
                  <p>
                    B. Enabling 2-factor authentication involves you downloading
                    the Google authenticator app on your mobile device (Android
                    or Iphone), scanning a QR code generated using the app, and
                    then using the Google Authenticator code every time you want
                    to login, thereby verifying your account ownership.
                  </p>
                  <p>
                    C. To set up 2 factor authentication, Please click next and
                    follow the instructions carefully
                  </p>
                  <div class="mt-4">
                    <v-btn @click="factorStepper = 3" color="primary" large
                      >Next</v-btn
                    >
                  </div>
                </v-card-text>
              </v-container>
            </v-stepper-content>
            <!-- Step 3 -->

            <v-stepper-content step="3">
              <v-card-title>
                <span class="mx-auto">2-Factor Authentication</span>
              </v-card-title>
              <v-card-subtitle>
                <span class="mx-auto">
                  We strongly recommend you to enable 2FA on your account.
                  Please go through the following process to set up your 2
                  Factor Authentication.
                </span>
              </v-card-subtitle>

              <v-stepper v-model="lastPage2FA" class="elevation-0">
                <v-stepper-header class="elevation-0 grey lighten-3">
                  <v-stepper-step step="1" :complete="lastPage2FA > 1">
                    Download 2FA App
                  </v-stepper-step>
                  <v-stepper-step step="2" :complete="lastPage2FA > 2">
                    Scan Code
                  </v-stepper-step>
                  <v-stepper-step step="3" :complete="lastPage2FA > 3">
                    Summary
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <p class="mb-0 text-center font-weight-bold">
                      Let's set up your Account!
                    </p>
                    <p class="grey--text">
                      Download and install the Google Authenticator app on your
                      phone
                    </p>
                    <div
                      class="d-block text-center d-md-flex justify-center align-center"
                    >
                      <v-img
                        src="@/assets/img/googleplayicon.529d60eb.svg"
                        max-width="57"
                        class="mx-auto mx-md-1"
                      ></v-img>
                      <a
                        target="_blank"
                        style="width: fit-content"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                        class="pa-2 px-4 d-flex justify-center align-center grey darken-3 rounded mx-auto mx-md-1 my-1"
                      >
                        <v-img
                          src="@/assets/img/google-play.de8d9de6.svg"
                          max-width="35"
                        ></v-img>
                        <div>
                          <p
                            class="mb-0 white--text opacity-85"
                            style="font-size: 12px"
                          >
                            Download if from
                          </p>
                          <h3 class="white--text">Google Play</h3>
                        </div>
                      </a>

                      <a
                        target="_blank"
                        style="width: fit-content"
                        href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                        class="pa-2 mx-2 px-4 d-flex grey darken-3 rounded mx-auto mx-md-1 my-1"
                      >
                        <v-img
                          src="@/assets/img/appleicon.377276f1.svg"
                          max-width="35"
                        ></v-img>
                        <div>
                          <p
                            class="mb-0 white--text opacity-85"
                            style="font-size: 12px"
                          >
                            Download if from
                          </p>
                          <h3 class="white--text">App Store</h3>
                        </div>
                      </a>
                    </div>
                    <p class="grey--text mt-3">
                      Or download the Authy App on your phone
                    </p>
                    <div
                      class="d-block text-center d-md-flex justify-center align-center"
                    >
                      <v-img
                        src="@/assets/img/authyicon.77fb73b9.svg"
                        max-width="57"
                        class="mx-auto mx-md-1"
                      ></v-img>
                      <a
                        target="_blank"
                        style="width: fit-content"
                        href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en"
                        class="pa-2 px-4 d-flex grey darken-3 rounded mx-auto mx-md-1 my-1"
                      >
                        <v-img
                          src="@/assets/img/google-play.de8d9de6.svg"
                          max-width="35"
                        ></v-img>
                        <div>
                          <p
                            class="mb-0 white--text opacity-85"
                            style="font-size: 12px"
                          >
                            Download if from
                          </p>
                          <h3 class="white--text">Google Play</h3>
                        </div>
                      </a>

                      <a
                        target="_blank"
                        style="width: fit-content"
                        href="https://apps.apple.com/us/app/authy/id494168017"
                        class="pa-2 mx-2 px-4 d-flex grey darken-3 rounded mx-auto mx-md-1 my-1"
                      >
                        <v-img
                          src="@/assets/img/appleicon.377276f1.svg"
                          max-width="35"
                        ></v-img>
                        <div>
                          <p
                            class="mb-0 white--text opacity-85"
                            style="font-size: 12px"
                          >
                            Download if from
                          </p>
                          <h3 class="white--text">App Store</h3>
                        </div>
                      </a>
                    </div>
                    <div class="mt-4">
                      <v-btn
                        :loading="generationCode"
                        @click="generate2FACode"
                        color="primary"
                        large
                        >Next</v-btn
                      >
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <div class="text-center">
                      <p>Scan QR Code</p>
                      <v-img
                        max-width="300"
                        class="mx-auto"
                        :src="codeQR"
                      ></v-img>
                      <v-text-field
                        label="Token from 2FA app"
                        v-model="userToken"
                        filled
                      >
                      </v-text-field>
                      <v-btn
                        color="primary"
                        @click="verify2FACode"
                        :loading="sendingToken"
                        >NEXT</v-btn
                      >
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <h3 class="text-center green--text">
                      2 Factor Enabled Successfully
                    </h3>
                    <v-btn color="primary" @click="factorDialog = false"
                      >Close</v-btn
                    >
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="disableDialog" max-width="560">
      <v-card :loading="sending" class="text-center">
        <v-container>
          <v-card-title>
            You are about to disable two factor authentication
          </v-card-title>
          <v-card-text>
            2 factor authentication is an additional security layer for user
            identity confirmation when user logs in <br />
            Please click on 'Confirm' to activate
            <div class="mt-4">
              <v-btn
                @click="actionOnTwoFa(false)"
                :loading="sending"
                color="primary"
                large
                >Confirm</v-btn
              >
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addPinDialog" max-width="600">
      <v-card>
        <v-card-title>Set PIN</v-card-title>
        <v-card-text>
          <v-text-field
            :disabled="updatingPin"
            v-model="pin"
            label="4 digit pin"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="updatingPin"
            @click="addPin"
            depressed
            color="primary"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      @click:outside="setupPinStepper = 1"
      max-width="700"
      v-model="setupPinDialog"
      persistent
    >
      <v-card>
        <v-stepper v-model="setupPinStepper">
          <!-- <v-stepper-header>
            <v-stepper-step :complete="setupPinStepper > 1" step="1">
              Set Up Pin
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="setupPinStepper > 2" step="2">
              Set up your new 4-digit PIN
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Confirm your 4-digit PIN </v-stepper-step>
          </v-stepper-header> -->

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card flat class="text-center">
                <v-card-title class="text-center">
                  <span class="mx-auto">Set Up Pin</span>
                </v-card-title>
                <v-card-text>
                  <p>Input the 4-digit PIN sent to your email address</p>
                  <PincodeInput secure v-model="oldPinCode" placeholder="*" />
                </v-card-text>
              </v-card>

              <v-btn
                color="primary"
                @click="verifyOldPIN"
                :loading="verifyingOldPin"
              >
                Continue
              </v-btn>

              <v-btn @click="setupPinDialog = false" text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card flat class="text-center">
                <v-card-title>
                  <span class="mx-auto">Setup your new 4-digit PIN</span>
                </v-card-title>
                <v-card-text>
                  <p>
                    The new PIN will now be used to authorize all transactions
                  </p>
                  <PincodeInput secure v-model="newPin" placeholder="*" />
                </v-card-text>
              </v-card>

              <v-btn
                color="primary"
                @click="setupPinStepper = 3"
                :disabled="newPin.length < 4"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card flat class="text-center">
                <v-card-title>
                  <span class="mx-auto">Confirm your 4-digit PIN</span>
                </v-card-title>
                <v-card-text>
                  <p>
                    Ensure you re-enter your PIN correctly to complete the setup
                  </p>
                  <p v-if="newPin !== confirmNewPin && confirmNewPin !== ''">
                    <small class="error--text"> PINS don't match! </small>
                  </p>
                  <PincodeInput
                    secure
                    v-model="confirmNewPin"
                    placeholder="*"
                  />
                </v-card-text>
              </v-card>

              <v-btn text @click="setupPinStepper = 2"> Back </v-btn>
              <v-btn
                :disabled="newPin !== confirmNewPin"
                color="primary"
                @click="changePIN"
                :loading="changingPin"
              >
                Finish
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" v-model="setPinDialog">
      <v-card class="text-center">
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="setPinDialog = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-title>Reset Pin</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="resetPinForm.password"
            type="password"
            filled
            label="Verify Password"
            placeholder="Input your password to proceed"
            :rules="[rules.required]"
          >
          </v-text-field>
          <v-text-field
            v-model="resetPinForm.newPin"
            type="number"
            filled
            label="Inter your new 4 digit pin"
            placeholder="Input your new 4 digit pin"
            :rules="[rules.required, rules.fourDigits]"
          >
          </v-text-field>
          <v-text-field
            v-model="resetPinForm.confirmNewPin"
            type="number"
            filled
            label="Confirm your new 4 digit pin"
            placeholder="Confirm your new 4 digit pin"
            :rules="[rules.required, rules.fourDigits, pinIsEqual]"
          >
          </v-text-field>
          <v-btn @click="resetPIN" :loading="resettingPIN" color="primary"
            >Confirm</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" v-model="changePinDialog">
      <v-card class="text-center">
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="changePinDialog = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-title>Change Pin</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="changePinForm.pin"
            type="text"
            filled
            label="Pin"
            placeholder="Enter your 4 digit pin"
            hint="Enter your 4 digit pin"
            :rules="[rules.required, rules.fourDigits]"
          >
          </v-text-field>
          <v-text-field
            v-model="changePinForm.password"
            type="text"
            filled
            label=" Password"
            placeholder="Input your password to proceed"
            hint="Input your password to proceed"
            :rules="[rules.required]"
          >
          </v-text-field>
          <v-text-field
            v-model="changePinForm.newPin"
            type="number"
            filled
            label="Inter your new 4 digit pin"
            placeholder="Input your new 4 digit pin"
            :rules="[rules.required, rules.fourDigits]"
          >
          </v-text-field>
          <v-text-field
            v-model="changePinForm.confirmNewPin"
            type="number"
            filled
            label="Confirm your new 4 digit pin"
            placeholder="Confirm your new 4 digit pin"
            :rules="[rules.required, rules.fourDigits, pinIsEqualChangePin]"
          >
          </v-text-field>
          <v-btn @click="changePINv2" :loading="isChangingPin" color="primary"
            >Confirm</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" v-model="inputPasswordDialog">
      <v-card class="text-center">
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="inputPasswordDialog = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-title>Enter your password</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="forgetPasswordPin"
            type="text"
            filled
            label="Password"
            placeholder="Enter your password"
            hint="Enter your password"
            :rules="[rules.required]"
          >
          </v-text-field>
          <v-btn @click="forgetPIN" :loading="changingPin" color="primary"
            >Confirm</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Authentication Method</v-card-title>
          <v-card-subtitle
            >Set how you verify your transaction with OTP or PIN;
            <b>You are using {{ user.authenticationType.toUpperCase() }} </b>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              v-if="user.authenticationType === 'otp'"
              depressed
              @click.stop="updateAuthType('pin')"
              :loading="sending"
              class="accent"
              >USE PIN</v-btn
            >
            <v-btn
              v-if="user.authenticationType === 'pin'"
              depressed
              @click.stop="updateAuthType('otp')"
              :loading="sending"
              class="accent"
              >USE OTP</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Set Pin</v-card-title>
          <v-card-subtitle
            >Forgot your 4 digit pin? You can set it here</v-card-subtitle
          >
          <v-card-actions>
            <v-btn depressed @click.stop="setPinDialog = true" class="accent"
              >SET PIN</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card v-if="user.pinSet" class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Change PIN</v-card-title>
          <v-card-subtitle
            >Your 4 digit pin is used to authorize all
            transactions</v-card-subtitle
          >
          <v-card-actions>
            <v-btn depressed @click.stop="changePinDialog = true" class="accent"
              >Change PIN</v-btn
            >
          </v-card-actions>
        </v-card>

        <v-card v-if="user.pinSet" class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Forget PIN?</v-card-title>
          <v-card-subtitle
            >Forget your pin? Enter your password and a new PIN will be sent to
            your Email.</v-card-subtitle
          >
          <v-card-actions>
            <v-btn
              depressed
              @click.stop="inputPasswordDialog = true"
              class="accent"
              >Forget PIN</v-btn
            >
          </v-card-actions>
        </v-card>

        <v-card class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Change Password</v-card-title>
          <v-card-subtitle
            >To sign in your password is required</v-card-subtitle
          >
          <v-card-actions>
            <v-dialog v-model="dialogPassword" persistent max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" depressed v-bind="attrs" v-on="on">
                  Change Password
                </v-btn>
              </template>
              <v-card>
                <v-form ref="changePassword" @submit.prevent="validate">
                  <v-card-title>
                    <span class="headline">Change Password</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            filled
                            label="Old Password"
                            :disabled="changingPassword"
                            :rules="[rules.required, rules.minimum]"
                            :append-icon="
                              showOldPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showOldPassword ? 'text' : 'password'"
                            @click:append="showOldPassword = !showOldPassword"
                            required
                            v-model="form.oldPassword"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            filled
                            label="New Password"
                            :disabled="changingPassword"
                            :rules="[rules.required, rules.minimum]"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            required
                            v-model="form.password"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            filled
                            label="Confirm New Password"
                            :disabled="changingPassword"
                            :rules="[
                              rules.required,
                              rules.minimum,
                              passwordIsEqual
                            ]"
                            :append-icon="
                              showNewPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showNewPassword ? 'text' : 'password'"
                            @click:append="showNewPassword = !showNewPassword"
                            required
                            v-model="form.confirmPassword"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error darken-1"
                      text
                      :disabled="changingPassword"
                      @click="dialogPassword = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      type="submit"
                      :loading="changingPassword"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>

        <v-card class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>2 Factor Authentication</v-card-title>
          <v-card-subtitle
            >2 Factor Authentication is a multi-layer security measure to ensure
            the safety of your account.</v-card-subtitle
          >
          <v-card-actions>
            <v-btn
              v-if="!user.twoFactorAuth"
              depressed
              @click.stop="factorDialog = true"
              class="accent"
              >Enable</v-btn
            >
            <v-btn
              v-else-if="user.twoFactorAuth"
              depressed
              @click.stop="disableDialog = true"
              class="error"
              >Disable</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card class="pa-4 my-3" elevation="5" rounded="lg">
          <v-card-title>Delete Account</v-card-title>
          <v-card-subtitle
            >We do our best to give you a great experience - we’ll be sad to see
            you leave us</v-card-subtitle
          >
          <v-card-actions>
            <v-dialog v-model="dialogDelete" persistent max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed class="error"
                  >Delete Account</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="headline"> Are you sure? </v-card-title>

                <v-card-text>
                  <p>
                    You will be able to contact our support to re-activate your
                    account only within 30 days
                  </p>
                  <v-text-field
                    label="Password"
                    placeholder="Enter your password to confirm action"
                    v-model="deleteAccountPassword"
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="dialogDelete = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="error"
                    :loading="deletingAccount"
                    text
                    @click="deleteAccount"
                    :disabled="!deleteAccountPassword"
                  >
                    yes, delete it
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '../../../utils/rules'
import api from '../../../api/users/profile'
import PincodeInput from 'vue-pincode-input'
import { mapActions, mapState } from 'vuex'
import pinApi from '../../../api/users/pin'
import apiAuth from '../../../api/users/auth'

export default {
  name: 'Security',
  components: {
    PincodeInput
  },
  data: () => ({
    deletingAccount: false,
    inputPasswordDialog: false,
    forgetPasswordPin: '',
    factorDialog: false,
    factorStepper: 1,
    verifyingOldPin: false,
    lastPage2FA: 1,
    oldPinCode: '',
    newPin: '',
    confirmNewPin: '',
    changingPassword: false,
    showOldPassword: false,
    showPassword: false,
    setPinDialog: false,
    showNewPassword: false,
    dialogPassword: false,
    addPinDialog: false,
    dialogDelete: false,
    showAlert: false,
    message: null,
    error: false,
    pin: '',
    updatingPin: false,
    sending: false,
    disableDialog: false,
    rules: {
      required: rules.required,
      minimum: rules.valueWithAtLeast8Characters,
      fourDigits: (v) => (v.length > 0 && v.length <= 4) || 'Must be 4 digits'
    },
    form: {
      password: '',
      oldPassword: '',
      confirmPassword: ''
    },
    setupPinDialog: false,
    setupPinStepper: 1,
    changingPin: false,
    passwordForPin: '',
    resettingPIN: false,
    togglingPIN: false,
    codeQR: '',
    generationCode: false,
    userToken: '',
    sendingToken: false,
    resetPinForm: {
      password: '',
      newPin: '',
      confirmNewPin: ''
    },
    changePinForm: {
      pin: '',
      newPin: '',
      confirmNewPin: '',
      password: ''
    },
    changePinDialog: false,
    isChangingPin: false,
    deleteAccountPassword: ''
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    passwordIsEqual() {
      if (this.form.password) {
        return (
          this.form.password === this.form.confirmPassword ||
          "Password doesn't match"
        )
      }
      return "Password doesn't match"
    },
    pinIsEqual() {
      if (this.resetPinForm.newPin) {
        return (
          this.resetPinForm.newPin === this.resetPinForm.confirmNewPin ||
          "Pin doesn't match"
        )
      }
      return "Pin doesn't match"
    },
    pinIsEqualChangePin() {
      if (this.changePinForm.newPin) {
        return (
          this.changePinForm.newPin === this.changePinForm.confirmNewPin ||
          "Pin doesn't match"
        )
      }
      return "Pin doesn't match"
    }
  },
  methods: {
    close2FADialog() {
      this.factorStepper = 1
      this.factorDialog = false
    },
    async updateAuthType(type) {
      this.sending = true
      const res = await api
        .profile()
        .updateProfile({ authenticationType: type })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.sending = false
        return
      }
      this.$store.commit('user/updateUserData', res.data.data)
      this.sending = false
      this.alert({
        message: 'Authentication method updated successfully',
        status: true
      })
    },
    async verify2FACode() {
      this.sendingToken = true
      const res = await apiAuth
        .auth()
        .verifyTwoFA(this.user._id, { token: this.userToken })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.sendingToken = false
        return
      }
      this.sendingToken = false
      this.lastPage2FA = 3
      const profile = await api.profile().get()

      this.$store.commit('user/updateUserData', profile.data.data)
    },
    async generate2FACode() {
      this.generationCode = true
      const res = await apiAuth.auth().enableTwoFa()
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.generationCode = false
        return
      }

      this.codeQR = res.data.data
      // console.log(res.data.data)
      this.generationCode = false

      this.lastPage2FA = 2
    },
    async verifyOldPIN() {
      this.verifyingOldPin = true
      const res = await pinApi.pin().verifyPin({ pin: this.oldPinCode })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.oldPinCode = ''
        this.verifyingOldPin = false
        return
      }

      this.alert({
        message: 'PIN verified successful',
        status: true
      })

      this.verifyingOldPin = false
      this.setupPinStepper = 2
    },
    async changePIN() {
      this.changingPin = true
      const res = await pinApi.pin().update({ pin: this.newPin })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.changingPin = false
        return
      }

      this.alert({
        message: 'PIN changed successful',
        status: true
      })
      this.changingPin = false
      this.setupPinDialog = false
      const profile = await api.profile().get()

      this.$store.commit('user/updateUserData', profile.data.data)
    },
    async forgetPIN() {
      this.changingPin = true
      const res = await pinApi
        .pin()
        .forgetPin({ password: this.forgetPasswordPin })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.changingPin = false
        return
      }

      this.alert({
        message:
          'Your PIN has been reset Successfully, please check your email',
        status: true
      })
      this.changingPin = false
      this.inputPasswordDialog = false
      const profile = await api.profile().get()

      this.$store.commit('user/updateUserData', profile.data.data)
    },
    async addPin() {
      this.updatingPin = true
      const res = await api.profile().updatePIN({ pin: this.pin })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.updatingPin = false
        return
      }
      this.alert({
        message: 'Update pin successful',
        status: true
      })
      this.updatingPin = false
      this.addPinDialog = false
    },
    validate() {
      if (this.$refs.changePassword.validate()) this.changePassword()
    },
    async resetPIN() {
      this.resettingPIN = true
      const res = await pinApi.pin().resetPin({ ...this.resetPinForm })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.resettingPIN = false
        return
      }

      this.alert({
        message: res.data.data || 'PIN changed successful',
        status: true
      })

      const profile = await api.profile().get()

      this.$store.commit('user/updateUserData', profile.data.data)

      this.resettingPIN = false
      this.setPinDialog = false
      this.passwordForPin = ''
    },
    async changePINv2() {
      this.isChangingPin = true
      const res = await pinApi.pin().changePin({ ...this.changePinForm })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.isChangingPin = false
        return
      }

      this.alert({
        message: res.data.data || 'PIN changed successful',
        status: true
      })

      this.changePinDialog = false
      this.isChangingPin = false
      this.changePinForm = {
        pin: '',
        newPin: '',
        confirmNewPin: '',
        password: ''
      }
    },
    async actionOnTwoFa(action) {
      this.sending = true
      const res = await api.profile().updateProfile({ twoFactorAuth: action })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.sending = false
        return
      }
      this.$store.commit('user/updateUserData', res.data.data)
      this.sending = false
      if (action) {
        this.factorDialog = false
      } else {
        this.disableDialog = false
      }
      this.alert({
        message: 'Two Factor Authentication enabled!',
        status: true
      })
    },
    async changePassword() {
      this.isChangingPin = true
      const res = await apiAuth.auth().changePassword({
        oldPassword: this.form.oldPassword,
        password: this.form.password,
        confirmPassword: this.form.password
      })
      if (res.error) {
        this.alert({
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.changingPassword = false
        this.dialogPassword = false
        setTimeout(() => {
          this.showAlert = false
        }, 3000)
        return
      }

      this.alert({
        message: res.data.data,
        error: false,
        status: true
      })
      this.changingPassword = false
      this.dialogPassword = false
      this.form = { password: '', confirmPassword: '' }
    },

    deleteAccount() {
      this.deletingAccount = true
      apiAuth
        .auth()
        .deactivateAccount(this.deleteAccountPassword)
        .then((res) => {
          if (res.error) {
            this.alert({
              message:
                res.errorMessage.message ||
                `${res.internalError.message}, please try again`,
              error: true,
              status: true
            })
            this.deletingAccount = false
            this.dialogDeleteAccount = false
            setTimeout(() => {
              this.showAlert = false
            }, 3000)
            return
          }
          this.alert({
            message: res.data.data,
            error: false,
            status: true
          })
          this.deletingAccount = false
          this.dialogDeleteAccount = false
          setTimeout(() => {
            this.$router.push('/auth')
            this.$store.commit('user/setToken', '')
            this.$store.commit('user/updateUserData', {})
          }, 1500)
        })
    },

    ...mapActions({
      alert: 'alert'
    })
  }
}
</script>

<style>
div.vue-pincode-input-wrapper {
  /* any styles you want for wrapper */
}

input.vue-pincode-input {
  height: 58px;
  max-width: 58px;
}
a {
  text-decoration: none;
}
</style>
