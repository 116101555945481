<template>
  <div id="dashboard" style="height: 100%">
    <v-app-bar flat app light color="white">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center"> </v-toolbar-title>
      <v-spacer></v-spacer>
      <div
        v-if="checkIfPermission('view notification', admin.permissions)"
        class="mr-5"
      >
        <v-badge
          :content="notifications.length"
          :value="notifications.length > 0"
          overlap
          color="red"
        >
          <v-icon icon @click.stop="notificationModal = !notificationModal"
            >mdi-bell-outline</v-icon
          >
        </v-badge>
      </div>
      <v-badge
        v-if="checkIfPermission('view kyc', admin.permissions)"
        :value="kycPendingCount > 0"
        left
        color="accent"
        overlap
        :content="kycPendingCount"
      >
        <v-btn class="mr-4" to="/admin/admin/kyc" color="primary" depressed
          >KYC</v-btn
        >
      </v-badge>

      <v-menu
        transition="scale-transition"
        origin="center center"
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-2" v-bind="attrs" v-on="on"
            ><v-icon>mdi-account-outline</v-icon></v-btn
          >
        </template>
        <v-list dense class="text-center" elevation="2" min-width="100">
          <v-list-item ripple to="/admin/admin/settings/system-settings">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item ripple to="/admin/admin/profile">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Nav -->
    <!-- Notification -->
    <v-navigation-drawer
      width="300"
      v-model="notificationModal"
      temporary
      app
      right
    >
      <v-sheet color="py-4 pa-4">
        <h4 class="text-h6 text-right py-2">Notification</h4>
        <v-divider class="mb-2"></v-divider>
        <v-card
          v-for="notification in notifications"
          :key="notification._id"
          class="my-2"
        >
          <v-toolbar color="primary">
            <p class="white--text overline mb-0">{{ notification.title }}</p>
            <v-spacer></v-spacer>
            <v-tooltip
              v-if="checkIfPermission('manage notification', admin.permissions)"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="readNotification(notification._id)"
                  dark
                  :disabled="marking"
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  >mdi-close</v-icon
                >
              </template>
              <span>Mark as Read</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <p class="text-capitalize grey--text my-2">
              {{ timeAgo.format(new Date(notification.createdAt)) }}
            </p>

            <p class="grey--text text--darken-1 mb-0">
              {{ notification.message }}
            </p>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-navigation-drawer>
    <!-- Sidebar -->
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      :mini-variant.sync="mini"
      class="primary"
      style="height: 100%; max-height: 100%"
    >
      <v-list-item class="px-2" @click="toggleMini = !toggleMini">
        <v-btn icon small>
          <v-icon class="white--text">{{
            toggleMini ? 'mdi-chevron-right' : 'mdi-chevron-left'
          }}</v-icon>
        </v-btn>
      </v-list-item>
      <div class="py-3 text-center">
        <v-list-item class="px-2">
          <v-list-item-avatar
            class="mx-auto"
            :width="toggleMini ? '' : 105"
            :height="toggleMini ? '' : 105"
          >
            <v-img :src="logo"></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="title white--text">
              {{ admin.name ? admin.name.split(' ')[0] : '...' }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">{{
              admin.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- <v-divider></v-divider> -->
      <v-list dark>
        <template v-for="item in menus">
          <template v-if="item.children && item.children.length > 0">
            <v-list-group
              active-class="border"
              ripple
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:prependIcon>
                <v-icon color="white">{{ item.icon }}</v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                link
                active-class="font-weight-bold primary darken-4"
                :to="child.href"
                v-for="child in item.children"
                :key="child.title"
              >
                <v-list-item-content>
                  <v-list-item-title
                    style="color: white"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item-group :key="item.title">
              <v-list-item
                ripple
                class="my-3"
                :key="item.title"
                link
                :to="item.href"
                active-class="border"
                :color="`${
                  $route.path.split('/').includes(item.href.split('/')[3])
                    ? 'primary'
                    : ''
                }`"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="`${
                      $route.path.split('/').includes(item.href.split('/')[3])
                        ? 'primary'
                        : '#fff'
                    }`"
                    >{{ item.icon }}</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="`${
                      $route.path.split('/').includes(item.href.split('/')[3])
                        ? 'font-weight-medium'
                        : 'white--text font-weight-regular'
                    }`"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main class="grey lighten-4 pb-0 overflow-hidden" style="height: 100%">
      <v-container class="px-4 py-0 fill-height" fluid>
        <v-row class="fill-height">
          <v-col>
            <transition>
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <!-- <Footer /> -->
    </v-main>
  </div>
</template>

<script>
// import Footer from '../components/footer/footer.vue'
// import Sidebar from '../components/sidebar/sidebar.vue'
import logo from '../assets/logo.png'
import { mapState, mapActions, mapGetters } from 'vuex'
import { AdminDashboardMenu as menus } from '../utils/const'
import timeAgo from '../utils/timeAgo'
import systemData from '../api/systemData'

export default {
  name: 'user-dashboard',
  data: () => ({
    timeAgo,
    notificationModal: false,
    sidebarMenu: true,
    toggleMini: false,
    logo,
    menus,
    marking: false
  }),
  components: {
    // Sidebar
    // Footer
  },
  created() {
    this.sidebarMenu = !this.$vuetify.breakpoint.smAndDown
  },
  mounted() {
    this.getKycData()
    this.getSiteSettings()
    this.$store.dispatch('admin/getNotifications')
  },
  methods: {
    ...mapActions({
      getKycData: 'admin/getKycData',
      markNotificationAsRead: 'admin/markNotificationAsRead'
    }),
    readNotification(id) {
      this.marking = true
      this.markNotificationAsRead([id])
      setTimeout(() => {
        this.marking = false
      }, 3000)
    },
    async getSiteSettings() {
      const res = await systemData.data().siteSettings()
      this.$store.commit('admin/setSiteSettings', res.data.data)
    },
    logout() {
      setTimeout(() => {
        this.$router.push('/admin')
        this.$store.commit('admin/setToken', '')
        this.$store.commit('admin/updateAdminData', {})
      }, 1500)
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin,
      alert: (state) => state.alert,
      notifications: (state) => state.admin.notifications
    }),
    ...mapGetters({
      kycPendingCount: 'admin/pendingKycCount'
    }),
    mini: {
      get() {
        return this.toggleMini
      },
      set: (value) => ''
    }
  }
}
</script>

<style>
.border {
  background-color: #fff;
}
</style>
