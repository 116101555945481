<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="700"
  >
    <v-card flat color="warning">
      <v-container>
        <v-card-title class="white--text text-capitalize">{{
          type.action
        }}</v-card-title>
        <v-card-text>
          <p class="body-2 white--text font-weight-medium">
            You are about to {{ type.action }} this {{ type.on }}, do you want
            to proceed?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :disabled="loading"
            @click="$emit('update:open', false)"
            >NO</v-btn
          >
          <v-btn depressed :loading="loading" @click="doSomething()">Yes</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import userApi from '../api/admin/userManagement'

export default {
  name: 'actionOnDialog',
  props: {
    open: Boolean,
    type: Object,
    id: String,
    callback: Function
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async doSomething() {
      this.loading = true
      if (this.type.action === 'suspend') {
        const res = await userApi.users().updateStatus(this.id, false)
        if (res.error) {
          this.$store.dispatch('alert', {
            message:
              res.errorMessage.message ||
              `${res.internalError.message}, please try again`,
            status: true,
            error: true
          })
          // console.log(res)
          this.loading = false
          return
        }
        this.$store.dispatch('alert', {
          message: 'Success',
          status: true,
          error: false
        })
        this.loading = false
        this.$emit('update:open', false)
      } else {
        const res = await userApi.users().updateStatus(this.id, true)
        if (res.error) {
          this.$store.dispatch('alert', {
            message:
              res.errorMessage.message ||
              `${res.internalError.message}, please try again`,
            status: true,
            error: true
          })
          // console.log(res)
          this.loading = false
          return
        }
        this.$store.dispatch('alert', {
          message: 'Success',
          status: true,
          error: false
        })
        this.loading = false
        this.$emit('update:open', false)
      }

      if (this.callback) {
        this.callback()
      }
    }
  }
}
</script>

<style></style>
