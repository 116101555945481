import base64 from 'base-64'
import userApi from '../../api/admin/userManagement'
import notificationsApi from '../../api/notifications'

const state = () => ({
  admin: {},
  siteInfo: {},
  siteSettings: {},
  kycData: { data: [], loading: false },
  token: '',
  notifications: [],
  viewingUser: {},
  countrySetting: {}
})
const getters = {
  getRequestHeaders: (state) => ({
    headers: {
      Authorization: 'Bearer ' + base64.decode(state.token)
    }
  }),
  getCountrySettings: (state) => state.countrySetting,
  pendingKycCount: (state) =>
    state.kycData.data.filter((data) => data.status === 'pending').length
}
const actions = {
  async getKycData({ commit }) {
    commit('setKycData', { loading: true })
    const res = await userApi.users().kycRequest(10)
    commit('setKycData', { data: res.data.data.data, loading: false })
  },
  async markNotificationAsRead({ dispatch }, ids = []) {
    const res = await notificationsApi.notification().markAsReadAdmin(ids)
    if (res.error) {
      return
    }
    dispatch('getNotifications')
  },
  async getNotifications({ state, commit }) {
    const res = await notificationsApi.notification().getForAdmin()
    commit(
      'updateNotification',
      res.data.data.data.filter((d) => !d.isRead)
    )
  }
}
const mutations = {
  updatingViewingUser(state, user) {
    state.viewingUser = user
  },
  updateAdminPermissions(state, permissions) {
    state.admin = {
      ...state.admin,
      permissions
    }
  },
  updateNotification(state, notifications) {
    state.notifications = notifications
  },
  setKycData(state, { data, loading }) {
    state.kycData.data = data || state.kycData.data
    state.kycData.loading = loading
  },
  setSiteSettings(state, settings) {
    state.siteSettings = { ...state.siteSettings, ...settings }
  },
  setCountrySetting(state, currency) {
    const nigeria = currency.find((d) => d.country.toLowerCase() === 'nigeria')
    const ghana = currency.find((d) => d.country.toLowerCase() === 'ghana')
    const kenya = currency.find((d) => d.country.toLowerCase() === 'kenya')

    state.countrySetting = {
      nigeria,
      ghana,
      kenya
    }
  },
  setSiteInfo(state, info) {
    state.siteInfo = info
  },
  updateAdminData(state, admin) {
    if (Object.keys(admin).length === 0) {
      state.admin = {}
      return
    }
    state.admin = admin
  },
  setToken(state, token) {
    if (token === '') {
      localStorage.removeItem('jwt_admin')
      state.token = ''
      return
    }
    const encodedToken = base64.encode(token)
    localStorage.setItem('jwt_admin', encodedToken)
    state.token = encodedToken
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
