<template>
  <v-container>
    <v-overlay :value="loadingBanks" opacity="0.8">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-dialog max-width="700" v-model="editBankAccount">
      <v-card :loading="editingBankAccount">
        <v-form ref="editBankAccountForm" @submit.prevent="validateEdit">
          <v-container>
            <v-card-title> Edit Bank Account</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :disabled="editingBankAccount || gettingName"
                    v-model="bankData.bankName"
                    item-value="code"
                    item-text="bank"
                    :items="localbanks"
                    :loading="localbanks.length == 0"
                    label="Bank Name"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="
                      editingBankAccount || gettingName || !bankData.bankName
                    "
                    filled
                    v-model="bankData.accountNumber"
                    :loading="gettingName"
                    label="Account Number"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="editingBankAccount"
                    filled
                    v-model="bankData.accountName"
                    :readonly="userCountry === COUNTRIES.NG"
                    label="Account Name"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    :disabled="addingBankAccount"
                    filled
                    type="number"
                    v-model="bankData.bvn"
                    label="BVN"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                type="submit"
                :loading="editingBankAccount"
                depressed
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="infoBeforeEdit">
      <v-card>
        <v-card-title>
          <span class="mx-auto">Update Bank Account</span>
        </v-card-title>
        <v-card-text>
          Please note that you can only add a new bank account that has been
          linked to the BVN you added earlier.
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              () => {
                editBankAccount = true
                infoBeforeEdit = false
              }
            "
            color="primary"
            >Continue</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="700" v-model="addAccountDialog">
      <v-card :loading="addingBankAccount">
        <v-form ref="bankAccountForm" @submit.prevent="validate">
          <v-container>
            <v-card-title>Add Bank Account</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :disabled="addingBankAccount || gettingName"
                    v-model="bankDataUpload.bankName"
                    item-value="code"
                    item-text="bank"
                    :items="localbanks"
                    :loading="localbanks.length == 0"
                    label="Bank Name"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="
                      addingBankAccount ||
                      gettingName ||
                      !bankDataUpload.bankName
                    "
                    filled
                    v-model="accountNumber"
                    :loading="gettingName"
                    label="Number"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="addingBankAccount"
                    filled
                    v-model="bankDataUpload.accountName"
                    :readonly="userCountry === COUNTRIES.NG"
                    label="Account Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="bankDataUpload.otp"
                    filled
                    label="OTP"
                    hint="An OTP  will be sent to your registered email to approve this transaction"
                    persistent-hint
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        :loading="sendingOtp"
                        @click="generateOtp('add-account')"
                        color="primary"
                        dark
                      >
                        Request OTP
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- <v-col cols="12">
                  <v-text-field
                    :disabled="addingBankAccount"
                    filled
                    type="number"
                    v-model="bankData.bvn"
                    label="BVN"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                type="submit"
                :loading="addingBankAccount"
                depressed
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500" v-model="deleteAccountDialog">
      <v-card>
        <v-card-title>
          <span class="mx-auto">Delete Bank Account</span>
        </v-card-title>
        <v-card-text>
          <p>Are you sure you want to delete this bank account?</p>
          <div>
            <v-text-field
              v-model="deleteAccountOtp"
              filled
              label="OTP"
              hint="An OTP  will be sent to your registered email to approve this transaction"
              persistent-hint
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:append-outer>
                    <v-btn
                      :loading="sendingOtp"
                      @click="generateOtp('delete-account')"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Request OTP
                    </v-btn>
                  </template>
                  <span>Click here to request OTP</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteBank"
            :disabled="!deleteAccountOtp"
            :loading="deletingBank"
            color="primary"
            >Delete</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card v-if="bankAccounts.length <= 0" class="pa-2">
          <v-card-subtitle class="text-subtitle"
            >No bank account available</v-card-subtitle
          >

          <v-card-actions>
            <v-btn
              depressed
              @click.stop="addAccountDialog = true"
              class="accent"
            >
              {{
                userCountry == COUNTRIES.GH
                  ? 'ADD MTN MOMO NUMBER '
                  : userCountry == COUNTRIES.KE
                  ? 'ADD M-Pesa NUMBER '
                  : 'Add new bank Account'
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-row v-else>
          <v-col v-for="bank in bankAccounts" :key="bank._id" cols="12" sm="6">
            <v-card>
              <v-container>
                <v-card-text>
                  <p class="mb-0 subtitle-2">{{ bank.account_name }}</p>
                  <p class="mb-0 subtitle-2">{{ bank.account_number }}</p>
                  <p class="mb-0 subtitle-2">
                    {{
                      localbanks.length
                        ? localbanks.find((d) => d.code == bank.bank_code).bank
                        : ''
                    }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="deletingBank"
                    @click="
                      () => {
                        deleteAccountDialog = true
                        selectedAccount = bank
                      }
                    "
                    text
                    color="error"
                    >Delete</v-btn
                  >
                  <v-btn depressed @click="edit(bank)" color="accent"
                    >EDIT</v-btn
                  >
                </v-card-actions>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-btn
          @click="addAccountDialog = true"
          v-if="bankAccounts.length > 0 && bankAccounts.length < 2"
          color="accent"
          class="mt-4"
          >ADD</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../../api/systemData'
import bankApi from '../../../api/users/bankAccount'
import misc from '../../../api/users/misc'
import profileApi from '../../../api/users/profile'
import { COUNTRIES } from '../../../utils/countryHelpers'
import { updateProfile } from '../../../utils/helpers'

export default {
  name: 'BankAccount',
  data() {
    return {
      COUNTRIES,
      editBankAccount: false,
      addAccountDialog: false,
      editingBankAccount: false,
      infoBeforeEdit: false,
      localbanks: [],
      bankData: {},
      bankDataUpload: {},
      accountNumber: '',
      isEditing: false,
      addingBankAccount: false,
      gettingName: false,
      bankAccounts: [],
      loadingBanks: false,
      deletingBank: false,
      deleteAccountDialog: false,
      deleteAccountOtp: '',
      selectedAccount: {},
      sendingOtp: false
    }
  },
  created() {
    this.getUserProfile()
    this.getLocalBanksList()
    this.getBanks()
  },
  watch: {
    accountNumber(newValue, oldValue) {
      if (
        newValue.length === 10 &&
        this.user.country !== COUNTRIES.GH &&
        this.user.country !== COUNTRIES.KE
      ) {
        // console.log('call')
        this.getName()
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  methods: {
    async getBanks() {
      this.loadingBanks = true
      const res = await bankApi.profile().getBankAccounts()
      this.bankAccounts = res.data.data
      this.loadingBanks = false
    },
    async getName() {
      this.gettingName = true
      const res = await profileApi.profile().confirmBankAccount({
        accountNumber: this.accountNumber,
        bankCode: this.bankDataUpload.bankName
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true
        })
        this.gettingName = false
        return
      }
      // console.log(res.data.data)
      this.bankDataUpload.accountName = res.data.data.account_name
      this.gettingName = false
    },
    edit(bank) {
      this.isEditing = true
      // console.log(bank)
      this.bankData = {
        id: bank._id,
        accountName: bank.account_name,
        accountNumber: bank.account_number,
        bankName: bank.bank_code
      }
      this.infoBeforeEdit = true
    },
    async deleteBank() {
      this.deletingBank = true
      const res = await bankApi.profile().deleteBankAccount({
        id: this.selectedAccount._id,
        otp: this.deleteAccountOtp
      })

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.deletingBank = false
        return
      }

      this.$store.dispatch('alert', {
        message: 'Bank account deleted!',
        status: true
      })
      this.getBanks()
      this.deleteAccountDialog = false
      this.deleteAccountPin = ''
      this.deletingBank = false
    },
    validate() {
      if (this.$refs.bankAccountForm.validate()) this.addBankAccount()
    },
    validateEdit() {
      if (this.$refs.editBankAccountForm.validate()) this.editBankAccountF()
    },
    async editBankAccountF() {
      this.editingBankAccount = true
      const res = await bankApi.profile().editBankAccount({
        bankId: this.bankData.id,
        accountName: this.bankData.accountName,
        accountNumber: this.bankData.accountNumber,
        bankCode: this.bankData.bankName
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.editingBankAccount = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Edit Successfully',
        status: true
      })

      updateProfile({ profile: res.data.data })
      this.getBanks()

      this.editingBankAccount = false
      this.editBankAccount = false
    },
    async getLocalBanksList() {
      const res = await dataApi.data().getData('user', {
        type: 'localbank',
        params: {
          country: this.user.country
        }
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          error: true,
          message: 'Unable to get bank list'
        })
        return
      }
      this.localbanks = res.data.data.data
    },
    async addBankAccount() {
      this.addingBankAccount = true
      const res = await bankApi.profile().addBankAccount({
        accountNumber: this.accountNumber,
        accountName: this.bankDataUpload.accountName,
        bankCode: this.bankDataUpload.bankName,
        otp: this.bankDataUpload.otp
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.addingBankAccount = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Account Added Successfully',
        status: true
      })

      updateProfile({ profile: res.data.data })
      this.getBanks()

      this.addingBankAccount = false
      this.addAccountDialog = false
    },
    async getUserProfile() {
      await profileApi.profile().get(['bankAccounts'])
    },
    async generateOtp(type) {
      this.sendingOtp = true
      const res = await misc.misc().generateOtp(type)
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
        this.sendingOtp = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'OTP sent successfully, kindly check your email',
        status: true
      })
      this.sendingOtp = false
    }
  }
}
</script>

<style></style>
