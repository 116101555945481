// Layouts
import Auth from '../layouts/AuthAdmin.vue'

// Auth
import Login from '../views/authAdmin/Login.vue'
import VerifyCode from '../views/authAdmin/VerifyCode.vue'
// import ForgetPassword from '../views/authAdmin/ForgetPassword.vue'
// import ResetPassword from '../views/authAdmin/ResetPassword.vue'

const authAdminRoutes = {
  path: '/admin',
  redirect: '/admin',
  component: Auth,
  children: [
    {
      path: '/admin',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/admin/verify-login/:userId',
      component: VerifyCode,
      meta: {
        title: 'Verify Login'
      }
    }
    // {
    //   path: '/admin/reset-password',
    //   component: ForgetPassword,
    //   meta: {
    //     title: 'Reset Password | Admin'
    //   }
    // },
    // {
    //   path: '/admin/password-reset/:token',
    //   component: ResetPassword,
    //   meta: {
    //     title: 'Reset Password | Admin'
    //   }
    // }
  ]
}

export default authAdminRoutes
