import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0D01C7',
        secondary: '#b0bec5',
        accent: '#FDCA25',
        error: '#b71c1c'
      }
    }
  }
})
