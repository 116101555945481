<template>
  <div>
    <main>
      <section class="section-background">
        <router-view />
      </section>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    // const tawk = document.createElement('script')
    // tawk.setAttribute('type', 'text/javascript')
    // tawk.innerText =
    //   "var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://embed.tawk.to/5ecae123c75cbf1769eee9c3/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })();"
    // document.head.appendChild(tawk)
  }
}
</script>

<style scoped>
.section-background {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
</style>
