var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.checkIfPermission('view kyc', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_c('div',[_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":"0.85"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1),_c('h1',[_vm._v("KYC Verification")]),_c('v-row',[_c('v-col',[_c('kyc-details',{attrs:{"actionProgress":_vm.progress,"open":_vm.viewDetailsModal,"kyc":_vm.selectedKyc},on:{"update:actionProgress":function($event){_vm.progress=$event},"update:action-progress":function($event){_vm.progress=$event},"update:open":function($event){_vm.viewDetailsModal=$event}}}),_c('v-sheet',[_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.header,"search":_vm.search,"items":_vm.kycsM,"items-per-page":_vm.perPage,"footer-props":{
                itemsPerPageOptions: [5, 10, 15, 20, 25, 50]
              },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewKYC(item)}}},[_c('v-list-item-title',[_vm._v("View Details")])],1)],1)],1)]}},{key:"item.user",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{ name: 'ViewUser', params: { id: item.userData.id } }}},[_vm._v(_vm._s(item.userData.username))]),_c('div',{staticClass:"subtitle-2 grey--text"},[_vm._v(" "+_vm._s(item.userData.email)+" ")])]}},{key:"item.docs",fn:function(ref){
              var item = ref.item;
return [_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":"_blank","to":("/view-image?imageLink=" + (item.image1))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Image")])])],1),_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":("/view-image?videoLink=" + (item.image2)),"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-video-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Video")])])],1)]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved' || item.status == 'true'
                      ? 'success'
                      : item.status == 'pending'
                      ? 'warning'
                      : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status === 'approved' || item.status === 'true' ? 'Approved' : item.status === 'pending' ? 'Pending' : 'Declined')+" ")])]}}],null,true)})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }