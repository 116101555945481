var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.checkIfPermission('view wallet', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_vm._e(),_c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Wallets History")]),_c('user-details-min',{attrs:{"userId":_vm.minUserId,"open":_vm.openMin},on:{"update:open":function($event){_vm.openMin=$event}}}),_c('v-row',[_c('v-col',[_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v("Debit")]),_c('v-tab',[_vm._v("Credit")])],1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.searchDebit),callback:function ($$v) {_vm.searchDebit=$$v},expression:"searchDebit"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.arrangedTransaction,"items-per-page":_vm.perPage,"loading":_vm.loadingTransaction,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block",on:{"click":function($event){return _vm.openMinDialog(item.user_id)}}},[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString()))])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(item.currency))]),_vm._v(" "+_vm._s(_vm.numberWithCommas(Number(item.amount))))])]}}],null,true)}),_c('fund-details',{attrs:{"actionProgress":_vm.progress,"open":_vm.transactionOpen,"transaction":_vm.transaction},on:{"update:actionProgress":function($event){_vm.progress=$event},"update:action-progress":function($event){_vm.progress=$event},"update:open":function($event){_vm.transactionOpen=$event}}})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.searchCredit),callback:function ($$v) {_vm.searchCredit=$$v},expression:"searchCredit"}})],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.arrangedTransactionCredit,"items-per-page":_vm.perPage,"loading":_vm.loadingTransactionCredit,"footer-props":{
                  itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                },"server-items-length":_vm.apiDataCredit.total},on:{"update:items-per-page":_vm.updateItemPerPageCredit,"update:page":_vm.updatePageCredit},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block",on:{"click":function($event){return _vm.openMinDialog(item.user_id)}}},[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString()))])]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(item.currency))]),_vm._v(" "+_vm._s(_vm.numberWithCommas(Number(item.amount))))])]}}],null,true)}),_c('fund-details',{attrs:{"actionProgress":_vm.progress,"open":_vm.transactionOpen,"transaction":_vm.transaction},on:{"update:actionProgress":function($event){_vm.progress=$event},"update:action-progress":function($event){_vm.progress=$event},"update:open":function($event){_vm.transactionOpen=$event}}})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }