<template>
  <v-container class="pb-10">
    <div
      v-if="!checkIfPermission('view staff', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <v-overlay opacity="0.8" :value="updatingOtp">
        <v-progress-circular indeterminate size="65"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="dialogCreateStaff" max-width="700px">
        <v-form ref="createStaffForm" @submit.prevent="validate">
          <v-card class="py-4 pt-0">
            <v-toolbar flat class="mb-2" color="primary">
              <v-card-title class="white--text"> Create Staff </v-card-title>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :rules="[rules.required]"
                      :disabled="loading"
                      v-model="createStaff.name"
                      label="Staff  Name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      :rules="[rules.required]"
                      v-model="createStaff.username"
                      label="Staff Username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      :rules="[rules.required, rules.email]"
                      v-model="createStaff.email"
                      label="Staff Email"
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :rules="[rules.required]"
                      :disabled="loading"
                      v-model="createStaff.number"
                      label="Staff Phone Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      filled
                      :disabled="loading"
                      v-model="createStaff.branchAddress"
                      label="Branch Address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      v-model="createStaff.city"
                      :rules="[rules.required]"
                      label="City"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      :disabled="loading"
                      v-model="createStaff.state"
                      :rules="[rules.required]"
                      label="State"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-select
                      filled
                      :disabled="loading"
                      v-model="createStaff.role"
                      :rules="[rules.required]"
                      label="Staff Role"
                      :items="[
                        'Admin',
                        'Deposit & Withdrawal',
                        'Sales',
                        'Purchase',
                        'KYC'
                      ]"
                    ></v-select>
                  </v-col> -->
                  <p
                    v-if="
                      checkIfPermission('view permission', admin.permissions)
                    "
                  >
                    STAFF MANAGEMENT PERMISSION
                  </p>
                  <v-row
                    v-if="
                      checkIfPermission('view permission', admin.permissions)
                    "
                  >
                    <v-col
                      cols="12"
                      md="6"
                      v-for="permission in permissions"
                      :key="permission._id"
                    >
                      <v-switch
                        :disabled="loading"
                        v-model="permissionsModel"
                        :label="permission.name"
                        :messages="permission.description"
                        :value="permission._id"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :disabled="loading"
                @click="dialogCreateStaff = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :loading="loading"
                color="blue darken-1"
                text
                type="submit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <staff-details
        :progress.sync="progress"
        :open.sync="staffDetailsDialog"
        :staff="staff"
      />
      <h1 class="mb-5">Staffs</h1>
      <v-row>
        <v-col class="" cols="12">
          <v-container class="py-10">
            <v-card flat color="white">
              <v-card-title class="d-flex align-baseline">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search..."
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn
                  color="accent"
                  v-if="checkIfPermission('create staff', admin.permissions)"
                  @click="dialogCreateStaff = true"
                  class="ml-4 font-weight-bold"
                  >ADD STAFF</v-btn
                >
              </v-card-title>
              <v-data-table
                :loading="data.length == 0"
                :items="arrangeAdmin"
                :search="search"
                :headers="headers"
              >
                <template v-slot:[`item.user`]="{ item }">
                  <span class="font-weight-medium d-block">{{
                    item.username
                  }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <span
                    :class="`text-capitalize rounded py-2 px-3 white--text ${
                      item.status == 'active' ? 'success' : 'error'
                    }`"
                    >{{ item.status }}</span
                  >
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item ripple @click="viewAdmin(item)">
                        <v-list-item-title>View Details</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="updateAdmin(item, true)"
                        v-if="
                          item.status === 'suspended' &&
                          checkIfPermission('manage staff', admin.permissions)
                        "
                        ripple
                      >
                        <v-list-item-title>Activate</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="updateAdmin(item, false)"
                        v-if="
                          item.status === 'active' &&
                          checkIfPermission('manage staff', admin.permissions)
                        "
                        ripple
                      >
                        <v-list-item-title>Suspend</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.registered`]="{ item }">
                  <small>{{ item.registered }}</small>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import staffDetails from '../../../components/admin/staffDetails.vue'
import authApi from '../../../api/admin/auth'
import adminApi from '../../../api/admin/admin'
import dataApi from '../../../api/systemData'
import permissionApi from '../../../api/admin/permissions'
import rules from '../../../utils/rules'
import { mapState } from 'vuex'

export default {
  components: { staffDetails },
  name: 'Staffs',
  watch: {
    progress(newVl, oldVl) {
      if (newVl === 'done') {
        this.getAdmins()
        this.progress = 'starting'
      }
    },
    isAdminOtpActive(newVl, oldVl) {
      if (newVl !== oldVl) {
        this.updateSiteData()
      }
    }
  },
  data: () => ({
    updatingOtp: false,
    loading: false,
    permissionsModel: [],
    progress: 'starting',
    transactionTab: null,
    search: '',
    staffDetailsDialog: false,
    dialogCreateStaff: false,
    createStaff: { email: '' },
    rules: {
      required: rules.required,
      minimum: rules.valueWithAtLeast8Characters,
      email: rules.email
    },
    headers: [
      {
        text: 'User',
        align: 'start',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Staff Name',
        value: 'name'
      },
      {
        text: 'Department',
        value: 'dept'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Registered',
        value: 'registered'
      },
      {
        text: '',
        value: 'action'
      }
    ],
    staff: {},
    data: [],
    permissions: [],
    isAdminOtpActive: false,
    daysOfWeek: [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ]
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin,
      siteSettings: (state) => state.admin.siteSettings
    }),
    arrangeAdmin() {
      if (this.data.length > 0) {
        return this.data.map((d) => ({
          id: d._id,
          status: d.status ? 'active' : 'suspended',
          username: d.username,
          email: d.email,
          name: `${d.name ? d.name : `${d.lname} ${d.fname}`}`,
          registered: new Date(d.createdAt),
          dept: d.role
        }))
      }
      return []
    }
  },
  created() {
    this.getAdmins()
    this.getAvailablePermissions()
    this.isAdminOtpActive = this.siteSettings.adminOtp
  },
  methods: {
    validate() {
      if (this.$refs.createStaffForm.validate()) this.createAdmin()
    },
    async getAvailablePermissions() {
      const res = await permissionApi.permissions().getAll({
        params: {
          limit: 0,
          offset: 0
        }
      })
      this.permissions = res.data.data.data
    },
    async getAdmins() {
      const res = await adminApi.users().getAll()
      this.data = res.data.data.data
    },
    async updateAdmin(item, status) {
      const res = await adminApi.users().updateAdminStatus(item.id, status)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Successful',
        status: true
      })
      this.getAdmins()
    },
    async updatePermission({ userId }) {
      const res = await permissionApi.permissions().updateUserPermission({
        userId,
        permissions: this.permissionsModel
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.loading = false
    },
    async createAdmin() {
      this.loading = true
      const res = await authApi.auth().createNewAdmin(this.createStaff)
      if (res.error) {
        // console.log(res.errorMessage)
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loading = false
        return
      }
      this.updatePermission({ userId: res.data.data.newAdmin._id })
      this.$store.dispatch('alert', {
        message: 'Staff created successfully',
        error: false,
        status: true
      })
      this.loading = false
      this.dialogCreateStaff = false
      this.createStaff = {}
      this.permissionsModel = []
      this.getAdmins()
    },
    viewAdmin(d) {
      const data = this.data.find((user) => user._id === d.id)
      this.staff = {
        info: [
          {
            title: 'Username',
            text: data.username
          },
          {
            title: 'Staff Name',
            text: `${data.name ? data.name : `${data.lname} ${data.fname}`}`
          },
          {
            title: 'Staff Email',
            text: data.email
          },
          {
            title: 'Wallet Funding Amount'
          },
          {
            title: 'Base Outlet Address',
            text: data.address
          },
          {
            title: 'Staff Region',
            text: `${data.city}, ${data.state}`
          },
          {
            title: 'Last Login Date',
            text: new Date(data.login_time).toLocaleString()
          }
        ],
        ...data,
        allowedShifts: this.daysOfWeek.map((day) => {
          const shift = data.allowedShifts.find((s) => s.dayOfWeek === day)
          if (shift) {
            return {
              ...shift,
              status: true
            }
          }
          return {
            dayOfWeek: day,
            startTime: '00:00',
            endTime: '10:00',
            status: false
          }
        }),
        walletFundBalances:
          Array.isArray(data.walletFundBalances) &&
          data.walletFundBalances.length > 0
            ? data.walletFundBalances
            : [
                {
                  balance: 0,
                  currency: 'NGN'
                },
                {
                  balance: 0,
                  currency: 'GHC'
                },
                {
                  balance: 0,
                  currency: 'KSH'
                }
              ],
        registered: new Date(data.createdAt).toDateString(),
        status: data.status ? 'Active' : 'Suspended',
        role: data.role
      }
      this.staffDetailsDialog = true
    },
    async updateSiteData() {
      this.updatingOtp = true
      const res = await dataApi.data().updateSite({
        adminOtp: this.isAdminOtpActive
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Updated Successfully',
        status: true
      })
      this.updatingOtp = false
    }
  }
}
</script>

<style></style>
