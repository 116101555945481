<template>
  <div id="dashboard" style="height: 100%">
    <v-app-bar flat app light color="white">
      <v-app-bar-nav-icon
        @click.stop="sidebarMenu = !sidebarMenu"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">
        <a class="d-flex align-center" href="https://snappyexchange.com">
          <v-img :src="logo" width="45" class="d-none d-md-block mr-2"></v-img>
          <span class="d-none black--text d-md-block">Snappy Exchange</span>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="text-subtitle-2 text-md-h6 text--primary mr-3"
        >{{ wallet ? numberWithCommas(wallet.balance) : 0 }} {{ userCurrency }}
      </span>
      <v-badge
        :content="notifications.length"
        :value="notifications.length > 0"
        overlap
        color="red"
      >
        <v-icon icon @click.stop="notificationModal = !notificationModal"
          >mdi-bell-outline</v-icon
        >
      </v-badge>
      <v-menu
        transition="scale-transition"
        origin="center center"
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mx-2" v-bind="attrs" v-on="on"
            ><v-icon>mdi-account-outline</v-icon></v-btn
          >
        </template>
        <v-list dense class="text-center" elevation="2" min-width="100">
          <v-list-item ripple="" to="/user/settings">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- Notification -->
    <v-navigation-drawer
      width="300"
      v-model="notificationModal"
      temporary
      app
      right
    >
      <v-sheet color="py-4 pa-4">
        <h4 class="text-h6 text-right py-2">Notification</h4>
        <v-divider class="mb-2"></v-divider>
        <v-card
          v-for="notification in notifications"
          :key="notification._id"
          class="my-2"
        >
          <v-toolbar color="primary">
            <p class="white--text overline mb-0">{{ notification.title }}</p>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="readNotification(notification._id)"
                  dark
                  :disabled="marking"
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  >mdi-close</v-icon
                >
              </template>
              <span>Mark as Read</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <p class="text-capitalize grey--text my-2">
              {{ timeAgo.format(new Date(notification.createdAt)) }}
            </p>

            <p class="grey--text text--darken-1 mb-0">
              {{ notification.message }}
            </p>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-navigation-drawer>
    <!-- Sidebar -->
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      :mini-variant.sync="toggleMini"
      class="primary"
      style="height: 100%; max-height: 100%"
    >
      <v-list-item class="px-2" @click="toggleMini = !toggleMini">
        <v-btn icon small>
          <v-icon class="white--text">{{
            toggleMini ? 'mdi-chevron-right' : 'mdi-chevron-left'
          }}</v-icon>
        </v-btn>
      </v-list-item>
      <div class="py-3 text-center">
        <v-list-item class="px-2">
          <v-list-item-avatar
            class="mx-auto"
            :width="toggleMini ? '' : 105"
            :height="toggleMini ? '' : 105"
          >
            <v-img :src="user.image"></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item link to="/user/settings">
          <v-list-item-content>
            <span
              v-if="
                user.fname == 'UNDEFINED' ||
                user.lname == 'UNDEFINED' ||
                !user.fname ||
                !user.lname
              "
              class="subtitle text-error white--text"
              >{{ toggleMini ? '' : 'Kindly update your profile' }}</span
            >
            <v-list-item-title v-else class="title white--text">
              {{
                user.fname == 'UNDEFINED' ||
                user.lname == 'UNDEFINED' ||
                !user.fname
                  ? ``
                  : ` ${user.fname}`
              }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">{{
              user.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="viewRankDialog = true">
          <v-list-item-content>
            <v-chip color="accent rank" v-if="currentRank">
              {{ currentRank.title }}
            </v-chip>
          </v-list-item-content>
        </v-list-item>
      </div>
      <!-- <v-divider></v-divider> -->
      <v-list dark>
        <template v-for="item in menus">
          <template v-if="item.children && item.children.length > 0">
            <v-list-group
              active-class="border"
              ripple
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:prependIcon>
                <v-icon
                  :color="`${
                    $route.path.split('/').includes(item.href.split('/')[2])
                      ? 'primary'
                      : ''
                  }`"
                  >{{ item.icon }}</v-icon
                >
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                link
                :to="child.href"
                v-for="child in item.children"
                :key="child.title"
              >
                <v-list-item-content>
                  <v-list-item-title
                    style="color: white"
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item-group :key="item.title">
              <v-list-item
                ripple
                class="my-3"
                :key="item.title"
                link
                :to="item.href"
                active-class="border"
                :color="`${
                  $route.path.split('/').includes(item.href.split('/')[2])
                    ? 'primary'
                    : ''
                }`"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="`${
                      $route.path.split('/').includes(item.href.split('/')[2])
                        ? 'primary'
                        : '#fff'
                    }`"
                    >{{ item.icon }}</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="`${
                      $route.path.split('/').includes(item.href.split('/')[2])
                        ? 'font-weight-medium'
                        : 'white--text font-weight-regular'
                    }`"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main class="grey lighten-4 pb-0 overflow-hidden" style="height: 100%">
      <v-container class="px-4 py-0 fill-height" fluid>
        <v-row class="fill-height">
          <v-col>
            <transition>
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <!-- <Footer /> -->
    </v-main>

    <v-dialog v-model="viewRankDialog" max-width="500">
      <v-card v-if="currentRank !== null">
        <v-container>
          <div class="py-10">
            <v-img
              :src="currentRank.badge"
              :alt="currentRank.title"
              width="105"
              height="105"
              style="margin: 0 auto"
            />
          </div>
          <div class="text-center px-10">
            You are a
            <span class="primary--text bold">{{ currentRank.title }}</span>
            trade up to
            <span class="primary--text bold">{{
              ranksAmount.minTransactionAmount
            }}</span>
            to become a
            <span class="primary--text bold">{{ nextRank.title }}</span
            >, and earn extra cash.
          </div>
          <div class="text-center">
            <v-btn
              color="primary"
              class="mt-5"
              href="https://blog.snappyexchange.com/category/userranking"
              target="_blank"
            >
              Learn More</v-btn
            >
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Footer from '../components/footer/footer.vue'
// import Sidebar from '../components/sidebar/sidebar.vue'
import { mapState, mapActions } from 'vuex'
import rank from '../api/users/rank'
import logo from '../assets/logo.png'
import { getCurrencyCode } from '../utils/countryHelpers'
import { numberWithCommas } from '../utils/helpers'
import timeAgo from '../utils/timeAgo'

export default {
  name: 'user-dashboard',
  data: () => ({
    viewRankDialog: false,
    numberWithCommas,
    marking: false,
    timeAgo,
    notificationModal: false,
    sidebarMenu: true,
    toggleMini: false,
    logo,
    userTawk: {},
    currentRank: null,
    totalAmountTrader: 0,
    nextRank: null,
    menus: [
      {
        title: 'Dashboard',
        href: '/user/dashboard',
        icon: 'mdi-view-dashboard'
      },
      {
        title: 'Transactions',
        href: '/user/transactions',
        icon: 'mdi-bank-transfer'
      },
      {
        title: 'Wallet',
        href: '/user/wallet',
        icon: 'mdi-wallet-outline'
      },
      {
        title: 'Products',
        href: '/user/products',
        icon: 'mdi-apps'
      },
      {
        title: 'Rates',
        href: '/user/rates',
        icon: 'mdi-chart-histogram'
      },
      {
        title: 'Download our app',
        href: '/user/download',
        icon: 'mdi-download'
      },
      {
        title: 'Promo Code',
        href: '/user/promo-codes',
        icon: 'mdi-percent-outline'
      },
      {
        title: 'Leaderboard Info',
        href: '/user/leaderboard-info',
        icon: 'mdi-account-multiple-outline'
      },
      // {
      //   title: 'Message',
      //   href: '/user/messages',
      //   icon: 'mdi-android-messages',
      //   children: [
      //     {
      //       title: 'Manage Message',
      //       href: '/user/messages/create-message'
      //     },
      //     {
      //       title: 'Create Testimonial',
      //       href: '/user/messages/create-testimonial',
      //       icon: 'mdi-android-messages'
      //     }
      //   ]
      // },
      {
        title: 'Referral System',
        href: '/user/referral-system',
        icon: 'mdi-account-arrow-right'
      },
      {
        title: 'Settings',
        href: '/user/settings',
        icon: 'mdi-account-cog'
      }
    ]
  }),
  components: {
    // Sidebar
    // Footer
  },
  created() {
    this.sidebarMenu = !this.$vuetify.breakpoint.smAndDown
    this.getCurrentRank()
  },
  mounted() {
    this.$store.dispatch('user/getNotifications')
    // console.log(!this.$vuetify.breakpoint.smAndDown)
    // const tawk = document.createElement('script')
    // tawk.setAttribute('type', 'text/javascript')
    // tawk.innerText =
    //   "var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date(); (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://embed.tawk.to/5ecae123c75cbf1769eee9c3/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })();"
    // document.head.appendChild(tawk)
  },
  methods: {
    async getCurrentRank() {
      const res = await rank.rankApi().getUserCurrentRank()

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })

        return
      }

      this.currentRank = res.data.data.currentRank
      this.nextRank = res.data.data.nextRank
      this.totalAmountTrader = res.data.data.totalAmount
    },
    readNotification(id) {
      this.marking = true
      this.markNotificationAsRead([id])
      setTimeout(() => {
        this.marking = false
      }, 3000)
    },
    logout() {
      setTimeout(() => {
        this.$router.push('/auth')
        this.$store.commit('user/setToken', '')
        this.$store.commit('user/updateUserData', {})
      }, 1500)
    },
    ...mapActions({
      markNotificationAsRead: 'user/markNotificationAsRead'
    })
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      alert: (state) => state.alert,
      wallet: (state) => state.user.wallet,
      notifications: (state) => state.user.notifications,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency,
      ranksAmount() {
        return {
          amountNeed: numberWithCommas(
            (this.nextRank.minTransactionAmount - this.totalAmountTrader) /
              this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
          ),
          minTransactionAmount: `${getCurrencyCode(
            this.userCountry
          )} ${numberWithCommas(
            Number(
              this.nextRank.minTransactionAmount /
                this.countrySetting?.[this.userCountry.toLowerCase()].nairaRate
            ).toFixed(2)
          )}`
        }
      }
    })
  },

  head: {
    title: 'Snappy Exchange'
  }
}
</script>

<style>
.border {
  background-color: #fff;
}

.rank {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
}

.bold {
  font-weight: 600;
}
</style>
