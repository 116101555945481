import axios from 'axios'
import Store from '../store'
import { errorHandler, successHandler } from './helpers/handler'
import { BASEURL } from './const'

const URL = `${BASEURL}/promocodes`

export default {
  promoCodes() {
    return {
      createCode: async (data) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodes: async ({ param, requester = 'user' }) => {
        let endpoint

        if (requester === 'user') {
          endpoint = `${URL}/user`
        } else {
          endpoint = `${URL}`
        }

        try {
          const res = await axios.get(endpoint, {
            params: param,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCode: async ({ param, id, requester }) => {
        const endpoint = `${URL}/${id}`
        try {
          const res = await axios.get(endpoint, {
            params: { param },
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateCode: async (data) => {
        const endpoint = `${URL}/${data._id || data.id}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteCode: async (data) => {
        const endpoint = `${URL}/${data._id}`
        try {
          const res = await axios.delete(endpoint, {
            data,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodeUsage: async ({ params, requester = 'user' }) => {
        let endpoint = `${URL}/usage`

        if (requester === 'user') {
          endpoint = `${URL}/usage/user`
        }

        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getCodeUsageById: async ({ param, id }) => {
        const endpoint = `${URL}/usage/${id}`
        try {
          const res = await axios.get(endpoint, {
            params: { param },
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      calculateCodeBonus: async (data) => {
        const endpoint = `${URL}/calculate-bonus`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
