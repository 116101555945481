<template>
  <div>
    <main>
      <section class="section-background">
        <router-view />
      </section>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
.section-background {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
</style>
