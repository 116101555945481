<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view wallet', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div>
      <h1 class="mb-5">Wallets</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />
      <v-row>
        <v-col>
          <v-card flat color="white">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="arrangedTransaction"
              :items-per-page="perPage"
              :loading="loadingTransaction"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
              }"
              @update:items-per-page="updateItemPerPage"
              @update:page="updatePage"
              :server-items-length="apiData.total"
            >
              <template v-slot:[`item.user`]="{ item }">
                <!-- <router-link
                  :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                  class="font-weight-medium text-capitalize d-block"
                  >{{ item.user }}</router-link
                > -->
                <span
                  class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                  @click="openMinDialog(item.user_id)"
                >
                  {{ item.user }}
                </span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-card
                  :color="
                    item.status == 'approved'
                      ? 'success'
                      : item.status == 'pending'
                      ? 'warning'
                      : 'error'
                  "
                  flat
                  rounded="md"
                  outlined
                  class="text-capitalize text-center white--text pa-1"
                >
                  {{ item.status }}
                </v-card>
              </template>

              <!-- <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template> -->
              <template v-slot:[`item.balance`]="{ item }">
                <span>
                  <span class="grey--text text--darken-2">{{
                    item.currency
                  }}</span>
                  {{ numberWithCommas(Number(item.balance)) }}</span
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn small depressed @click.stop="selectTransaction(item)"
                  >View Details</v-btn
                >
              </template>
            </v-data-table>
            <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
            <fund-details
              :actionProgress.sync="progress"
              :open.sync="transactionOpen"
              :transaction="transaction"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import fundDetails from '../../../components/fundDetails.vue'
import walletApi from '../../../api/admin/wallet'
import { mapState } from 'vuex'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import { numberWithCommas } from '../../../utils/helpers'
import _ from 'lodash'
import { getCurrencyCode } from '../../../utils/countryHelpers'

export default {
  components: {
    fundDetails,
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    openMin: false,
    minUserId: '',
    progress: 'starting',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Name',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email'
      },
      {
        text: 'Money in Wallet',
        value: 'balance'
      }
      // {
      //   text: 'Bonus (NGN)',
      //   value: 'bonus'
      // },
      // {
      //   text: 'Token',
      //   value: 'token'
      // }
    ],
    transactions: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1
  }),
  created() {
    this.getTransactions(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getTransactions()
      }
      this.progress = 'starting'
    },
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangedTransaction() {
      if (this.transactions.length !== 0) {
        return this.transactions.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency:
            transaction.currencyCode ??
            getCurrencyCode(transaction?.user?.country)
        }))
      }
      return []
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await walletApi.data().getUserWallets({
        populate: ['user'],
        limit: this.perPage,
        keyword: this.search
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await walletApi.data().getUserWallets({
        populate: ['user'],
        limit: this.perPage,
        offset: length
      })
      // this.transactions = res.data.data.data.filter((t) => t.balance > 0)
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    }
  }
}
</script>

<style></style>
