// Layouts
import Auth from '../layouts/Auth.vue'

// Auth
// import Login from '../views/auth/Login.vue'
// import Register from '../views/auth/Register.vue'
// import ForgotPassword from '../views/auth/ForgotPassword.vue'
// import VerifyEmail from '../views/auth/verifyEmail.vue'
// import ResetPassword from '../views/auth/ResetPassword.vue'
// import Verify from '../views/auth/VerifyCode.vue'
// import ResendEmailVerification from '../views/auth/ResendEmailVerification.vue'

const authRoutes = {
  path: '/',
  redirect: '/login',
  component: Auth,
  children: [
    {
      path: '/login',
      component: () => import('../views/auth/Login.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/register',
      component: () => import('../views/auth/Register.vue'),
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/forgot-password',
      component: () => import('../views/auth/ForgotPassword.vue'),
      meta: {
        title: 'Forgot Password'
      }
    },
    {
      path: '/verify/:token',
      component: () => import('../views/auth/verifyEmail.vue'),
      meta: {
        title: 'Verify Email'
      }
    },
    {
      path: '/verify/account/:userId',
      component: () => import('../views/auth/VerifyCode.vue'),
      meta: {
        title: '2FA'
      }
    },
    {
      path: '/password-reset/:token',
      component: () => import('../views/auth/ResetPassword.vue'),
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/register/success',
      component: () => import('../views/auth/ResendEmailVerification.vue'),
      meta: {
        title: 'Registration Successful'
      }
    }
  ]
}

export default authRoutes
