<template>
  <v-container class="pb-10">
    <div>
      <h1 class="mb-5">Users Referrals</h1>
      <v-row>
        <v-col class="" cols="12">
          <!-- All -->

          <user-list-table />

          <!-- Active -->
          <!-- Inactive -->
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import UserListTable from '../../../components/admin/userListReferrals'
import { mapActions, mapState } from 'vuex'

export default {
  components: { UserListTable },
  name: 'Users',

  data: () => ({
    transactionTab: null,
    userDetailsDialog: false,
    active: [],
    inactive: [],
    loadingActive: false,
    loadingInactive: false
  }),
  created() {},
  methods: {
    ...mapActions({
      alert: 'alert'
    })
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  }
}
</script>

<style></style>
