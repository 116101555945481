<template>
  <div
    style="position: absolute; top: 0; left: 0; right: 0; z-index: 100"
    v-if="message"
    :class="`animate__animated animate__slideInDown font-weight-bold d-flex justify-space-between ${type} white--text pa-3`"
  >
    <h5 class="width: 90%" v-text="message ? message : ''"></h5>
    <h5
      @click="$emit('update:message', null)"
      class="cursor-pointer font-weight-bold"
    >
      X
    </h5>
  </div>
</template>

<script>
export default {
  name: 'NotificationMessage',
  props: {
    message: { required: true },
    type: {
      type: String,
      validator: function (value) {
        return ['success', 'warning', 'error', 'info'].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style></style>
