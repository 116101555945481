var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-10"},[(_vm.info.totalUsers)?_c('div',{staticClass:"flex items-center justify-between mb-5 gap-x-10"},[_c('div',{staticClass:"bg-yellow-100 rounded-md px-4 py-6 w-full shadow"},[_c('p',{staticClass:"text-xs text-opacity-75 m-0 mb-0"},[_vm._v("Total Transaction Amount")]),_c('p',{staticClass:"m-0 mb-0 text-2xl font-medium"},[_vm._v(_vm._s(_vm.info.totalTransactionAmount))])]),_c('div',{staticClass:"bg-blue-100 rounded-md px-4 py-6 w-full shadow"},[_c('p',{staticClass:"text-xs text-opacity-75 m-0 mb-0"},[_vm._v("Total Users")]),_c('p',{staticClass:"m-0 mb-0 text-2xl font-medium"},[_vm._v(_vm._s(_vm.info.totalUsers))])]),_c('div',{staticClass:"bg-green-100 rounded-md px-4 py-6 w-full shadow"},[_c('p',{staticClass:"text-xs text-opacity-75 m-0 mb-0"},[_vm._v("Total Users With Approved Transactions")]),_c('p',{staticClass:"m-0 mb-0 text-2xl font-medium"},[_vm._v(_vm._s(_vm.info.totalUsersWithApprovedTransactions))])]),_c('div',{staticClass:"bg-red-100 rounded-md px-4 py-6 w-full shadow"},[_c('p',{staticClass:"text-xs text-opacity-75 m-0 mb-0"},[_vm._v("Transaction Count")]),_c('p',{staticClass:"m-0 mb-0 text-2xl font-medium"},[_vm._v(_vm._s(_vm.info.transactionCount))])])]):_vm._e(),_c('user-details',{attrs:{"open":_vm.userDetailsDialog,"user":_vm.user},on:{"update:open":function($event){_vm.userDetailsDialog=$event}}}),_c('v-card',{attrs:{"flat":"","color":"white"}},[_c('v-card-title'),_c('div',{staticClass:"px-4 grid grid-cols-4 gap-x-5"},[_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date range","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',[_c('v-select',{attrs:{"items":[
            {
              text: 'Purchase',
              value: 'purchase'
            },
            {
              text: 'Sale',
              value: 'sale'
            }
          ],"label":"Transaction Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',[_c('v-select',{attrs:{"items":[
            {
              text: 'Coin',
              value: 'coin'
            },
            {
              text: 'Giftcard',
              value: 'giftcard'
            }
          ],"label":"Transaction Type"},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('div',[_c('v-select',{attrs:{"items":['Nigeria', 'Ghana', 'Kenya'],"label":"Country"},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getUsers()}}},[_vm._v("Filter")])],1)]),_c('v-data-table',{attrs:{"loading":_vm.loadingUser,"items":_vm.users,"search":_vm.search,"headers":_vm.headers,"footer-props":{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
      },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-5 py-4"},[_c('v-alert',{attrs:{"value":true,"icon":"mdi-alert"}},[_vm._v(" Pick a filter option ")])],1)]},proxy:true},{key:"item.user",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{ name: 'ViewUser', params: { id: item.userId } }}},[_vm._v(_vm._s(((item.fname) + " " + (item.lname))))]),_c('small',{staticClass:"font-weight-regular grey--text text--darken-2 d-block"},[_vm._v(_vm._s(item.username))])]}},{key:"item.registered",fn:function(ref){
      var item = ref.item;
return [_c('small',[_vm._v(_vm._s(new Date(item.dateJoined).toDateString()))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }