import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { errorHandler, successHandler } from '../helpers/handler'

const URL = `${BASEURL}/auth`

export default {
  auth() {
    return {
      enableTwoFa: async () => {
        const endpoint = `${URL}/twofa/enable`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyTwoFA: async (userId, { token }) => {
        const endpoint = `${URL}/twofa/${userId}`
        try {
          const res = await axios.post(endpoint, { token })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resendTwoFaCode: async (userId) => {
        const endpoint = `${URL}/twofa/${userId}`
        try {
          const res = await axios.get(endpoint)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendVerificationEmail: async (email) => {
        const endpoint = `${URL}/token/resend`
        const data = { email }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      changePassword: async ({ oldPassword, password, confirmPassword }) => {
        const endpoint = `${URL}/password/change`
        const data = { oldPassword, password, confirmPassword }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyEmail: async (token) => {
        const endpoint = `${URL}/verify/email/${token}`
        try {
          const res = await axios.get(endpoint)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resetPassword: async ({ password, token }) => {
        const endpoint = `${URL}/password/reset/${token}`
        const data = { password, confirmPassword: password }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resetPasswordEmail: async (email) => {
        const endpoint = `${URL}/password/recover`
        const data = { email }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      register: async ({
        email,
        referrer,
        password,
        username,
        fname,
        lname,
        phone,
        country
      }) => {
        const endpoint = `${URL}/register`
        const data = {
          email,
          password,
          referrer,
          confirmPassword: password,
          username,
          fname,
          lname,
          phone,
          country
        }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      login: async ({ email, password }) => {
        const endpoint = `${URL}/login`
        const data = { email, password }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deactivateAccount: async (password) => {
        const endpoint = `${BASEURL}/deactivate-account`
        try {
          const res = await axios.post(
            endpoint,
            { password },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
