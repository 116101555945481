var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"mb-5"},[_vm._v("Payment Gateways")]),_c('v-card',{attrs:{"flat":""}},[_c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Bank Account Lists")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.methods,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(new Date(item.createdAt).toDateString()))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.status === false)?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.actionOnCurrency(item, 'activate')}}},[_c('v-list-item-title',[_vm._v("Activate")])],1):_vm._e(),(item.status === true)?_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.actionOnCurrency(item, 'deactivate')}}},[_c('v-list-item-title',[_vm._v("Deactivate")])],1):_vm._e()],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("text-capitalize rounded py-2 px-3 white--text " + (item.status == true ? 'success' : 'error'))},[_vm._v(_vm._s(item.status))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }