<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loading" class="pa-2">
          <v-card-title> Notification Settings </v-card-title>
          <v-card-subtitle
            >In addition to these alerts, we may send you important information
            about our products and services.</v-card-subtitle
          >
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center text-h6">Notification Type</th>
                  <th class="text-left text-h6">Email Alert</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="item in notif" :key="item.name">
                  <td class="text-center">{{ item.name }}</td>
                  <td class="text-center mx-auto">
                    <v-switch
                      v-if="item.name === 'Buy or Sell'"
                      class="mx-auto text-center"
                      color="accent"
                      readonly
                      inset
                      v-model="one"
                    ></v-switch>
                    <v-switch
                      v-else
                      :readonly="item.readonly"
                      :disabled="loading"
                      class="mx-auto text-center"
                      color="accent"
                      v-model="user[`${item.value}`]"
                      @change="toggleState(item.value)"
                      inset
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profileApi from '../../../api/users/profile'
import { mapState } from 'vuex'
import { updateProfile } from '../../../utils/helpers'

export default {
  name: 'Notification',
  data: () => ({
    one: true,
    loading: false,
    headers: [
      {
        text: 'Notification Type',
        align: 'center',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Email Alert',
        align: 'center',
        sortable: false,
        value: 'action'
      }
    ],
    notif: [
      {
        name: 'Send or Receive Funds',
        readonly: true,
        value: 'fundEmailAlert'
      },
      {
        name: 'Buy or Sell',
        readonly: true,
        value: 'buyOrSellEmailAlert'
      },
      {
        name: 'Newsletter',
        action: '',
        value: 'newsletterAlert'
      }
    ]
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  methods: {
    async toggleState(data) {
      this.loading = true
      // console.log(this.user[data])
      const user = await profileApi
        .profile()
        .updateProfile({ [data]: !!this.user[data] })
      if (user.error) {
        this.$store.dispatch('alert', {
          message: user.errorMessage.message,
          error: true,
          status: true
        })
        this.loading = false
        return
      }
      updateProfile({ profile: user.data.data })
      this.$store.dispatch('alert', {
        message: 'Update successful',
        status: true
      })
      this.loading = false
    }
  }
}
</script>

<style></style>
