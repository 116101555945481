import axios from 'axios'
import Store from '../../store/index'

import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/admin/auth`

export default {
  auth() {
    return {
      login: async ({ email, password }) => {
        const endpoint = `${URL}/login`
        const data = { email, password }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyOTP: async ({ userId, otp }) => {
        const endpoint = `${URL}/verify-otp`
        const data = { userId, otp }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resendOTP: async ({ userId }) => {
        const endpoint = `${URL}/resend-otp`
        const data = { userId }
        try {
          const res = await axios.post(endpoint, data)
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      changePassword: async ({ password, confirmPassword }) => {
        const endpoint = `${URL}/password/change`
        const data = { password, confirmPassword }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },

      createNewAdmin: async (data) => {
        const endpoint = `${URL}/new`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getPasswordResetLink: async ({ email }) => {
        const endpoint = `${URL}/password/recover`
        try {
          const res = await axios.post(endpoint, { email })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resetPassword: async ({ password, token }) => {
        const endpoint = `${URL}/password/reset/${token}`
        try {
          const res = await axios.post(endpoint, {
            password,
            confirmPassword: password
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
