<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view fund request', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1 class="mb-5">Wallet Funding Requests</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />
      <v-row>
        <v-col>
          <v-card flat color="white">
            <v-card-title>
              <v-select
                v-model="selectedStatus"
                :items="status"
                label="Select Status"
                item-text="text"
                item-value="value"
              ></v-select>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="arrangedTransaction"
              :loading="loadingTransaction"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 25, 50]
              }"
              @update:items-per-page="updateItemPerPage"
              @update:page="updatePage"
              :server-items-length="apiData.total"
            >
              <template v-slot:[`item.user`]="{ item }">
                <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.user }}</router-link
              > -->
                <span
                  class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                  @click="openMinDialog(item.user_id)"
                >
                  {{ item.user }}
                </span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-card
                  :color="
                    item.status == 'approved'
                      ? 'success'
                      : item.status == 'pending'
                      ? 'warning'
                      : 'error'
                  "
                  flat
                  rounded="md"
                  outlined
                  class="text-capitalize text-center white--text pa-1"
                >
                  {{ item.status }}
                </v-card>
              </template>
              <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <span>
                  {{ numberWithCommas(item.amount) }}
                  <small class="grey--text text--darken-2">NGN</small>
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  small
                  depressed
                  @click.stop="selectTransaction(item, 'declined')"
                  >View Details</v-btn
                >
              </template>
            </v-data-table>
            <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
            <funding-details
              @update:actionProgress="handleActionProgressUpdate"
              :open.sync="transactionOpen"
              :transaction="transaction"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import fundingDetails from '../../components/fundingDetails.vue'
import walletApi from '../../api/admin/wallet'
import { numberWithCommas } from '../../utils/helpers'
import UserDetailsMin from '../../components/userDetailsMin.vue'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  components: {
    fundingDetails,
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    openMin: false,
    minUserId: '',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Username',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Reference',
        value: 'reference'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'action',
        sortable: false
      }
    ],
    transactions: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1,
    selectedStatus: 'pending',
    status: [
      {
        text: 'Pending',
        value: 'pending'
      },
      {
        text: 'Approved',
        value: 'approved'
      },
      {
        text: 'Declined',
        value: 'declined'
      }
    ]
  }),
  created() {
    this.getRequests(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getRequests(0)
        return
      }
      this.debounceSearch()
    },
    selectedStatus(newValue) {
      this.getRequests(0)
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangedTransaction() {
      return this.transactions.map((transaction) => {
        return {
          ...transaction,
          user: transaction.user.username,
          username: transaction.user.username,
          user_id: transaction.user._id,
          date: new Date(transaction.createdAt).toLocaleString(),
          time: new Date(transaction.createdAt).toLocaleTimeString(),
          email: transaction.user.email,
          actionBy: transaction?.actionBy?.username
        }
      })
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getRequests((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getRequests((this.lastPage - 1) * this.perPage)
    },
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await walletApi.data().viewFundRequests({
        populate: ['user', 'actionBy'],
        limit: this.perPage,
        offset: length,
        keyword: this.search,
        status: this.selectedStatus
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async getRequests(length) {
      this.loadingTransaction = true
      const res = await walletApi.data().viewFundRequests({
        populate: ['user', 'actionBy'],
        limit: this.perPage,
        status: this.selectedStatus,
        offset: length
      })

      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    selectTransaction(transaction, status) {
      this.transaction = transaction
      this.selectTransactionStatus = status
      this.transactionOpen = true
    },
    handleActionProgressUpdate() {
      this.getRequests(0)
    }
  }
}
</script>

<style></style>
