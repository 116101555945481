<template>
  <v-container>
    <div>
      <h1 class="mb-5">Trustees</h1>
      <delete-dialog
        :progress.sync="deleteProgress"
        :open.sync="deleteDialog"
        :type="deleteData"
      ></delete-dialog>
      <v-dialog v-model="editDialog" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title>Edit Trustee </v-card-title>
          </v-toolbar>

          <v-container>
            <v-row v-if="currency !== {}">
              <v-col cols="12">
                <v-text-field
                  v-model="trustee.name"
                  label="Name"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="trustee.title"
                  label="Title"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="trustee.facebook"
                  label="Facebook"
                  type="url"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="trustee.instagram"
                  label="Instagram"
                  type="url"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="trustee.youtube"
                  label="Youtube"
                  filled
                  type="url"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Twitter"
                  v-model="trustee.twitter"
                  filled
                  type="url"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="trustee.image"
                  filled
                  :loading="sendingImage"
                  counter
                  show-size
                  accept="image/*"
                  label="Image"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                :disabled="!trustee.image || sendingImage"
                depressed
                :loading="loadingEdit"
                @click="editTrustee"
                color="accent"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="createDialog" max-width="600">
        <v-card>
          <v-toolbar flat>
            <v-card-title>Create Trustee </v-card-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newTrustee.name"
                  label="Name"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newTrustee.title"
                  label="Title"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newTrustee.facebook"
                  label="Facebook"
                  type="url"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newTrustee.instagram"
                  label="Instagram"
                  type="url"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newTrustee.youtube"
                  label="Youtube"
                  filled
                  type="url"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Twitter"
                  v-model="newTrustee.twitter"
                  filled
                  type="url"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="newTrustee.image"
                  filled
                  :loading="sendingImage"
                  counter
                  show-size
                  accept="image/*"
                  label="Image"
                >
                </v-file-input>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                :loading="loading"
                :disabled="!newTrustee.image"
                @click="createTrustee"
                color="accent"
                >Create</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <v-card flat>
        <v-container>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn @click="createDialog = true" color="accent" depressed>
              <v-icon left>mdi-plus</v-icon>
              Create New</v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :loading="trustees.length === 0"
              :items="trustees"
              :headers="headers"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span class="font-weight-bold text-capitalize">{{
                  item.name
                }}</span>
              </template>
              <template v-slot:[`item.image`]="{ item }">
                <v-img :src="item.image" class="pa-2" width="45px"></v-img>
              </template>
              <template v-slot:[`item.rate`]="{ item }">
                <span class="text-capitalize">
                  1 {{ item.symbol }} = <b>{{ item.price }}</b></span
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="selectTrustee(item)" ripple>
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="actionOnCurrency(item, 'deactivate')"
                      v-if="item.status === true"
                      ripple
                    >
                      <v-list-item-title>Deactivate</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="actionOnCurrency(item, 'activate')"
                      v-if="item.status === false"
                      ripple
                    >
                      <v-list-item-title>Activate</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteCurrency(item)" ripple>
                      <v-list-item-title class="text--error"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span
                  :class="`d-block text-center text-capitalize rounded py-2 px-3 white--text ${
                    item.status == true ? 'success' : 'error'
                  }`"
                  >{{ item.status ? 'Active' : 'Inactive' }}</span
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-container>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import { mapState } from 'vuex'

export default {
  components: { deleteDialog },
  data: () => ({
    editDialog: false,
    deleteProgress: 'starting',
    deleteData: {},
    deleteDialog: false,
    loading: false,
    bankAccount: false,
    currency: {},
    trustee: {},
    editSelectedCurrency: false,
    createDialog: false,
    headers: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'title',
        text: 'title'
      },
      {
        value: 'image',
        text: 'Image'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    loadingEdit: false,
    preset: 'rwy1auea',
    cloudName: 'snappyexchange',
    trustees: [],
    newTrustee: {
      name: '',
      title: '',
      status: true,
      imageToUpload: null
    },
    sendingImage: false
  }),
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getSystemTrust()
  },
  watch: {
    deleteProgress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getSystemTrust()
        this.deleteProgress = 'starting'
      }
    }
  },
  methods: {
    deleteCurrency(trust) {
      this.deleteData = {
        type: 'trust',
        data: trust
      }
      this.deleteDialog = true
    },
    async editTrustee() {
      this.loadingEdit = true

      const formData = new FormData()

      for (const key in this.trustee) {
        formData.append(key, this.trustee[key])
      }

      const res = await dataApi
        .data()
        .editSystemData({ type: 'trust', typeId: this.trustee._id }, formData, {
          'Content-Type': 'multipart/form-data'
        })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loadingEdit = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Trustee edited',
        status: true,
        error: false
      })
      this.loadingEdit = false
      this.editDialog = false
      this.currency = {}
      this.editDialog = false
      this.getSystemTrust()
    },
    async actionOnCurrency(currency, action) {
      const res = await dataApi
        .data()
        .editSystemData(
          { type: 'trust', typeId: currency._id },
          { ...currency, status: action === 'activate' }
        )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: `Currency ${action}`,
        status: true,
        error: false
      })
      this.getSystemTrust()
    },
    selectTrustee(trustee) {
      this.trustee = trustee
      this.editDialog = true
    },
    async createTrustee() {
      this.loading = true
      const formData = new FormData()

      for (const key in this.newTrustee) {
        formData.append(key, this.newTrustee[key])
      }
      const res = await dataApi
        .data()
        .createSystemData('trust', { ...this.newTrustee }, formData, {
          'Content-Type': 'multipart/form-data'
        })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'New trustee created',
        status: true
      })
      this.loading = false
      this.createDialog = false
      this.newTrustee = {
        name: '',
        title: '',
        status: true
      }
      this.getSystemTrust()
    },
    async getSystemTrust() {
      const res = await dataApi.data().getData('admin', { type: 'trust' })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable to get trustees, please check your Internet',
          error: true,
          status: true,
          time: 4500
        })
        setTimeout(() => {
          this.getSystemTrust()
        }, 5000)
        return
      }
      this.trustees = res.data.data.data
    }
  }
}
</script>

<style></style>
