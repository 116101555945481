// Withdrawals
import Withdrawal from '../../views/admin/Withdrawals.vue'
import WDeclined from '../../views/admin/withdrawals/Declined.vue'
import WAccepted from '../../views/admin/withdrawals/Accepted.vue'
import WPending from '../../views/admin/withdrawals/Pending.vue'

const withdrawRoute = {
  path: '/admin/admin/sales',
  component: Withdrawal,
  redirect: '/admin/admin/withdrawals/processed',
  name: 'Withdrawals',
  meta: {
    title: 'Withdrawals'
  },
  children: [
    {
      path: '/admin/admin/withdrawals/declined',
      component: WDeclined,
      name: 'DeclinedWithdrawal',
      meta: {
        title: 'Declined Withdrawal'
      }
    },
    {
      path: '/admin/admin/withdrawals/processed',
      component: WAccepted,
      name: 'AcceptedWithdrawal',
      meta: {
        title: 'Accepted Withdrawal'
      }
    },
    {
      path: '/admin/admin/withdrawals/pending',
      component: WPending,
      name: 'PendingWithdrawal',
      meta: {
        title: 'Pending Withdrawal'
      }
    }
  ]
}

export default withdrawRoute
