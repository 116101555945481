<template>
  <div class="whatsapp">
    <a
      href="https://api.whatsapp.com/send?phone=2348156010101"
      class="pa-2 rounded-sm d-flex justify-center align-center"
    >
      <v-img width="25px" class="mr-2" src="../assets/img/whatsapp.png">
      </v-img>
      <span class="white--text font-weight-bold" for="">Chat</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'WhatsApp',
  data: () => ({
    number: '2348156010101'
  })
}
</script>

<style scoped>
.whatsapp {
  z-index: 100;
  background-color: #64b161;
  bottom: 45px;
  left: 15px;
  position: sticky;
  width: 100px;
  border-radius: 7px;
}
</style>
