<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="700px"
  >
    <v-card class="pa-4">
      <v-card-title>User Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">
              Successful Purchase
            </p>
            <span class="text-h5"
              >{{ totalApprovedPurchase }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Successful Sales</p>
            <span class="text-h5"
              >{{ totalApprovedSales }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">
              Successful Giftcard Sales
            </p>
            <span class="text-h5"
              >{{ totalApprovedGiftcard }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Balance in Wallet</p>
            <span class="text-h5"
              >{{ arrangeUserData.balance }}
              <span class="grey--text subtitle-2">NGN</span></span
            >
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">User Status</p>
            <span
              :class="`${
                arrangeUserData.status ? 'success--text' : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{ arrangeUserData.status ? 'Active' : 'Suspended' }}</span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Registered Date</p>
            <span class="font-weight-medium overline"
              >{{ new Date(arrangeUserData.createdAt).toDateString() }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-2 font-weight-medium grey--text">
              Verification Status
            </p>
            <div class="d-flex">
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.email_verify ? 'success' : 'error'
                }`"
              >
                Email
              </span>
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.bvnVerified ? 'success' : 'error'
                }`"
              >
                BVN
              </span>
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.kycverify ? 'success' : 'error'
                }`"
                >KYC
              </span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">User Info</p>
        <v-row v-for="info in arrangeUserData.info" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
        <!-- <v-divider class="mb-2 mt-4"></v-divider> -->
        <!-- <p class="overline grey--text">More information</p> -->
        <v-row v-for="info in arrangeUserData.moreInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <p class="overline grey--text">Transaction information</p>
      <v-tabs v-model="tabs">
        <v-tab> Sales </v-tab>
        <v-tab> Purchase </v-tab>
        <v-tab> Giftcard </v-tab>
        <!-- <v-tab> Referrals </v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <!-- Sales -->
        <v-tab-item>
          <v-data-table
            :loading="loading"
            :items="transactionSales"
            :headers="headersForTransaction"
          >
            <template v-slot:[`item.rate`]="{ item }">
              <span>{{ item.rate.price }}</span>
              <span class="overline"> {{ item.rate.currency }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.amount.price }}</span>
              <span class="overline"> {{ item.amount.currency }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span>{{ item.price }}</span>
              <span class="overline"> {{ userCurrency }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- Purchase -->
        <v-tab-item>
          <v-data-table
            :loading="loading"
            :items="transactionPurchase"
            :headers="headersForTransaction"
          >
            <template v-slot:[`item.rate`]="{ item }">
              <span>{{ item.rate.price }}</span>
              <span class="overline"> {{ item.rate.currency }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.amount.price }}</span>
              <span class="overline"> {{ item.amount.currency }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span>{{ item.price }}</span>
              <span class="overline"> {{ userCurrency }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- Giftcard -->
        <v-tab-item>
          <v-data-table
            :loading="loading"
            :items="transactionGiftcard"
            :headers="headersForGiftcard"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-img :src="item.image" width="45"></v-img>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.amount }}</span>
              <span class="overline"> {{ userCurrency }} </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          ><v-card flat class="mt-5">
            <v-card-title>User's Referral</v-card-title>
            <v-data-table
              :headers="headers"
              :items="arrangeUserData.referral"
            ></v-data-table> </v-card
        ></v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn text color="accent" @click="$emit('update:open', false)"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { BASEURL } from '../api/const'
import Store from '../store/index'
import { numberWithCommas } from '../utils/helpers'
import { mapState } from 'vuex'
export default {
  name: 'UserDetails',
  props: {
    open: Boolean,
    user: Object
  },
  watch: {
    user(newValue, oldValue) {
      this.transactionPurchase = []
      this.transactionSales = []
      this.transactionGiftcard = []
      this.totalApprovedPurchase = 0
      this.totalApprovedSales = 0
      this.totalApprovedGiftcard = 0
      this.getTransactionForAUser(newValue._id || newValue.id)
    }
  },
  data: () => ({
    tabs: null,
    userTransactions: [],
    loading: false,
    headers: [
      {
        text: 'Username',
        value: 'username',
        align: 'start'
      },
      {
        text: 'Total Transaction',
        value: 'total'
      },
      {
        text: 'Bonus Earning',
        value: 'bonus'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headersForTransaction: [
      {
        text: 'Trx ID.',
        align: 'start',
        sortable: false,
        value: 'trx'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Currency',
        value: 'currency'
      },
      {
        text: 'Amount',
        value: 'amount'
      },

      {
        text: 'Rate',
        value: 'rate'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headersForGiftcard: [
      {
        text: 'Trx ID',
        value: 'trx',
        sortable: false
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Giftcard',
        value: 'image'
      },
      {
        text: 'Name',
        value: 'giftcard.name'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    transactionPurchase: [],
    transactionSales: [],
    transactionGiftcard: [],
    totalApprovedPurchase: 0,
    totalApprovedSales: 0,
    totalApprovedGiftcard: 0
  }),
  methods: {
    async getTransactionForAUser() {
      this.loading = true
      const res = await axios.get(
        `${BASEURL}/transactions?user=${this.arrangeUserData._id}&populate=currency&populate=giftcard&limit=0`,
        {
          headers: {
            ...Store.getters['admin/getRequestHeaders'].headers
          }
        }
      )
      this.userTransactions = res.data.data.data
      // Purchase
      this.transactionPurchase = res.data.data.data
        .filter((transaction) => transaction.type === 'purchase')
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionPurchase.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedPurchase = this.totalApprovedPurchase + e.price
        }
      })

      // Sales
      this.transactionSales = res.data.data.data
        .filter(
          (transaction) =>
            transaction.type === 'sale' && transaction.category !== 'giftcard'
        )
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionSales.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedSales = this.totalApprovedSales + e.price
        }
      })

      this.transactionGiftcard = res.data.data.data
        .filter((t) => t.category === 'giftcard')
        .map((trx) => ({
          ...trx,
          date: new Date(
            trx.created_at ? trx.created_at : trx.createdAt
          ).toDateString(),
          id: trx._id,
          image: trx.giftcard ? trx.giftcard.image : '',
          amount: trx.main_amo
        }))

      this.transactionGiftcard.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedGiftcard = this.totalApprovedGiftcard + e.amount
        }
      })
      this.loading = false
    }
  },
  computed: {
    ...mapState({
      siteInfo: (state) => state.admin.siteInfo,
      admin: (state) => state.admin.admin
    }),
    arrangeUserData() {
      return {
        ...this.user,
        purchase: { total: 0.0 },
        sales: { total: 0.0 },
        giftcard: { total: 0.0 },
        info: [
          {
            title: 'Full Name',
            text: `${this.user.fname} ${this.user.lname}`
          },
          {
            title: 'Username',
            text: `${this.user.username}`
          },
          {
            title: 'Email',
            text: `${this.user.email}`
          },
          {
            title: 'Currency',
            text: `${this.user.country === 'Nigeria' ? 'NGN' : 'GHC'}`
          },
          {
            title: 'Country',
            text: `${this.user.country}`
          },
          {
            title: 'Address',
            text: `${this.user.address || ''}`
          },
          {
            title: 'Date of Birth',
            text: `${this.user.dob || ''}`
          },
          {
            title: 'Phone',
            text: `${this.user.phone || ''}`
          }
        ],
        moreInfo: [
          // {
          //   title: 'Last Login',
          //   text: '16 minutes ago'
          // },
          // {
          //   title: 'Last Login IP',
          //   text: '197.218.8.12'
          // },
          // {
          //   title: 'Last Login Location',
          //   text: 'Africa, Nigeria, Lagos'
          // }
        ]
      }
    },
    userCurrency() {
      return this.user.country === 'Ghana' ? 'GHC' : 'NGN'
    }
  }
}
</script>

<style></style>
