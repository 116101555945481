<template>
  <v-container>
    <h1 class="mb-5">Withdraw Methods</h1>
    <delete-dialog
      :open.sync="deleteDialog"
      :progress.sync="deleteProgress"
      :type="deleteType"
    ></delete-dialog>
    <v-dialog v-model="editDialog" max-width="600">
      <v-form ref="editAccount" @submit.prevent="validateEditAccount">
        <v-card>
          <v-card-title>Edit Method Details</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="selectedMethod.name"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Minimum Withdraw"
                  v-model="selectedMethod.withdraw_min"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Maximum Withdraw"
                  v-model="selectedMethod.withdraw_max"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  suffix="%"
                  label="Percentage on Withdraw"
                  v-model="selectedMethod.percent"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Fix amount on Withdrawal"
                  v-model="selectedMethod.fix"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Activate"
                  :items="['Yes', 'No']"
                  :rules="[rules.required]"
                  v-model="selectedMethod.status"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                :loading="loadingEdit"
                color="accent"
                type="submit"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="createDialog" max-width="600">
      <v-form ref="createAccount" @submit.prevent="validateCreateAccount">
        <v-card>
          <v-card-title>Create Withdraw Method</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  v-model="newMethod.name"
                  filled
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Minimum Withdraw"
                  v-model="newMethod.withdraw_min"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  label="Maximum Withdraw"
                  v-model="newMethod.withdraw_max"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  suffix="%"
                  label="Percentage on Withdraw"
                  v-model="newMethod.percent"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  suffix="NGN"
                  label="Fix amount on Withdrawal"
                  v-model="newMethod.fix"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Activate"
                  :items="[true, 'false']"
                  :rules="[rules.required]"
                  v-model="newMethod.status"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                type="submit"
                :loading="loadingCreate"
                color="accent"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card flat>
      <v-container>
        <v-toolbar flat>
          <v-card-title>Withdrawal Method List</v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="createDialog = true" color="accent" depressed>
            <v-icon left>mdi-plus</v-icon>
            Create New</v-btn
          >
        </v-toolbar>
        <v-card-subtitle
          >Active Withdrawal methods will be available for users to select when
          user want to make withdrawal</v-card-subtitle
        >
        <v-card-text>
          <v-data-table
            :loading="banks.length == 0"
            :items="banks"
            :headers="headers"
          >
            <template v-slot:[`item.date`]="{ item }">
              <small>{{ new Date(item.createdAt).toDateString() }}</small>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="selectBank(item)" ripple>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnBank(item, 'activate')"
                    v-if="!item.status"
                    ripple
                  >
                    <v-list-item-title>Activate</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnBank(item, 'deactivate')"
                    v-if="item.status"
                    ripple
                  >
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <v-list-item ripple @click="deleteBankAccount(item)">
                    <v-list-item-title class="error--text"
                      >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                :class="`text-capitalize d-block text-center rounded py-2 px-3 white--text ${
                  item.status == true ? 'success' : 'error'
                }`"
                >{{ item.status ? 'active' : 'inactive' }}</span
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import rules from '../../../utils/rules'

export default {
  components: { deleteDialog },
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    deleteType: {},
    createDialog: false,
    loadingCreate: false,
    newMethod: {},
    rules: {
      required: rules.required
    },
    deleteProgress: 'starting',
    message: '',
    headers: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'withdraw_min',
        text: 'Min Withdraw'
      },
      {
        value: 'withdraw_max',
        text: 'Max Withdraw'
      },
      {
        value: 'percent',
        text: 'percent'
      },
      {
        value: 'fix',
        text: 'Fixed Amount'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    banks: [],
    selectedMethod: {},
    loadingEdit: false
  }),
  created() {
    this.getBankAccounts()
  },
  watch: {
    deleteProgress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getBankAccounts()
        this.deleteProgress = 'starting'
      }
    }
  },
  methods: {
    validateCreateAccount() {
      if (this.$refs.createAccount.validate()) this.createBankAccount()
    },
    validateEditAccount() {
      if (this.$refs.editAccount.validate()) this.editBankAccount()
    },
    selectBank(method) {
      this.selectedMethod = method
      this.editDialog = true
    },
    deleteBankAccount(method) {
      this.deleteType = {
        type: 'withdrawmethod',
        data: method
      }
      this.deleteDialog = true
    },
    async createBankAccount() {
      this.loadingCreate = true
      const res = await dataApi
        .data()
        .createSystemData('withdrawmethod', this.newMethod)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true,
          time: 4500
        })

        return
      }

      this.getBankAccounts()
      this.$store.dispatch('alert', {
        message: 'Bank Details created successfully',
        error: false,
        status: true
      })
      this.loadingCreate = false
      this.createDialog = false
    },
    async editBankAccount() {
      this.loadingEdit = true
      const res = await dataApi.data().editSystemData(
        { type: 'withdrawmethod', typeId: this.selectedMethod._id },
        {
          ...this.selectedMethod,
          status: this.selectedMethod.status === 'Yes'
        }
      )

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true,
          time: 4500
        })
        this.loadingEdit = false
        return
      }

      this.getBankAccounts()
      this.$store.dispatch('alert', {
        message: 'Method update successful',
        error: false,
        status: true
      })
      this.loadingEdit = false
      this.editDialog = false
    },
    async actionOnBank(bank, action) {
      const res = await dataApi
        .data()
        .editSystemData(
          { type: 'withdrawmethod', typeId: bank._id },
          { ...bank, status: action === 'activate' }
        )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: `Method updated`,
        status: true,
        error: false
      })
      this.getBankAccounts()
    },
    async getBankAccounts() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'withdrawmethod' })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable fetch methods, please check your Internet',
          error: true,
          status: true,
          time: 4500
        })
        setTimeout(() => {
          this.getBankAccounts()
        }, 5000)
        return
      }

      this.banks = res.data.data.data
    }
  }
}
</script>

<style></style>
