import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/bonus-conversions`

export default {
  data() {
    return {
      getBonusConversions: async ({ ...params }) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getBonusConversion: async (bonusId) => {
        const endpoint = `${URL}/${bonusId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateBonusConversion: async ({ conversionId, status, remarks }) => {
        const endpoint = `${URL}/${conversionId}`
        const data = { status, remarks }
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
