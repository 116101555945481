<template>
  <v-dialog
    @click:outside="onClose"
    v-model="open"
    max-width="700px"
  >
    <v-dialog max-width="600" v-model="reasonDialog">
      <v-card>
        <v-card-title> Reason for Decline </v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <vue-upload-multiple-image
                class="insi"
                @upload-success="uploadImageSuccess"
                dropText="Remove"
                markIsPrimaryText="Others"
                primaryText="Main image"
                dragText="Drag your image"
                browseText="Pick a image"
                popupText="Edit Image"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
              ></vue-upload-multiple-image>
            </v-col>
            <v-col cols="12">
              <v-textarea
                filled
                label="Reason for Decline"
                v-model="reason.reason"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="error"
            @click="actionOnTransaction('declined')"
            >Confirm Decline</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="confirmApprove">
      <v-card>
        <v-card-title>Confirm approval</v-card-title>
        <v-card-text
          >You are about to approve this transaction, confirm?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            @click="confirmApprove = false"
            color="error"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            @click="actionOnTransaction('approved')"
            color="success"
          >
            Confirm Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pa-4">
      <v-card-title>Transaction Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Transaction Date</p>
            <small>{{ transaction.date }}</small>
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Transaction Status</p>
            <span
              :class="`${
                transaction.status == 'approved'
                  ? 'success--text'
                  : transaction.status === 'pending'
                  ? 'accent--text'
                  : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{ transaction.status }}</span
            >
          </v-col>
          <v-col cols="12" md="4">
            <p class="mb-0 font-weight-medium">Transaction Time</p>
            <small>{{ transaction.time }}</small>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">Transaction Info</p>
        <v-row v-for="info in transaction.transactionInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text-capitalize text--darken-1">{{
              info.title
            }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span
              class="grey--text text-capitalize text--darken-3 font-weight-medium"
              >{{ info.text }}</span
            >
          </v-col>
        </v-row>
        <v-divider
          v-if="transaction.category === 'giftcard'"
          class="mb-2 mt-4"
        ></v-divider>
        <p
          v-if="transaction.category === 'giftcard'"
          class="overline mb-2 grey--text"
        >
          Transaction Cart
        </p>
        <v-row v-if="transaction.category === 'giftcard'">
          <v-col v-for="(n, i) in transaction.cart" :key="i" cols="4">
            <v-card rounded elevation="1" class="pa-1">
              <p class="mb-0">
                <span class="grey--text">Value</span>
                {{ transaction.currency ? transaction.currency.symbol : ''
                }}{{ numberWithCommas(n.variationAmount) }}
              </p>
              <p class="mb-0">
                <span class="grey--text">Quantity</span>
                {{ numberWithCommas(n.quantity) }}
              </p>
              <p class="mb-0">
                <span class="grey--text">Rate</span>
                {{ transaction.currencyCode }}
                {{ nairaRateBasedOnCountry(n.nairaRate) }}
              </p>
              <p class="mb-0">
                <span class="grey--text">Price</span>
                {{ transaction.currencyCode }}
                {{
                  numberWithCommas(
                    nairaRateBasedOnCountry(Number(n.nairaRate)) *
                      Number(n.quantity) *
                      Number(n.variationAmount)
                  )
                }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">Transaction Details</p>
        <v-row v-for="info in transaction.transactionDetails" :key="info.title">
          <template v-if="info.title === 'Image for Decline'">
            <v-col cols="5" md="5">
              <span class="grey--text text--darken-1">{{ info.title }}</span>
            </v-col>
            <v-col cols="7" md="7">
              <template v-if="info.text === 'No image uploaded'">
                <span>No image uploaded</span>
              </template>
              <template v-else>
                <a :href="info.text" target="_blank">
                  <img
                    :src="info.text"
                    style="width: 100%"
                    class="grey--text text--darken-3 font-weight-medium"
                  />
                </a>
              </template>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="5" md="5">
              <span class="grey--text text--darken-1">{{ info.title }}</span>
            </v-col>
            <v-col cols="7" md="7">
              <span class="grey--text text--darken-3 font-weight-medium">{{
                info.text
              }}</span>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">Image</span>
          </v-col>
          <v-col cols="7" md="7">
            <div
              v-if="
                transaction.images &&
                Array.isArray(transaction.images) &&
                transaction.images.length > 0
              "
            >
              <v-col>
                <v-row>
                  <v-col
                    cols="4"
                    v-for="(img, i) in transaction.images"
                    :key="i"
                  >
                    <img
                      :src="img"
                      height="150"
                      width="340"
                      alt="image"
                      style="max-width: 100%; width: 100%"
                    />
                    <router-link
                      target="_blank"
                      :to="`/view-image?imageLink=${img}`"
                      class="v-btn pa-2 mt-4"
                      >view</router-link
                    >
                  </v-col>
                </v-row>
              </v-col>
            </div>
            <div v-else>
              <img
                v-if="transaction.image !== null"
                :src="transaction.image"
                height="100"
                width="340"
                style="max-width: 100%"
                alt="image"
              />
              <router-link
                v-if="transaction.image !== null"
                class="v-btn pa-2 mt-4"
                target="_blank"
                :to="`/view-image?imageLink=${transaction.image}`"
                >View</router-link
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <div
          class="d-flex"
          v-if="
            (checkIfPermission('manage giftcard', admin.permissions) &&
              type === 'giftcard') ||
            (checkIfPermission('manage sales', admin.permissions) &&
              type === 'sales') ||
            (checkIfPermission('manage purchase', admin.permissions) &&
              type === 'purchase')
          "
        >
          <v-btn
            text
            :loading="loading"
            @click.stop="reasonDialog = true"
            color="error"
            v-if="transaction.status === 'pending'"
            >Decline</v-btn
          >
          <v-btn
            text
            :loading="loading"
            @click.stop="confirmApprove = true"
            color="success"
            v-if="transaction.status === 'pending'"
            >Approve</v-btn
          >
        </div>

        <v-btn text :disabled="loading" color="accent" @click="onClose"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import transactionApi from '../api/admin/transactions'
import { numberWithCommas } from '../utils/helpers'
import VueUploadMultipleImage from 'vue-upload-multiple-image'

export default {
  name: 'TransactionDetails',
  data() {
    return {
      numberWithCommas,
      loading: false,
      reason: {
        image: []
      },
      images: [],
      reasonDialog: false,
      confirmApprove: false
    }
  },
  props: {
    open: Boolean,
    transaction: Object,
    progress: String,
    type: String
  },
  components: {
    VueUploadMultipleImage
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin,
      countrySetting: (state) => state.admin.countrySetting
    })
  },
  methods: {
    onClose() {
      this.reason = {
        image: [],
        reason: ''
      }
      this.images = []
      this.$emit('update:open', false)
    },
    nairaRateBasedOnCountry(nairaRate) {
      return Number(
        nairaRate /
          this.countrySetting[this.transaction.country.toLowerCase()].nairaRate
      )
    },
    forceFileDownload(response) {
      const url = response

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `banner${response}.jpeg`) // or any other extension
      link.setAttribute('download', `banner${response}.jpeg`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    async actionOnTransaction(action) {
      this.loading = true
      const res = await transactionApi.transactions().updateTransaction({
        trxId: this.transaction._id,
        status: action,
        details: action === 'declined' ? this.reason.reason : '',
        image: action === 'declined' ? this.reason.image : []
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.loading = false
        return
      }
      this.reason = {
        image: []
      }
      this.images = []
      this.$store.dispatch('alert', {
        message: `${action} successfully`,
        status: true
      })
      this.reasonDialog = false
      this.$emit('update:progress', 'done')
      this.$emit('update:open', false)
      this.loading = false
    },
    uploadImageSuccess(formData, index, fileList) {
      this.reason.image.push(formData.getAll('file')[0])
      this.images = fileList
    },
    beforeRemove(index, done, fileList) {
      var r = confirm('remove image')
      if (r === true) {
        var imgIndex = this.reason.image.indexOf(fileList[index].name)
        this.reason.image.splice(imgIndex)
        done()
      }
    },
    editImage(formData, index, fileList) {
      // console.log('edit data', formData, index, fileList)
    }
  }
}
</script>

<style>
.insi .image-container {
  width: 100% !important;
}
</style>
