import axios from 'axios'
import Store from '../../store/index'

import {
  errorHandler,
  successHandler,
  generateNoOptionsQuery
} from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/users`

export default {
  users() {
    return {
      /**
       * Fetch all users depending on status
       *
       * @param {String} status User status
       */
      getAll: async (
        activeStatus,
        { order = 'desc', limit = 0, offset = 0, keyword = '', ...params }
      ) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params: {
              email_verify: true,
              limit,
              order,
              offset,
              keyword: keyword || undefined,
              activeStatus: activeStatus === 'all' ? undefined : activeStatus,
              ...params
            },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getSingle: async ({ userId = '', params = {} }) => {
        const endpoint = `${URL}/${userId}?populate=referrals`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getReferrals: async ({ ...params }) => {
        const endpoint = `${URL}/referrals`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateStatus: async (userId, status) => {
        const endpoint = `${URL}/${userId}/status`
        try {
          const res = await axios.post(
            endpoint,
            { status: status },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getTestimonials: async (limit = 0) => {
        const endpoint = `${URL}/testimonials/fetch${generateNoOptionsQuery([
          'user'
        ])}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateTestimonialStatus: async (testimonialID, { status }) => {
        const endpoint = `${URL}/testimonials/${testimonialID}`
        try {
          const res = await axios.put(
            endpoint,
            { status },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendMessageToUser: async ({ userId, details, image }) => {
        const endpoint = `${URL}/messages/new`
        const formData = new FormData()
        formData.append('userId', userId)
        formData.append('details', details)
        formData.append('image', image)
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getMessages: async (type, { admin = false }) => {
        const endpoint = `${URL}/messages/${type}${generateNoOptionsQuery([
          'user'
        ])}&admin=${admin}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      markMessageRead: async ({ messageId }) => {
        const endpoint = `${URL}/messages/${messageId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      kycRequest: async (limit, offset) => {
        const endpoint = `${URL}/fetch/verifications${generateNoOptionsQuery(
          ['user', 'actionby'],
          limit
        )}&offset=${offset || 0}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyKycRequest: async (kycId, { status, reason }) => {
        const endpoint = `${URL}/verifications/${kycId}`
        const data = { status, reason }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      listSubscribers: async () => {
        const endpoint = `${URL}/subscribers${generateNoOptionsQuery([])}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateSubscriber: async ({ id, status }) => {
        const endpoint = `${URL}/subscribers/${id}`
        try {
          const res = await axios.put(
            endpoint,
            { status },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendEmailToSubscribers: async ({ message, subject }) => {
        const endpoint = `${URL}/mail/subscribers`
        try {
          const res = await axios.post(
            endpoint,
            { message, subject },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendEmailToUsers: async ({ message, subject }) => {
        const endpoint = `${URL}/mail/users`
        try {
          const res = await axios.post(
            endpoint,
            { message, subject },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      activateAccount: async ({ userId }) => {
        const endpoint = `${URL}/${userId}/reactivate`
        try {
          const res = await axios.post(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deactivateAccount: async ({ userId }) => {
        const endpoint = `${URL}/${userId}/deactivate`
        try {
          const res = await axios.post(
            endpoint,
            {},
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      generateAccountStatement: async ({ userId, params }) => {
        const endpoint = `${URL}/${userId}/account-statement`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      exportData: async (country) => {
        const endpoint = `${URL}/export`
        try {
          const res = await axios.get(endpoint, {
            params: {
              country
            },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      userCryptoStats: async (filters = {}) => {
        const endpoint = `${BASEURL}/cryptocoin/user-stats`
        try {
          const res = await axios.get(endpoint, {
            params: filters,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
