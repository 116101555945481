<template>
  <v-container>
    <h1 class="mb-5">Approved Withdrawals</h1>
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <v-row>
      <v-col>
        <v-card flat color="white">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="arrangedTransaction"
            :loading="loadingTransaction"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 25, 50]
            }"
            @update:items-per-page="updateItemPerPage"
            @update:page="updatePage"
            :server-items-length="apiData.total"
          >
            <template v-slot:[`item.user`]="{ item }">
              <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.user }}</router-link
              > -->
              <span
                class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                @click="openMinDialog(item.user_id)"
              >
                {{ item.user }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <small class="font-weight-medium">{{ item.date }}</small>
            </template>
            <template v-slot:[`item.netAmount`]="{ item }">
              <span
                >{{ item.netAmount }}
                <small class="grey--text text--darken-2">{{
                  item.currencyCode
                }}</small></span
              >
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>
                {{ item.amount }}
                <small class="grey--text text--darken-2">{{
                  item.currencyCode
                }}</small>
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small depressed @click.stop="selectTransaction(item)"
                >View Details</v-btn
              >
            </template>
          </v-data-table>
          <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
          <withdraw-details
            :open.sync="transactionOpen"
            :transaction="transaction"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import DeleteDialog from '../../../components/deleteDialog.vue'
import withdrawDetails from '../../../components/withdrawDetails.vue'
import withdrawalApi from '../../../api/admin/withdrawals'
import { numberWithCommas } from '../../../utils/helpers'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import _ from 'lodash'

export default {
  components: {
    withdrawDetails,
    UserDetailsMin
    //  DeleteDialog
  },
  data: () => ({
    openMin: false,
    minUserId: '',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Username',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Net Amount',
        value: 'netAmount'
      },
      {
        text: 'Price',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'action',
        sortable: false
      }
    ],
    transactions: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1
  }),
  created() {
    this.getTransactions(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    arrangedTransaction() {
      if (this.transactions.length !== 0) {
        return this.transactions.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          date: new Date(
            transaction.created_at
              ? transaction.created_at
              : transaction.createdAt
          ).toDateString(),
          user: transaction.user.username,
          user_id: transaction.user._id,
          status: transaction.status,
          netAmount: numberWithCommas(transaction.net_amount),
          amount: numberWithCommas(transaction.amount)
        }))
      }
      return []
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await withdrawalApi.logs().getAll({
        status: 'approved',
        populate: ['user', 'method', 'wallet', 'bank', 'actionby'],
        limit: this.perPage,
        keyword: this.search
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await withdrawalApi.logs().getAll({
        status: 'approved',
        populate: ['user', 'method', 'wallet', 'bank', 'actionby'],
        limit: this.perPage,
        offset: length
      })

      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    selectTransaction(transx) {
      const transaction = this.transactions.find((trx) => trx._id === transx.id)

      this.transaction = {
        ...transaction,
        date: new Date(
          transaction.created_at
            ? transaction.created_at
            : transaction.createdAt
        ).toDateString(),
        time: new Date(
          transaction.created_at
            ? transaction.created_at
            : transaction.createdAt
        ).toTimeString(),
        status: transaction.status,
        email: transaction.user.email,
        username: transaction.user.username,
        transactionInfo: [
          {
            title: 'Transaction Type',
            text: `Withdrawal`
          },
          {
            title: 'Withdrawal Method',
            text: transaction.method ? transaction.method.name : ''
          },
          {
            title: 'Amount',
            text: `${transaction.amount} ${transaction.currencyCode}`
          },
          {
            title: 'Withdrawal Charge',
            text: `${numberWithCommas(transaction.charge)} ${
              transaction.currencyCode
            }`
          },
          {
            title: 'Total Amount',
            text: `${numberWithCommas(transaction.net_amount)} ${
              transaction.currencyCode
            }`
          },
          {
            title: 'TRX ID',
            text: `${transaction.transaction_id}`
          }
        ],
        transactionDetails: [
          {
            title: 'Payment Methods',
            text: transaction.method ? transaction.method.name : ''
          },
          {
            title: 'Bank Name',
            text: transaction.bank ? transaction.bank.bank_name : ''
          },
          {
            title: 'Account Name',
            text: transaction.bank ? transaction.bank.account_name : ''
          },
          {
            title: 'Account Number',
            text: transaction.bank ? transaction.bank.account_number : ''
          },
          {
            title: 'Amount To Pay',
            text: `${transaction.amount} ${transaction.currencyCode}`
          },
          {
            title: 'Action By',
            text: `${
              transaction.actionby ? transaction.actionby.name : 'admin'
            }`
          },
          {
            title: 'Accepted At',
            text: transaction.actionedAt
              ? new Date(transaction.actionedAt)
              : 'No approved date/time provided'
          }
        ]
      }
      this.transactionOpen = true
    }
  }
}
</script>

<style></style>
