<template>
  <v-container>
    <h1 class="mb-5">Approved Giftcard Sale</h1>
    <user-details-min :userId="minUserId" :open.sync="openMin" />
    <v-row>
      <v-col>
        <v-card flat color="white">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="arrangedTransaction"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 25, 50]
            }"
            @update:items-per-page="updateItemPerPage"
            @update:page="updatePage"
            :server-items-length="apiData.total"
            :loading="loadingTransaction"
            :items-per-page="perPage"
          >
            <template v-slot:no-data>
              <div class="py-3 text-center">
                <v-img
                  src="@/assets/img/undraw_empty.svg"
                  width="325"
                  class="mx-auto"
                  alt="empty"
                />
                <h2 class="mt-3">No pending Purchase</h2>
                <p>You are done here</p>
              </div>
            </template>
            <template v-slot:[`item.seller`]="{ item }">
              <!-- <router-link
                :to="{ name: 'ViewUser', params: { id: item.seller_id } }"
                class="font-weight-medium text-capitalize d-block"
                >{{ item.seller }}</router-link
              > -->
              <span
                class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                @click="openMinDialog(item.seller_id)"
              >
                {{ item.seller }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-card
                :color="
                  item.status == 'approved'
                    ? 'success'
                    : item.status == 'pending'
                    ? 'warning'
                    : 'error'
                "
                flat
                rounded="md"
                outlined
                class="text-capitalize text-center white--text pa-1"
              >
                {{ item.status }}
              </v-card>
            </template>
            <template v-slot:[`item.giftcard`]="{ item }">
              <v-img
                width="45"
                class="ma-1"
                :src="item.giftcard ? item.giftcard.image : ''"
              />
              <span class="font-weight-medium">{{
                item.giftcard ? item.giftcard.name : ''
              }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <small class="font-weight-medium">{{ item.date }}</small>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span
                >{{
                  item.promocodeAmountEarned
                    ? Number(item.promocodeAmountEarned) + Number(item.main_amo)
                    : item.price
                }}
                <small class="grey--text text--darken-2">{{
                  item.currencyCode
                }}</small></span
              >
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>
                <span class="grey--text text--darken-2">$</span>
                {{ item.amount }}</span
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn small depressed @click="selectTransaction(item)"
                >View Details</v-btn
              >
            </template>
          </v-data-table>
          <!-- <delete-dialog :open.sync="dialogDelete" type="purchase" /> -->
          <transaction-details
            :progress.sync="progress"
            :open.sync="transactionOpen"
            :transaction="transaction"
            type="giftcard"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import deleteDialog from '../../../components/deleteDialog.vue'
import TransactionDetails from '../../../components/transactionDetails.vue'
import transactionApi from '../../../api/admin/transactions'
import { numberWithCommas } from '../../../utils/helpers'
import dataApi from '../../../api/systemData'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import _ from 'lodash'

export default {
  components: {
    // deleteDialog,
    TransactionDetails,
    UserDetailsMin
  },
  data: () => ({
    openMin: false,
    minUserId: '',
    search: '',
    loading: false,
    dialogDelete: false,
    transactionOpen: false,
    countries: [],
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'date'
      },
      {
        text: 'Seller',
        sortable: false,
        value: 'seller'
      },
      {
        text: 'Giftcard',
        value: 'giftcard',
        sortable: false
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false
      }
    ],
    transactions: [],
    selectedTransaction: {},
    transaction: {},
    progress: 'starting',
    transactionsM: [],
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1
  }),
  created() {
    this.getTransactions(0)
    this.getCounties()
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') this.getTransactions()
      this.progress = 'starting'
    },
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    arrangedTransaction() {
      if (this.transactionsM.length !== []) {
        return this.transactionsM.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          date: transaction?.date_string,
          seller: transaction?.user?.username,
          seller_id: transaction?.user?._id,
          status: transaction?.status,
          giftcard: transaction?.giftcard,
          price: numberWithCommas(transaction.main_amo)
        }))
      }
      return []
    }
  },
  methods: {
    async searchData() {
      this.loadingTransaction = true
      this.transactionsM = []
      const res = await transactionApi.transactions().getAll({
        type: 'sale',
        category: 'giftcard',
        status: 'approved',
        populate: ['user', 'currency', 'giftcard', 'actionby'],
        limit: this.perPage,
        keyword: this.search
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.transactionsM = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async getCounties() {
      const res = await dataApi.data().getData('admin', { type: 'country' })
      this.countries = res.data.data.data
        .filter((country) => country.name)
        .reverse()
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await transactionApi.transactions().getAll({
        type: 'sale',
        category: 'giftcard',
        status: 'approved',
        populate: ['user', 'currency', 'giftcard', 'actionby', 'promocode'],
        limit: this.perPage,
        offset: length
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.transactionsM = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    getCardTypeOptionData(cardTypeId, cardCardTypes) {
      for (let i = 0; i < cardCardTypes.length; i++) {
        const type = cardCardTypes[i]
        for (let x = 0; x < type.options.length; x++) {
          const option = type.options[x]
          if (option._id === cardTypeId) return cardCardTypes[i]
        }
      }
    },
    selectTransaction(transx) {
      const transaction = this.transactions.find((trx) => trx._id === transx.id)
      const cart = transaction.cart.cartDetails.filter(
        (trx) => trx.quantity > 0
      )

      const cardTypeId = cart[0]._id
      const cardCurrencyId = transaction.cart.cardOption.currency
      const cardCountryId = transaction.cart.cardOption.country
      const cardCardTypes = transaction.cart.cardOption.cardTypes

      const options = this.getCardTypeOptionData(cardTypeId, cardCardTypes)
      let cardTypeFresh = ''
      if (options === undefined) {
        cardTypeFresh = cart[0].cardType
      }
      const currencyData = transaction.currency
      const countryData = this.countries.find((c) => c._id === cardCountryId)

      this.transaction = {
        ...transaction,
        options,
        cart,
        cardCurrencyId,
        countryData,
        currencyData,
        cardCountryId,
        date: transaction.date_string,
        time: transaction.time_string,
        status: transaction.status,
        transactionInfo: [
          {
            title: 'Transaction Type',
            text: `${transaction.type}`
          },
          {
            title: 'Giftcard',
            text: `${transaction.giftcard ? transaction.giftcard.name : ''}`
          },
          {
            title: 'Amount',
            text: ` ${numberWithCommas(transaction.main_amo)} ${
              transaction.currencyCode
            }`
          },
          {
            title: 'Bonus from Promo Code',
            text: `${
              transaction.promocodeAmountEarned
                ? transaction.promocodeAmountEarned
                : 'No promo code used'
            }`
          },
          {
            title: 'Total',
            text: ` ${
              transaction.promocodeAmountEarned
                ? numberWithCommas(
                    transaction.main_amo +
                      Number(transaction.promocodeAmountEarned)
                  )
                : numberWithCommas(transaction.main_amo)
            } ${transaction.currencyCode}`
          },
          {
            title: 'Promo Code Used',
            text: `${
              transaction.promocodeAmountEarned
                ? transaction.promocode.code
                : 'No promo code used'
            }`
          },
          {
            title: 'Transaction ID',
            text: `${transaction.trx}`
          },
          {
            title: 'Details',
            text: `${transaction.type} ${transaction.category}`
          },
          {
            title: 'Currency',
            text: `${transaction.currency ? transaction.currency.name : ''}`
          },
          {
            title: 'Country',
            text: `${countryData.name ?? ''}`
          },
          {
            title: 'Card Type',
            text: `${
              options !== undefined
                ? options.cardType
                : cardTypeFresh !== undefined
                ? cardTypeFresh.cardType
                : ''
            }`
          }
        ],
        transactionDetails: [
          {
            title: `Giftcard Type`,
            text: `${transaction.giftcard ? transaction.giftcard.name : ''}`
          },
          {
            title: `Sold By`,
            text: `${transaction?.user?.username}`
          },
          {
            title: `Action By`,
            text: `${
              transaction.actionby ? transaction.actionby.name : 'admin'
            }`
          },
          {
            title: 'Approved At',
            text: transaction.actionedAt
              ? new Date(transaction.actionedAt)
              : 'No approved date/time provided'
          }
        ]
      }
      this.transactionOpen = true
    }
    // async getATransaction(id) {
    //   const res = await transactionApi.transactions().getSingle(id)
    //   console.log(res)
    // }
  }
}
</script>

<style></style>
