<template>
  <v-container>
    <h1 class="mb-5">Bank Account Settings</h1>
    <delete-dialog
      :open.sync="deleteDialog"
      :progress.sync="deleteProgress"
      :type="deleteType"
    ></delete-dialog>
    <v-dialog v-model="editDialog" max-width="600">
      <v-form ref="editAccount" @submit.prevent="validateEditAccount">
        <v-card>
          <v-card-title>Edit Bank Details</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Bank Name"
                  v-model="selectedBank.name"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Account Name"
                  v-model="selectedBank.accname"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Account Number"
                  v-model="selectedBank.account"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Activate"
                  :items="[true, 'false']"
                  :rules="[rules.required]"
                  v-model="selectedBank.status"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                :loading="loadingEdit"
                color="accent"
                type="submit"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="createDialog" max-width="600">
      <v-form ref="createAccount" @submit.prevent="validateCreateAccount">
        <v-card>
          <v-card-title>Create Bank Details</v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Bank Name"
                  v-model="newBank.name"
                  filled
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Account Name"
                  v-model="newBank.accname"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Account Number"
                  v-model="newBank.account"
                  :rules="[rules.required]"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Activate"
                  :items="[true, 'false']"
                  :rules="[rules.required]"
                  v-model="newBank.status"
                  filled
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer> </v-spacer>
              <v-btn
                depressed
                type="submit"
                :loading="loadingCreate"
                color="accent"
                >Save Changes</v-btn
              >
            </v-card-actions>
          </v-container>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card flat>
      <v-container>
        <v-toolbar flat>
          <v-card-title>Bank Account List</v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click.stop="createDialog = true" color="accent" depressed>
            <v-icon left>mdi-plus</v-icon>
            Create New</v-btn
          >
        </v-toolbar>
        <v-card-subtitle
          >Active back accounts will show up when users whats to make
          payment</v-card-subtitle
        >
        <v-card-text>
          <v-data-table
            :loading="banks.length == 0"
            :items="banks"
            :headers="headers"
          >
            <template v-slot:[`item.date`]="{ item }">
              <small>{{ new Date(item.createdAt).toDateString() }}</small>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="selectBank(item)" ripple>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnBank(item, 'activate')"
                    v-if="!item.status"
                    ripple
                  >
                    <v-list-item-title>Activate</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnBank(item, 'deactivate')"
                    v-if="item.status"
                    ripple
                  >
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <v-list-item ripple @click="deleteBankAccount(item)">
                    <v-list-item-title class="error--text"
                      >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                :class="`text-capitalize d-block text-center rounded py-2 px-3 white--text ${
                  item.status == true ? 'success' : 'error'
                }`"
                >{{ item.status ? 'active' : 'inactive' }}</span
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import rules from '../../../utils/rules'

export default {
  components: { deleteDialog },
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    deleteType: {},
    createDialog: false,
    loadingCreate: false,
    newBank: {},
    rules: {
      required: rules.required
    },
    deleteProgress: 'starting',
    message: '',
    headers: [
      {
        align: 'start',
        value: 'date',
        text: 'Date',
        sortable: false
      },
      {
        value: 'name',
        text: 'Bank Name'
      },
      {
        value: 'accname',
        text: 'Account Name'
      },
      {
        value: 'account',
        text: 'Account Number'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    banks: [],
    selectedBank: {},
    loadingEdit: false
  }),
  created() {
    this.getBankAccounts()
  },
  watch: {
    deleteProgress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getBankAccounts()
        this.deleteProgress = 'starting'
      }
    }
  },
  methods: {
    validateCreateAccount() {
      if (this.$refs.createAccount.validate()) this.createBankAccount()
    },
    validateEditAccount() {
      if (this.$refs.editAccount.validate()) this.editBankAccount()
    },
    selectBank(bank) {
      this.selectedBank = bank
      this.editDialog = true
    },
    deleteBankAccount(bank) {
      this.deleteType = {
        type: 'bank',
        data: bank
      }
      this.deleteDialog = true
    },
    async createBankAccount() {
      this.loadingCreate = true
      const res = await dataApi.data().createSystemData('bank', this.newBank)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true,
          time: 4500
        })

        return
      }

      this.getBankAccounts()
      this.$store.dispatch('alert', {
        message: 'Bank Details created successfully',
        error: false,
        status: true
      })
      this.loadingCreate = false
      this.createDialog = false
    },
    async editBankAccount() {
      this.loadingEdit = true
      const res = await dataApi
        .data()
        .editSystemData(
          { type: 'bank', typeId: this.selectedBank._id },
          this.selectedBank
        )

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true,
          time: 4500
        })

        return
      }

      this.getBankAccounts()
      this.$store.dispatch('alert', {
        message: 'Bank Details updates successful',
        error: false,
        status: true
      })
      this.loadingEdit = false
      this.editDialog = false
    },
    async actionOnBank(bank, action) {
      const res = await dataApi
        .data()
        .editSystemData(
          { type: 'bank', typeId: bank._id },
          { ...bank, status: action === 'activate' }
        )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: `Bank Account ${action}`,
        status: true,
        error: false
      })
      this.getBankAccounts()
    },
    async getBankAccounts() {
      const res = await dataApi.data().getData('admin', { type: 'bank' })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable to bank accounts, please check your Internet',
          error: true,
          status: true,
          time: 4500
        })
        setTimeout(() => {
          this.getBankAccounts()
        }, 5000)
        return
      }

      this.banks = res.data.data.data
    }
  }
}
</script>

<style></style>
