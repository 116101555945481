<template>
  <v-container class="pb-10">
    <div
      v-if="!checkIfPermission('view user', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <div class="mb-5 flex items-center justify-between">
        <div>
          <h1>Users Statistics</h1>
          <p>
            Filter users by options, pick an option and click on the filter
            button
          </p>
        </div>
      </div>
      <v-row>
        <v-col class="" cols="12">
          <user-crypto-stats-table />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import UserCryptoStatsTable from '../../../components/admin/userCryptoStatsTable.vue'
import { mapActions, mapState } from 'vuex'
import userManagement from '../../../api/admin/userManagement'

export default {
  components: { UserCryptoStatsTable },
  name: 'Users',

  data: () => ({
    transactionTab: null,
    userDetailsDialog: false,
    active: [],
    inactive: [],
    loadingActive: false,
    loadingInactive: false,
    selectedCountry: 'Nigeria',
    exportingData: false
  }),
  created() {
    // this.getActiveUser()
    // this.getInactiveUser()
    // this.getUsersAll()
  },
  methods: {
    ...mapActions({
      alert: 'alert'
    }),
    async exportData() {
      this.exportingData = true

      const res = await userManagement.users().exportData(this.selectedCountry)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.exportingData = false
        return
      }
      this.$store.dispatch('alert', {
        message: res.data.data,
        status: true
      })
      this.exportingData = false
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  }
}
</script>

<style></style>
