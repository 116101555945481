<template>
  <v-container>
    <h1 class="mb-5">Payment Gateways</h1>
    <!-- <delete-dialog :open.sync="deleteDialog" type="bankAccount"></delete-dialog> -->
    <!-- <v-dialog v-model="editDialog" max-width="600">
      <v-card>
        <v-card-title>Edit Bank Details</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Bank Name" filled></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Account Name" filled></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Account Number" filled></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn depressed @click="editDialog = false" color="accent"
              >Save Changes</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog> -->
    <v-card flat>
      <v-container>
        <v-toolbar flat>
          <v-card-title>Bank Account Lists</v-card-title>
          <v-spacer></v-spacer>
          <!-- <v-btn color="accent" depressed>
            <v-icon left>mdi-plus</v-icon>
            Create New</v-btn
          > -->
        </v-toolbar>
        <v-card-text>
          <v-data-table :items="methods" :headers="headers">
            <template v-slot:[`item.date`]="{ item }">
              <small>{{ new Date(item.createdAt).toDateString() }}</small>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click.stop="editDialog = true" ripple>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item
                    @click="actionOnCurrency(item, 'activate')"
                    v-if="item.status === false"
                    ripple
                  >
                    <v-list-item-title>Activate</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnCurrency(item, 'deactivate')"
                    v-if="item.status === true"
                    ripple
                  >
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item ripple @click.stop="deleteDialog = true">
                    <v-list-item-title class="error--text"
                      >Delete</v-list-item-title
                    >
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                :class="`text-capitalize rounded py-2 px-3 white--text ${
                  item.status == true ? 'success' : 'error'
                }`"
                >{{ item.status }}</span
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
// import deleteDialog from '../../../components/deleteDialog.vue'
import dataApi from '../../../api/systemData'
import { mapState } from 'vuex'
// import axios from 'axios'

export default {
  // components: { deleteDialog },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    headers: [
      {
        align: 'start',
        value: 'date',
        text: 'Date'
      },
      {
        value: 'name',
        text: 'Method Name'
      },
      {
        value: 'status',
        text: 'Status'
      },
      {
        value: 'action',
        text: '',
        sortable: false
      }
    ],
    methods: []
  }),
  created() {
    this.getPayments()
  },
  methods: {
    async getPayments() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'paymentmethod' })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: 'Unable to get currency list, please check your Internet',
          error: true,
          status: true,
          time: 4500
        })

        return
      }
      this.methods = res.data.data.data
    },
    async actionOnCurrency(payment, action) {
      const res = await dataApi
        .data()
        .editSystemData(
          { type: 'paymentmethod', typeId: payment._id },
          { ...payment, status: action === 'activate' }
        )
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: `Payment Method ${action}`,
        status: true,
        error: false
      })
      this.getPayments()
    }
  }
}
</script>

<style></style>
