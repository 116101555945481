<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="700px"
  >
    <v-dialog max-width="500" v-model="declineDialog">
      <v-card>
        <v-container>
          <v-card-title>Decline</v-card-title>
          <v-card-subtitle
            >Decline {{ kyc.username }} documents verification
          </v-card-subtitle>
          <v-card-text>
            <v-textarea
              filled
              label="Reason"
              hint="Enter a reason for declining this document, Input reason will be sent to user"
              persistent-hint
              v-model="reason"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              :loading="loading"
              depressed
              @click="actionOnKYC('declined')"
              >Confirm Decline</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="confirmApprove">
      <v-card>
        <v-card-title>Confirm approval</v-card-title>
        <v-card-text
          >You are about to approve this kyc request, confirm?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            @click="confirmApprove = false"
            color="error"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            @click="actionOnKYC('approved')"
            color="success"
          >
            Confirm Approve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pa-4">
      <v-card-title>KYC Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Submitted By</p>
            <small>{{ kyc.username }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Document Type</p>
            <small>{{ kyc.type }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Document Number</p>
            <small>{{ kyc.number }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Document Expiry Date</p>
            <small>{{ kyc.date }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Submission Date</p>
            <small>{{
              new Date(kyc.created_at || kyc.createdAt).toDateString()
            }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Submission Time</p>
            <small>{{
              new Date(kyc.created_at || kyc.createdAt).toTimeString()
            }}</small>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="mb-0 font-weight-medium">Status</p>
            <span
              :class="`${
                kyc.status == 'approved' || kyc.status == 'true'
                  ? 'success--text'
                  : kyc.status === 'pending'
                  ? 'accent--text'
                  : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{
                kyc.status === 'approved' || kyc.status === 'true'
                  ? 'Approved'
                  : kyc.status === 'pending'
                  ? 'Pending'
                  : 'Declined'
              }}</span
            >
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">PERSONAL INFORMATION</p>
        <v-row v-for="info in kyc.userInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text-capitalize text--darken-1">{{
              info.title
            }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span
              class="grey--text text-capitalize text--darken-3 font-weight-medium"
              >{{ info.text }}</span
            >
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">UPLOADED DOCUMENTS</p>
        <v-row>
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">Image Uploaded</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">
              <v-img :src="kyc.image1"></v-img>
            </span>
          </v-col>
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">Video Uploaded</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">
              <video :key="kyc.image2" width="320" height="240" controls>
                <source :src="kyc.image2" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>

        <div
          v-if="checkIfPermission('manage kyc', admin.permissions)"
          class="d-flex"
        >
          <v-btn
            @click.stop="declineDialog = true"
            text
            color="error"
            v-if="kyc.status === 'pending'"
            >Decline</v-btn
          >
          <v-btn
            @click="confirmApprove = true"
            text
            color="success"
            v-if="kyc.status === 'pending'"
            >Approved</v-btn
          >
        </div>

        <v-btn text color="accent" @click="$emit('update:open', false)"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import userApi from '../api/admin/userManagement'

export default {
  name: 'kycDetails',
  data() {
    return {
      declineDialog: false,
      confirmApprove: false,
      loading: false,
      reason: ''
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  watch: {
    kyc: function () {
      this.$forceUpdate()
    }
  },
  mounted() {
    this.$forceUpdate()
  },
  methods: {
    async actionOnKYC(action) {
      this.loading = true
      const res = await userApi
        .users()
        .verifyKycRequest(this.kyc._id, { status: action, reason: this.reason })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.$store.dispatch('alert', {
        message: `${action} successfully`,
        status: true
      })
      this.loading = false
      this.declineDialog = false
      this.confirmApprove = false
      this.$emit('update:open', false)
      this.$emit('update:actionProgress', 'done')
    }
  },
  props: {
    open: Boolean,
    kyc: Object,
    actionProgress: String
  }
}
</script>

<style></style>
