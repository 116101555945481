<template>
  <v-container>
    <h1>Airtime</h1>
    <v-row>
      <v-col>
        <v-data-table :loading="loading" :items="airtimes" :headers="headers">
          <template v-slot:no-data>
            <div class="py-3 text-center">
              <v-img
                src="@/assets/img/undraw_empty.svg"
                width="325"
                class="mx-auto"
                alt="empty"
              />
              <h2 class="mt-3">No previous airtime Purchase</h2>
              <p>EMPTY</p>
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ new Date(item.createdAt).toDateString() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import airtimeApi from '../../api/admin/airtime'

export default {
  data() {
    return {
      airtimes: [],
      headers: [
        {
          value: 'date',
          text: 'Date'
        },
        {
          value: 'user.username',
          text: 'User'
        },
        {
          value: 'network.name',
          text: 'Network'
        },
        {
          value: 'status',
          text: 'Status'
        }
      ]
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    async getAll() {
      this.loading = true
      const res = await airtimeApi
        .airtime()
        .getAll({ populate: ['user', 'network'] })

      this.airtimes = res.data.data.data
      this.loading = false
    }
  }
}
</script>

<style></style>
