import axios from 'axios'
import Store from '../store/index'

import { errorHandler, successHandler, generateQuery } from './helpers/handler'
import { BASEURL } from './const'

const URL = `${BASEURL}/data`

export default {
  data() {
    return {
      /**
       * Get Any Data
       *
       * @param {String} requester account making the request (user/admin)
       * @param {Object} param1 Object type and to be populated field
       */
      getData: async (
        requester = 'user',
        { type, limit = 0, populate = [], params = {} }
      ) => {
        const endpoint = `${URL}/${type}${generateQuery(populate, limit)}`

        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       *Get data of a type
       *
       * @param {String} requester account making the request (user/admin)
       * @param {*} param1 type, typeId, fields to be populated
       */
      getSingleData: async (requester = 'user', { type, params, typeId }) => {
        const endpoint = `${URL}/${type}/${typeId}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      dashboardInfo: async (params) => {
        const endpoint = `${URL}/site-info`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      createSystemData: async (type, data, headers = {}) => {
        const endpoint = `${URL}/${type}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              ...headers
            }
          })
          return successHandler(res)
        } catch (error) {
          errorHandler(error)
        }
      },
      editSystemData: async ({ type, typeId }, data, headers = {}) => {
        const endpoint = `${URL}/${type}/${typeId}`

        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              ...headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteSystemData: async ({ type, typeId }) => {
        const endpoint = `${URL}/${type}/${typeId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      siteSettings: async (requester = 'admin') => {
        const endpoint = `${URL}/settings`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      countrySetting: async (requester = 'admin') => {
        const endpoint = `${URL}/country-settings`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateSite: async (data) => {
        const endpoint = `${URL}/settings`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateCountrySetting: async (data) => {
        const endpoint = `${URL}/country-settings/${data._id}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       * Get Leaderboards
       *
       * Type - sale/purchase
       * Category - coin/giftcard
       * period - day/year/month
       *
       * @param {String} requester account making the request (user/admin)
       * @param {*} param1 type, period, category
       */
      getLeaderboard: async ({
        period,
        category,
        type,
        requester = 'user',
        ...params
      }) => {
        const endpoint = `${URL}/${requester}/leaderboard`
        try {
          const res = await axios.get(endpoint, {
            params: {
              period,
              category,
              type,
              ...params
            },
            headers: {
              ...Store.getters[
                `${
                  requester === 'user'
                    ? 'user/getRequestHeaders'
                    : 'admin/getRequestHeaders'
                }`
              ].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
