<template>
  <v-container>
    <v-dialog v-model="accountStatementDialog" max-width="600">
      <v-card>
        <v-card-title> Generate Account Statement </v-card-title>
        <v-card-actions>
          <v-container>
            <v-text-field
              type="date"
              filled
              label="From"
              v-model="account.startDate"
            />
            <v-text-field
              type="date"
              filled
              label="To"
              v-model="account.endDate"
            />
            <v-btn
              color="primary"
              :loading="isGeneratingStatement"
              @click="generateAccountStatement"
              >Generate</v-btn
            >
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="8" sm="2" class="text-center">
        <v-img
          v-if="user.image != '' && editingImage != true"
          :src="user.image"
          width="110"
          height="110"
          class="rounded-circle mx-auto text-center"
        />
        <picture-input
          v-if="editingImage"
          :hideChangeButton="true"
          ref="pictureInput"
          @change="onChange"
          accept="image/jpeg,image/png"
          size="10"
          :removable="true"
          removeButtonClass="px-2 py-1 text-xs white--text error subtitle-2 rounded-lg"
          :customStrings="{
            drag: 'Upload Image'
          }"
        >
        </picture-input>
      </v-col>
      <v-col cols="8">
        <v-col>
          <h4>Change Picture</h4>
          <p>Maximum file size for Profile Avatar is 2MB</p>
          <v-btn
            class="accent mt-2 mt-md-0"
            :loading="attemptingUpload"
            @click="attemptUpload"
            depressed
            >Upload</v-btn
          >
          <v-btn
            class="accent mx-2 mt-2 mt-md-0"
            :loading="attemptingUpload"
            @click="editingImage = !editingImage"
            depressed
            >{{ editingImage ? 'Cancel Edit' : 'Edit Image' }}</v-btn
          >
        </v-col>
        <v-col cols="12" md="6">
          <v-btn depressed color="accent" @click="accountStatementDialog = true"
            >Generate Account Statement</v-btn
          >
        </v-col>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-container class="px-10">
        <div class="py-3">
          <h4>User Information</h4>
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              name="first_name"
              type="text"
              readonly
              filled
              label="First Name"
              v-model="profile.fname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              v-model="profile.lname"
              name="last_name"
              type="text"
              readonly
              filled
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              v-model="profile.phone"
              name="phone_number"
              type="text"
              filled
              readonly
              label="Phone Number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              name="email"
              type="email"
              filled
              v-model="profile.email"
              label="Email"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              name="dob"
              type="text"
              filled
              v-model="profile.dob"
              label="Date of Birth"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              dense
              name="Country"
              type="text"
              :items="['Nigeria', 'Ghana', 'Kenya', 'Others']"
              filled
              v-model="profile.country"
              label="Country"
              readonly
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              name="address"
              type="text"
              auto-grow
              filled
              v-model="profile.address"
              label="Address"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" depressed v-bind="attrs" v-on="on">
              Edit Profile
            </v-btn>
          </template>
          <v-form ref="updateProfile" @submit.prevent="validate">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Profile</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="First name"
                        :rules="[rules.required]"
                        v-model="profile.fname"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Last name"
                        :rules="[rules.required]"
                        v-model="profile.lname"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Phone Number"
                        :rules="[rules.required]"
                        v-model="profile.phone"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Email"
                        readonly
                        v-model="profile.email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        filled
                        type="date"
                        v-model="profile.dob"
                        label="Date of Birth"
                        prepend-icon="mdi-calendar"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        auto-grow
                        aria-autocomplete="address"
                        label="Address"
                        :rules="[rules.required]"
                        v-model="profile.address"
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  :disabled="updatingProfile"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  :loading="updatingProfile"
                  type="submit"
                >
                  Update
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-container>
    </v-row>
    <!-- <v-divider />
    <v-row>
      <v-container class="px-10">
        <h4>Referral</h4>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              readonly
              dense
              name="email"
              type="email"
              filled
              label="Referral Code"
              :value="user.username"
            >
              <v-btn
                icon
                @click="copy(user.username)"
                slot="append"
                color="accent"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-text-field>
          <p>SHARE</p>
            <v-btn-toggle>
              <v-hover v-slot="{ hover }">
                <template>
                  <v-btn
                    :to="`https://www.facebook.com/sharer/sharer.php?u=${shareContent}`"
                    :color="hover ? 'primary' : ''"
                  >
                    <v-icon :color="hover ? 'white' : ''" left
                      >mdi-facebook</v-icon
                    >
                    Facebook
                  </v-btn>
                </template>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :to="`https://twitter.com/intent/tweet?text=${shareContent}`"
                  :color="hover ? 'primary' : ''"
                >
                  <v-icon :color="hover ? 'white' : ''" left
                    >mdi-twitter</v-icon
                  >
                  Twitter
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :to="`https://api.whatsapp.com/send?phone=2348160730668&text=${shareContent}`"
                  :color="hover ? 'primary' : ''"
                >
                  <v-icon :color="hover ? 'white' : ''" left
                    >mdi-whatsapp</v-icon
                  >
                  Whatsapp
                </v-btn>
              </v-hover>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              id="reflink"
              readonly
              dense
              name="link"
              type="url"
              filled
              label="Referral Link"
              :value="`app.snappyechange.com/app/auth/register?ref=${user.username}`"
            >
              <v-btn
                data-clipboard-target="#reflink"
                icon
                @click="
                  copy(
                    `app.snappyechange.com/app/auth/register?ref=${user.username}`
                  )
                "
                slot="append"
                color="accent"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-row> -->
  </v-container>
</template>

<script>
import PictureInput from 'vue-picture-input'
import api from '../../../api/users/profile'
import rules from '../../../utils/rules'
import { mapState, mapActions } from 'vuex'
import { updateProfile as updateProfileState } from '../../../utils/helpers'

export default {
  name: 'ProfileSettings',
  components: {
    PictureInput
  },
  data: () => ({
    dobMenu: false,
    updatingProfile: false,
    attemptingUpload: false,
    accountStatementDialog: false,
    editingImage: false,
    account: {
      startDate: '',
      endDate: ''
    },
    isGeneratingStatement: false,
    dialog: false,
    profile: {
      fname: '',
      lname: '',
      phone: '',
      image: '',
      dob: '',
      address: ''
    },
    rules: {
      required: rules.required
    }
  }),
  created() {
    this.getUserProfile()
    this.profile.email = this.user.email
  },
  beforeMount() {
    this.profile.fname = this.user.fname
    this.profile.lname = this.user.lname
    this.profile.phone = this.user.phone
    this.profile.image = this.user.image
    this.profile.dob = this.user.dob || ''
    this.profile.address = this.user.address || ''
    this.profile.country = this.user.country || ''
  },
  computed: {
    shareContent() {
      return `I%20trade%20with%20%20%23SnappyExchnage,%20register%20your%20own%20account%20https%3A//snappyexchage.com/app/auth/login`
    },
    ...mapState({
      user: (state) => state.user.user
    })
  },
  methods: {
    async generateAccountStatement() {
      const params = {}

      if (this.account.startDate) {
        if (new Date(this.account.startDate) > new Date()) {
          this.$store.dispatch('alert', {
            message: 'Start date cannot be greater than today',
            error: true,
            status: true
          })
          return
        }
        params.startDate = this.account.startDate
      }

      if (this.account.endDate) {
        if (new Date(this.account.endDate) > new Date()) {
          this.$store.dispatch('alert', {
            message: 'End date cannot be greater than today',
            error: true,
            status: true
          })
          return
        }
        params.endDate = this.account.endDate
      }
      this.isGeneratingStatement = true

      const req = await api.profile().generateAccountStatement({
        params
      })

      if (req.error) {
        this.$store.dispatch('alert', {
          message:
            req.errorMessage.message ||
            `${req.internalError.message}, please try again`,
          error: true,
          status: true
        })
        this.isGeneratingStatement = false
        return
      }

      this.$store.dispatch('alert', {
        message:
          'Account statement generated successfully, kindly check your email',
        status: true
      })

      // initiate download
      const link = document.createElement('a')
      link.href = req.data.data
      link.setAttribute('download', 'account_statement.pdf')
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()

      // console.log(req)

      this.isGeneratingStatement = false
      this.accountStatementDialog = false
    },
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    validate() {
      if (this.$refs.updateProfile.validate()) {
        this.updateProfile()
      }
    },
    async attemptUpload() {
      this.attemptingUpload = true
      if (this.profile.image) {
        const res = await api.profile().updateProfile({ ...this.profile })
        if (res.error) {
          this.$store.dispatch('alert', {
            message:
              res.errorMessage.message ||
              `${res.internalError.message}, please try again`,
            error: true,
            status: true
          })
          this.attemptingUpload = false
          return
        }
        this.$store.dispatch('alert', {
          message: 'Profile Image updated',
          status: true
        })
        this.attemptingUpload = false
        this.editingImage = false
        updateProfileState({ profile: res.data.data })
      }
    },
    async updateProfile() {
      this.updatingProfile = true
      const res = await api.profile().updateProfile({ ...this.profile })
      if (res.error) {
        this.alert({
          error: true,
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true
        })
        this.updatingProfile = false
      }
      this.$store.commit('user/updateUserData', res.data.data)
      this.updatingProfile = false
      this.dialog = false
      this.alert({
        error: false,
        message: 'Profile update successful',
        status: true
      })
      this.profile.fname = res.data.data.fname
      this.profile.lname = res.data.data.lname
      this.profile.phone = res.data.data.phone
      this.profile.image = res.data.data.image
    },
    async getUserProfile() {
      await api.profile().get(['bankAccounts'])
    },
    onChange() {
      // console.log('New picture selected!')
      if (this.$refs.pictureInput.image) {
        this.profile.image = this.$refs.pictureInput.file
      } else {
        // console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    ...mapActions({
      alert: 'alert'
    })
  }
}
</script>

<style></style>
