import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/kyc`

export default {
  kyc() {
    return {
      getKYCStatus: async () => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      sendVerification: async (data) => {
        const endpoint = `${URL}`
        const formData = new FormData()
        delete data.tAndC
        delete data.correct
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            formData.append(key, element)
          }
        }
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'content-type': 'multipart/formdata'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
