export const getCurrencySymbol = (country) => {
  if (country.toLowerCase() === 'nigeria') return '₦'
  if (country.toLowerCase() === 'ghana') return '₵'
  if (country.toLowerCase() === 'kenya') return 'K'

  return ''
}

export const getCurrencyCode = (country) => {
  if (country?.toLowerCase() === 'nigeria') return 'NGN'
  if (country?.toLowerCase() === 'ghana') return 'GH₵'
  if (country?.toLowerCase() === 'kenya') return 'Ksh'

  return ''
}

export const getCurrencyName = (country) => {
  if (country?.toLowerCase() === 'nigeria') return 'Naira'
  if (country?.toLowerCase() === 'ghana') return 'Ghana Cedi'
  if (country?.toLowerCase() === 'kenya') return 'Kenyan shilling'

  return ''
}

export const getCountryCode = (country) => {
  if (!country) return ""
  if (country.toLowerCase() === 'nigeria') return 'NG'
  if (country.toLowerCase() === 'ghana') return 'GH'
  if (country.toLowerCase() === 'kenya') return 'KE'

  return 'NG'
}

export const getCountryName = (country) => {
  if (!country) return ""
  if (country.toLowerCase() === 'nigeria') return 'Nigeria'
  if (country.toLowerCase() === 'ghana') return 'Ghana'
  if (country.toLowerCase() === 'kenya') return 'Kenya'

  return 'Nigeria'
}

export const COUNTRIES = {
  NG: 'Nigeria',
  GH: 'Ghana',
  KE: 'Kenya'
}

export const rateLabel = (country) => {
  if (country.toLowerCase() === 'nigeria') return 'Naira rate'
  if (country.toLowerCase() === 'ghana') return 'Naira to Ghana Cedi'
  if (country.toLowerCase() === 'kenya') return 'Naira to Kenyan shilling'

  return ''
}

export const FEATURES = {
  SELL_CRYPTO: 'SELL_CRYPTO',
  BUY_CRYPTO: 'BUY_CRYPTO',
  TRADE_GIFTCARD: 'TRADE_GIFTCARD',
  BILL_PAYMENT: 'BILL_PAYMENT',
  AIRTIME_CONVERT: 'AIRTIME_CONVERT'
}

export const isCountrySupports = (country = '', features = []) => {
  const SUPPORT_LIST = {
    NIGERIA: [
      FEATURES.SELL_CRYPTO,
      FEATURES.BUY_CRYPTO,
      FEATURES.TRADE_GIFTCARD,
      FEATURES.BILL_PAYMENT,
      FEATURES.AIRTIME_CONVERT
    ],
    GHANA: [FEATURES.SELL_CRYPTO, FEATURES.TRADE_GIFTCARD],
    KENYA: [FEATURES.SELL_CRYPTO, FEATURES.TRADE_GIFTCARD]
  }

  return features.every((value) =>
    SUPPORT_LIST[country.toLowerCase()].includes(value)
  )
}
