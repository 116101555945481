<template>
  <v-dialog v-model="open" max-width="700" persistent>
    <v-card flat color="error">
      <v-container>
        <v-card-title class="white--text">Delete</v-card-title>
        <v-card-text>
          <p class="body-2 white--text font-weight-medium">
            You are about to this data record forever, do you want to proceed?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            :disabled="loading"
            @click="$emit('update:open', false)"
            >NO</v-btn
          >
          <v-btn depressed :loading="loading" @click="deleteType">Yes</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import dataApi from '../api/systemData'

export default {
  loading: true,
  name: 'DeleteDialog',
  props: {
    progress: String,
    open: Boolean,
    type: Object
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async deleteType() {
      this.loading = true
      const res = await dataApi.data().deleteSystemData({
        type: this.type.type,
        typeId: this.type.data._id
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.loading = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Delete Successfully',
        status: true,
        error: false
      })
      this.loading = false
      this.$emit('update:progress', 'done')
      this.$emit('update:open', false)
    }
  }
}
</script>

<style></style>
