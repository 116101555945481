<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view wallet', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div>
      <h1 class="mb-5">Wallets History</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />
      <v-row>
        <v-col>
          <v-tabs v-model="activeTab">
            <v-tab>Debit</v-tab>
            <v-tab>Credit</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card flat color="white">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchDebit"
                    append-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="arrangedTransaction"
                  :items-per-page="perPage"
                  :loading="loadingTransaction"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                  }"
                  @update:items-per-page="updateItemPerPage"
                  @update:page="updatePage"
                  :server-items-length="apiData.total"
                >
                  <template v-slot:[`item.user`]="{ item }">
                    <!-- <router-link
                  :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                  class="font-weight-medium text-capitalize d-block"
                  >{{ item.user }}</router-link
                > -->
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                      @click="openMinDialog(item.user_id)"
                    >
                      {{ item.user }}
                    </span>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{
                      new Date(item.createdAt).toLocaleDateString()
                    }}</span>
                  </template>

                  <!-- <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template> -->
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>
                      <span class="grey--text text--darken-2">{{
                        item.currency
                      }}</span>
                      {{ numberWithCommas(Number(item.amount)) }}</span
                    >
                  </template>
                </v-data-table>
                <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
                <fund-details
                  :actionProgress.sync="progress"
                  :open.sync="transactionOpen"
                  :transaction="transaction"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat color="white">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchCredit"
                    append-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :search="search"
                  :headers="headers"
                  :items="arrangedTransactionCredit"
                  :items-per-page="perPage"
                  :loading="loadingTransactionCredit"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                  }"
                  @update:items-per-page="updateItemPerPageCredit"
                  @update:page="updatePageCredit"
                  :server-items-length="apiDataCredit.total"
                >
                  <template v-slot:[`item.user`]="{ item }">
                    <!-- <router-link
                  :to="{ name: 'ViewUser', params: { id: item.user_id } }"
                  class="font-weight-medium text-capitalize d-block"
                  >{{ item.user }}</router-link
                > -->
                    <span
                      class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                      @click="openMinDialog(item.user_id)"
                    >
                      {{ item.user }}
                    </span>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{
                      new Date(item.createdAt).toLocaleDateString()
                    }}</span>
                  </template>

                  <!-- <template v-slot:[`item.date`]="{ item }">
                <small class="font-weight-medium">{{ item.date }}</small>
              </template> -->
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>
                      <span class="grey--text text--darken-2">{{
                        item.currency
                      }}</span>
                      {{ numberWithCommas(Number(item.amount)) }}</span
                    >
                  </template>
                </v-data-table>
                <!-- <delete-dialog :open.sync="dialogDelete" type="sales" /> -->
                <fund-details
                  :actionProgress.sync="progress"
                  :open.sync="transactionOpen"
                  :transaction="transaction"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import fundDetails from '../../../components/fundDetails.vue'
import walletApi from '../../../api/admin/wallet'
import { mapState } from 'vuex'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import { numberWithCommas } from '../../../utils/helpers'
import _ from 'lodash'

export default {
  components: {
    fundDetails,
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    activeTab: null,
    openMin: false,
    minUserId: '',
    progress: 'starting',
    searchCredit: '',
    searchDebit: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Name',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email'
      },
      {
        text: 'Money in Wallet',
        value: 'balance'
      },
      {
        text: 'Event On',
        value: 'createdAt'
      },
      {
        text: 'Action By',
        value: 'actionedBy.username'
      }
      // {
      //   text: 'Bonus (NGN)',
      //   value: 'bonus'
      // },
      // {
      //   text: 'Token',
      //   value: 'token'
      // }
    ],
    transactions: [],
    transactionsCredit: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    loadingTransactionCredit: false,
    apiData: {},
    apiDataCredit: {},
    lastPage: 1,
    lastPageCredit: 1
  }),
  created() {
    this.getTransactions(0)
    this.getTransactionsCredit(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
    this.debounceSearchCredit = _.debounce(this.searchDataCredit, 500)
  },
  watch: {
    searchCredit(newValue) {
      if (newValue === '') {
        this.getTransactionsCredit(0)
        return
      }
      this.debounceSearchCredit()
    },
    searchDebit(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    }),
    arrangedTransaction() {
      if (this.transactions.length !== 0) {
        return this.transactions.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency:
            transaction.currencyCode ?? transaction.user.country === 'Ghana'
              ? 'GHC'
              : 'NGN'
        }))
      }
      return []
    },
    arrangedTransactionCredit() {
      if (this.transactionsCredit.length !== 0) {
        return this.transactionsCredit.map((transaction) => ({
          ...transaction,
          id: transaction._id,
          user: transaction.user ? transaction.user.username : '',
          email: transaction.user ? transaction.user.email : '',
          name: transaction.user
            ? transaction.user.fname + ' ' + transaction.user.lname
            : '',
          user_id: transaction.user ? transaction.user._id : '',
          currency:
            transaction.currencyCode ?? transaction.user.country === 'Ghana'
              ? 'GHC'
              : 'NGN'
        }))
      }
      return []
    }
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async searchData() {
      this.loadingTransaction = true
      const res = await walletApi.data().getWalletHistory({
        params: {
          populate: ['user', 'actionedBy'],
          limit: this.perPage,
          source: 'admin',
          keyword: this.searchDebit,
          offset: length,
          type: 'debit'
        }
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async searchDataCredit() {
      this.loadingTransactionCredit = true
      const res = await walletApi.data().getWalletHistory({
        params: {
          populate: ['user', 'actionedBy'],
          limit: this.perPage,
          keyword: this.searchCredit,
          type: 'credit',
          source: 'admin'
        }
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }
      this.transactionsCredit = res.data.data.data
      this.apiDataCredit = res.data.data.meta
      this.loadingTransactionCredit = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updatePageCredit(page) {
      if (this.lastPageCredit - page > 2) {
        page = this.lastPageCredit
      }
      this.lastPageCredit = page
      this.getTransactionsCredit((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    updateItemPerPageCredit(count) {
      this.perPage = count
      this.getTransactionsCredit((this.lastPageCredit - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true
      const res = await walletApi.data().getWalletHistory({
        params: {
          populate: ['user', 'actionedBy'],
          limit: this.perPage,
          source: 'admin',
          offset: length,
          type: 'debit'
        }
      })

      // console.log(res.data.data.data)
      // this.transactions = res.data.data.data.filter((t) => t.balance > 0)
      this.transactions = res.data.data.data
      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    async getTransactionsCredit(length) {
      this.loadingTransactionCredit = true
      const res = await walletApi.data().getWalletHistory({
        params: {
          populate: ['user', 'actionedBy'],
          limit: this.perPage,
          offset: length,
          source: 'admin',
          type: 'credit'
        }
      })

      // console.log(res.data.data.data)
      // this.transactions = res.data.data.data.filter((t) => t.balance > 0)
      this.transactionsCredit = res.data.data.data
      this.apiDataCredit = res.data.data.meta
      this.loadingTransactionCredit = false
    }
  }
}
</script>

<style></style>
