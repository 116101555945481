var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.checkIfPermission('view rank', _vm.admin.permissions))?_c('div',[_c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Rank and Rewards")]),_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":function($event){{
            this.newRank = {
              title: '',
              minTransactionAmount: '',
              reward: '',
              image: null,
              description: ''
            }
          }}},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.isEditing ? 'Edit' : 'Create')+" Rank ")]),_c('v-spacer')],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Rank title","filled":""},model:{value:(_vm.newRank.title),callback:function ($$v) {_vm.$set(_vm.newRank, "title", $$v)},expression:"newRank.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Rank Minimum Transaction Amount","filled":""},model:{value:(_vm.newRank.minTransactionAmount),callback:function ($$v) {_vm.$set(_vm.newRank, "minTransactionAmount", $$v)},expression:"newRank.minTransactionAmount"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Rank Reward","type":"number","filled":""},model:{value:(_vm.newRank.reward),callback:function ($$v) {_vm.$set(_vm.newRank, "reward", $$v)},expression:"newRank.reward"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Rank Level","type":"number","filled":""},model:{value:(_vm.newRank.level),callback:function ($$v) {_vm.$set(_vm.newRank, "level", $$v)},expression:"newRank.level"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Rank Badge","filled":"","prepend-icon":"mdi-camera"},model:{value:(_vm.newRank.image),callback:function ($$v) {_vm.$set(_vm.newRank, "image", $$v)},expression:"newRank.image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Rank Description","filled":""},model:{value:(_vm.newRank.description),callback:function ($$v) {_vm.$set(_vm.newRank, "description", $$v)},expression:"newRank.description"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"accent","loading":_vm.creatingRank},on:{"click":function () {
                    _vm.isEditing ? _vm.updateRank() : _vm.createRank()
                  }}},[_vm._v(_vm._s(_vm.isEditing ? 'Save' : 'Create'))])],1)],1)],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),(_vm.checkIfPermission('create rank', _vm.admin.permissions))?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"accent","depressed":""},on:{"click":function($event){_vm.createDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Rank")],1):_vm._e()],1),_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v("Ranks")]),_c('v-tab',[_vm._v("Users in Ranks")]),_c('v-tab',[_vm._v("Manage Rank Movement")]),_c('v-tab',[_vm._v("Rank & Reward History")])],1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  },"loading":_vm.ranks.length === 0,"headers":_vm.headers,"items":_vm.ranks},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                  var item = ref.item;
return [_c('v-img',{attrs:{"src":item.badge,"max-width":"50px","max-height":"50px"}})]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(
                        _vm.checkIfPermission('update rank', _vm.admin.permissions)
                      )?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.onEditRank(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"color":"red","loading":item._id === _vm.deletingRank,"ripple":""},on:{"click":function($event){return _vm.deleteRank(item._id)}}},[_c('v-list-item-title',{staticClass:"text--error"},[_vm._v("Delete")])],1)],1)],1):_vm._e()]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loadingUsersInRank,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  },"headers":_vm.userRankHeaders,"items":_vm.ranksUser,"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{
                        name: 'ViewUser',
                        params: { id: item.user._id }
                      }}},[_vm._v(_vm._s(((item.user.fname) + " " + (item.user.lname))))]),_c('small',{staticClass:"font-weight-regular grey--text text--darken-2 d-block"},[_vm._v(_vm._s(item.user.username))])]}},{key:"item.rank",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.rank.title))])]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loadingRankMovement,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  },"headers":_vm.rankMovementHeaders,"items":_vm.ranksMovement,"server-items-length":_vm.ranksMovementApiData.total},on:{"update:items-per-page":_vm.updateItemPerPageMovement,"update:page":_vm.updatePageMovement},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{
                        name: 'ViewUser',
                        params: { id: item.user._id }
                      }}},[_vm._v(_vm._s(((item.user.fname) + " " + (item.user.lname))))]),_c('small',{staticClass:"font-weight-regular grey--text text--darken-2 d-block"},[_vm._v(_vm._s(item.user.username))])]}},{key:"item.oldRank",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.oldRank.title))])]}},{key:"item.newRank",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.newRank.title))])]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent"},on:{"click":function () {
                          _vm.confirmRewardApproval = true
                          _vm.actionOnApprove = function () {
                            _vm.updateRankReward(item._id)
                          }
                        }}},[_vm._v(" Click to Approve ")])]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loadingRankMovementFull,"footer-props":{
                    itemsPerPageOptions: [5, 10, 15, 25, 50]
                  },"headers":_vm.rankMovementHeadersFull,"items":_vm.ranksMovementFull,"server-items-length":_vm.apiDataFull.total},on:{"update:items-per-page":_vm.updateItemPerPageFull,"update:page":_vm.updatePageFull},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-capitalize d-block",attrs:{"to":{
                        name: 'ViewUser',
                        params: { id: item.user._id }
                      }}},[_vm._v(_vm._s(((item.user.fname) + " " + (item.user.lname))))]),_c('small',{staticClass:"font-weight-regular grey--text text--darken-2 d-block"},[_vm._v(_vm._s(item.user.username))])]}},{key:"item.oldRank",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.oldRank.title))])]}},{key:"item.newRank",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.newRank.title))])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [(
                        _vm.checkIfPermission(
                          'update rank reward',
                          _vm.admin.permissions
                        )
                      )?_c('v-btn',{attrs:{"color":"success"},on:{"click":function () {
                          _vm.confirmRewardApproval = true
                          _vm.actionOnApprove = function () {
                            _vm.updateRankReward(item._id)
                          }
                        }}},[_vm._v(" Approve ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.confirmRewardApproval),callback:function ($$v) {_vm.confirmRewardApproval=$$v},expression:"confirmRewardApproval"}},[_c('v-card',{attrs:{"flat":"","color":"warning"}},[_c('v-container',[_c('v-card-title',{staticClass:"white--text text-capitalize"},[_vm._v(" Confirm Reward ")]),_c('v-card-text',[_c('p',{staticClass:"body-2 white--text font-weight-medium"},[_vm._v(" Confirm this level promotion and reward user? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","disabled":_vm.updatingRewards},on:{"click":function () {
                  _vm.confirmRewardApproval = false
                  _vm.actionOnApprove = null
                }}},[_vm._v("NO")]),_c('v-btn',{attrs:{"depressed":"","loading":_vm.updatingRewards},on:{"click":function($event){_vm.actionOnApprove ? _vm.actionOnApprove() : function () {}}}},[_vm._v("Yes")])],1)],1)],1)],1)],1):_c('div',[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])])])}
var staticRenderFns = []

export { render, staticRenderFns }