<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view promocode usage', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <v-dialog v-model="filterDialog" max-width="500">
        <v-card>
          <v-card-title>
            <span class="headline">Filter</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="From"
                  type="date"
                  v-model="filterDate.from"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="To" type="date" v-model="filterDate.to">
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn color="blue darken-1" text @click="getCodesUsage(0)">
                  Filter
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    () => {
                      filterDate = {
                        from: '',
                        to: ''
                      }
                      getCodesUsage(0)
                    }
                  "
                >
                  Clear Filter
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <h1 class="mb-5">Promo Code History</h1>
      <v-row>
        <v-col>
          <v-card flat color="white">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                @click="filterDialog = true"
                color="primary"
                depressed
                class="ml-2"
              >
                <v-icon left> mdi-filter </v-icon>
                Filter</v-btn
              >
            </v-card-title>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="promoCodeHistory"
              :items-per-page="perPage"
              :loading="loadingCodes"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
              }"
              @update:items-per-page="updateItemPerPage"
              @update:page="updatePage"
              :server-items-length="apiData.total"
            >
              <template v-slot:[`item.user`]="{ item }">
                <div>
                  <router-link
                    :to="{ name: 'ViewUser', params: { id: item.user._id } }"
                    class="font-weight-medium text-capitalize d-block"
                    >{{ item.user.username }}</router-link
                  >
                  <p>
                    {{ item.user.email }}
                  </p>
                </div>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div>
                  <p>
                    {{ Number(item.transaction.main_amo) }}
                    {{ item.transaction.currencyCode }}
                  </p>
                </div>
              </template>
              <template v-slot:[`item.product`]="{ item }">
                <div>
                  <p>
                    {{ item.product.name }}
                  </p>
                </div>
              </template>
              <template v-slot:[`item.transaction`]="{ item }">
                <div>
                  <p>
                    {{ new Date(item.createdAt).toLocaleDateString() }}
                  </p>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import promoCodes from '../../../api/promo-codes'

export default {
  components: {},
  data: () => ({
    search: '',
    headers: [
      {
        text: 'User',
        value: 'user'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Promo Code Amount',
        value: 'transaction.promocodeAmountEarned'
      },
      {
        text: 'Code Used',
        value: 'promoCode.code'
      },
      {
        text: 'Product',
        value: 'product'
      },
      {
        text: 'Trx Date',
        value: 'transaction'
      }
    ],
    promoCodeHistory: [],
    perPage: 10,
    loadingCodes: false,
    apiData: {},
    lastPage: 1,
    filterDialog: false,
    filterDate: {
      from: '',
      to: ''
    }
  }),
  created() {
    this.getCodesUsage(0)
    this.debounceSearch = _.debounce(this.searchData, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getCodesUsage(0)
        return
      }
      this.debounceSearch()
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getCodesUsage((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getCodesUsage((this.lastPage - 1) * this.perPage)
    },
    async getCodesUsage(length) {
      this.loadingCodes = true

      const params = {
        populate: ['user', 'product', 'transaction', 'promoCode'],
        limit: this.perPage,
        offset: length
      }

      if (this.filterDate.from) {
        params.from = this.filterDate.from
      }

      if (this.filterDate.to) {
        params.to = this.filterDate.to
      }

      const res = await promoCodes.promoCodes().getCodeUsage({
        params,
        requester: 'admin'
      })
      this.apiData = res.data.data.meta
      this.filterDialog = false
      this.promoCodeHistory = res.data.data.data
      this.loadingCodes = false
    }
  }
}
</script>

<style></style>
