<template>
  <v-dialog
    @click:outside="$emit('update:open', false)"
    v-model="open"
    max-width="800"
  >
    <v-card class="pa-4">
      <v-card-title>User Details</v-card-title>
      <v-overlay v-if="loadingUserData || loading" opacity="0.8">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">
              Successful Purchase
            </p>
            <span class="text-h5"
              >{{ numberWithCommas(totalApprovedPurchase) }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Successful Sales</p>
            <span class="text-h5"
              >{{ numberWithCommas(totalApprovedSales) }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">
              Successful Giftcard Sales
            </p>
            <span class="text-h5"
              >{{ numberWithCommas(totalApprovedGiftcard) }}
              <span class="grey--text subtitle-2">{{
                userCurrency
              }}</span></span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">User Status</p>
            <span
              :class="`${
                arrangeUserData.status ? 'success--text' : 'error--text'
              } font-weight-bold text-capitalize mt-2 `"
              >{{ arrangeUserData.status ? 'Active' : 'Suspended' }}</span
            >
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-0 font-weight-medium grey--text">Registered Date</p>
            <span class="font-weight-medium overline"
              >{{ new Date(arrangeUserData.createdAt).toDateString() }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <p class="mb-2 font-weight-medium grey--text">
              Verification Status
            </p>
            <div class="d-flex">
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.email_verify ? 'success' : 'error'
                }`"
              >
                Email
              </span>
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.bvnVerified ? 'success' : 'error'
                }`"
              >
                BVN
              </span>
              <span
                :class="`font-weight-medium mx-1 rounded-lg white--text px-3 py-2 ${
                  arrangeUserData.kycverify ? 'success' : 'error'
                }`"
                >KYC
              </span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-4"></v-divider>
        <p class="overline grey--text">User Info</p>
        <v-row v-for="info in arrangeUserData.info" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
        <!-- <v-divider class="mb-2 mt-4"></v-divider> -->
        <!-- <p class="overline grey--text">More information</p> -->
        <v-row v-for="info in arrangeUserData.moreInfo" :key="info.title">
          <v-col cols="5" md="5">
            <span class="grey--text text--darken-1">{{ info.title }}</span>
          </v-col>
          <v-col cols="7" md="7">
            <span class="grey--text text--darken-3 font-weight-medium">{{
              info.text
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'ViewUser', params: { id: userId } }"
          >Full Profile</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { BASEURL } from '../api/const'
import Store from '../store'
import { numberWithCommas } from '../utils/helpers'
import userApi from '../api/admin/userManagement'
import { getCurrencyCode } from '../utils/countryHelpers'
export default {
  name: 'UserDetailsMin',
  props: {
    open: Boolean,
    userId: String
  },
  watch: {
    userId(newValue, oldValue) {
      if (newValue === oldValue) return
      this.getUserDetails()
    },
    user(newValue, oldValue) {
      this.totalApprovedPurchase = 0
      this.totalApprovedSales = 0
      this.totalApprovedGiftcard = 0
      this.getTransactionForAUser(newValue._id || newValue.id)
    }
  },
  data: () => ({
    numberWithCommas,
    loadingUserData: false,
    data: {},
    user: {},
    tabs: null,
    userTransactions: [],
    loading: false,
    loadingTransactions: false,
    totalApprovedPurchase: 0,
    totalApprovedSales: 0,
    totalApprovedGiftcard: 0
  }),
  methods: {
    async getUserDetails() {
      this.loadingUserData = true
      const res = await userApi.users().getSingle({ userId: this.userId })
      this.user = res.data.data
      this.loadingUserData = false
    },
    async getTransactionForAUser() {
      this.loading = true
      const res = await axios.get(
        `${BASEURL}/transactions?user=${this.arrangeUserData._id}&populate=currency&populate=giftcard&limit=0`,
        {
          headers: {
            ...Store.getters['admin/getRequestHeaders'].headers
          }
        }
      )
      this.userTransactions = res.data.data.data
      // Purchase
      this.transactionPurchase = res.data.data.data
        .filter((transaction) => transaction.type === 'purchase')
        .map((transaction) => ({
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionPurchase.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedPurchase = this.totalApprovedPurchase + e.price
        }
      })

      // Sales
      this.transactionSales = res.data.data.data
        .filter(
          (transaction) =>
            transaction.type === 'sale' && transaction.category !== 'giftcard'
        )
        .map((transaction) => ({
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          rate: { price: transaction.rate, currency: transaction.currencyCode },
          price: transaction.main_amo,
          status: transaction.status
        }))

      this.transactionSales.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedSales = this.totalApprovedSales + e.price
        }
      })

      this.transactionGiftcard = res.data.data.data
        .filter((t) => t.category === 'giftcard')
        .map((trx) => ({
          ...trx,
          date: new Date(
            trx.created_at ? trx.created_at : trx.createdAt
          ).toDateString(),
          id: trx._id,
          image: trx.giftcard ? trx.giftcard.image : '',
          amount: trx.main_amo
        }))

      this.transactionGiftcard.forEach((e) => {
        if (e.status === 'approved') {
          this.totalApprovedGiftcard = this.totalApprovedGiftcard + e.amount
        }
      })
      this.loading = false
    }
  },
  computed: {
    userCurrency() {
      return getCurrencyCode(this.user?.country)
    },
    arrangeUserData() {
      if (this.user) {
        return {
          ...this.user,
          purchase: { total: 0.0 },
          sales: { total: 0.0 },
          giftcard: { total: 0.0 },
          info: [
            {
              title: 'Full Name',
              text: `${this.user.fname} ${this.user.lname}`
            },
            {
              title: 'Username',
              text: `${this.user.username}`
            },
            {
              title: 'Email',
              text: `${this.user.email}`
            },
            {
              title: 'Country',
              text: `${this.user.country}`
            },
            {
              title: 'Address',
              text: `${this.user.address || ''}`
            },
            {
              title: 'Date of Birth',
              text: `${this.user.dob || ''}`
            },
            {
              title: 'Phone',
              text: `${this.user.phone || ''}`
            }
          ]
        }
      }
      else {
        return {}
      }
    }
  }
}
</script>

<style></style>
