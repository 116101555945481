import Giftcard from '@/views/admin/Giftcard.vue'
import GProcessed from '@/views/admin/giftcards/Processed.vue'
import GDeclined from '@/views/admin/giftcards/Declined.vue'
import GPending from '@/views/admin/giftcards/Pending.vue'

const giftcardRoutes = {
  path: '/admin/admin/giftcard',
  component: Giftcard,
  redirect: '/admin/admin/giftcard/processed',
  name: 'GiftcardGroup',
  meta: {
    title: 'Giftcard'
  },
  children: [
    {
      path: '/admin/admin/giftcard/processed',
      component: GProcessed,
      name: 'ProcessedGiftcard',
      meta: {
        title: 'Processed Giftcard'
      }
    },
    {
      path: '/admin/admin/giftcard/pending',
      component: GPending,
      name: 'PendingGiftcard',
      meta: {
        title: 'Pending Giftcard'
      }
    },
    {
      path: '/admin/admin/giftcard/declined',
      component: GDeclined,
      name: 'DeclinedGiftcard',
      meta: {
        title: 'Declined Giftcard'
      }
    }
  ]
}

export default giftcardRoutes
