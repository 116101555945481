<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view kyc', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <v-overlay :value="loading" opacity="0.85">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <h1>KYC Verification</h1>
      <v-row>
        <v-col>
          <kyc-details
            :actionProgress.sync="progress"
            :open.sync="viewDetailsModal"
            :kyc="selectedKyc"
          />
          <v-sheet>
            <v-container>
              <v-card flat>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search..."
                  single-line
                ></v-text-field>
              </v-card>
              <v-data-table
                :loading="loading"
                :headers="header"
                :search="search"
                :items="kycsM"
                :items-per-page="perPage"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 20, 25, 50]
                }"
                @update:items-per-page="updateItemPerPage"
                @update:page="updatePage"
                :server-items-length="apiData.total"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item ripple @click="viewKYC(item)">
                        <v-list-item-title>View Details</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  <router-link
                    :to="{ name: 'ViewUser', params: { id: item.userData.id } }"
                    class="font-weight-medium text-capitalize d-block"
                    >{{ item.userData.username }}</router-link
                  >
                  <div class="subtitle-2 grey--text">
                    {{ item.userData.email }}
                  </div>
                </template>
                <template v-slot:[`item.docs`]="{ item }">
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          target="_blank"
                          :to="`/view-image?imageLink=${item.image1}`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download Image</span>
                    </v-tooltip>
                  </span>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :to="`/view-image?videoLink=${item.image2}`"
                          target="_blank"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file-video-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Download Video</span>
                    </v-tooltip>
                  </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-card
                    :color="
                      item.status == 'approved' || item.status == 'true'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error'
                    "
                    flat
                    rounded="md"
                    outlined
                    class="text-capitalize text-center white--text pa-1"
                  >
                    {{
                      item.status === 'approved' || item.status === 'true'
                        ? 'Approved'
                        : item.status === 'pending'
                        ? 'Pending'
                        : 'Declined'
                    }}
                  </v-card>
                </template>
              </v-data-table>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import kycDetails from '../../components/kycDetails.vue'
import userApi from '../../api/admin/userManagement'
import { mapState } from 'vuex'

export default {
  name: 'KYC',
  components: { kycDetails },
  data() {
    return {
      search: '',
      perPage: 25,
      options: {},
      apiData: {},
      header: [
        {
          text: 'User',
          sortable: false,
          value: 'user'
        },
        {
          text: 'Doc Type',
          value: 'type'
        },
        {
          text: 'Documents',
          sortable: false,
          value: 'docs'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '',
          sortable: false,
          value: 'action'
        }
      ],
      selectedKyc: {},
      loading: false,
      viewDetailsModal: false,
      progress: 'starting',
      kycs: [],
      kycsM: []
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.$store.dispatch('admin/getKycData')
    this.getKycList()
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue === 'done') {
        this.getKycList(0)
        this.$store.dispatch('admin/getKycData')
      }
      this.progress = 'starting'
    },
    options: {
      handler() {
        this.getKycList()
      },
      deep: true
    }
  },
  methods: {
    updatePage(page) {
      this.getKycList((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getKycList((this.lastPage - 1) * this.perPage)
    },
    async getKycList(length) {
      this.loading = true
      const res = await userApi.users().kycRequest(this.perPage, length)
      this.kycs = res.data.data.data
      this.kycsM = res.data.data.data.map((e) => ({
        ...e,
        userData: {
          id: e.user._id,
          fname: e.user.fname,
          lname: e.user.lname,
          email: e.user.email,
          username: e.user.username,
          phone: e.user.phone,
          address: e.user.address,
          dob: e.user.dob
        },
        user: `${e.user.fname} ${e.user.username} ${e.user.lname} ${e.user.email}`
      }))
      this.apiData = res.data.data.meta
      this.loading = false

      // console.log(this.apiData)
    },
    viewKYC(item) {
      this.selectedKyc = {
        ...item,
        username: item.userData.username,
        userInfo: [
          {
            title: 'First Name',
            text: item.userData.fname
          },
          {
            title: 'Last Name',
            text: item.userData.lname
          },
          {
            title: 'Email',
            text: item.userData.email
          },
          {
            title: 'Phone Number',
            text: item.userData.phone
          },
          {
            title: 'Date of Birth',
            text: item.userData.dob
          },
          {
            title: 'Full Address',
            text: item.userData.address
          },
          {
            title: 'Reason for Decline',
            text: item.reason ? item.reason : 'Request not declined yet.'
          },
          // {
          //   title: 'Country of Residence',
          //   text: item.user.country
          // },
          {
            title: 'Action By',
            text: item.actionby
              ? item.actionby.name
              : 'Request not worked on yet'
          }
        ]
      }
      this.viewDetailsModal = true
    }
  }
}
</script>

<style></style>
