<template>
  <v-container>
    <div
      v-if="!checkIfPermission('create default data', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <h1>Currency Rate</h1>
      <v-row class="mt-5">
        <v-col>
          <v-card flat>
            <v-row class="py-2 px-6">
              <div>
                <v-card-title>Currency Rate</v-card-title>
                <v-card-subtitle
                  >Modify platform currency rate settings here</v-card-subtitle
                >
              </div>
              <v-spacer />
              <v-select
                :items="availableCountries"
                label="Country"
                v-model="selectedCountry"
              />
            </v-row>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    :disabled="loading"
                    v-model="countryValues.nairaRate"
                    persistent-hint
                    :label="`${rateLabel(selectedCountry)}`"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              v-if="
                checkIfPermission('update country settings', admin.permissions)
              "
            >
              <v-spacer></v-spacer>
              <v-btn
                color="accent"
                @click="updateSiteData"
                :loading="loading"
                depressed
                width="150"
                class="font-weight-bold"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import dataApi from '../../../api/systemData'
import { mapState } from 'vuex'
import { COUNTRIES, rateLabel } from '../../../utils/countryHelpers'

export default {
  data() {
    return {
      rateLabel,
      COUNTRIES,
      loading: false,
      selectedCountry: 'NIGERIA',
      countryValues: {}
    }
  },
  computed: {
    ...mapState({
      siteSettings: (state) => state.admin.siteSettings,
      admin: (state) => state.admin.admin,
      countrySetting: (state) => state.admin.countrySetting
    }),
    availableCountries() {
      return Object.keys(this.countrySetting).map((country) =>
        country.toUpperCase()
      )
    }
  },
  watch: {
    selectedCountry(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.countryValues = this.countrySetting[
          this.selectedCountry?.toLocaleLowerCase()
        ]
      }
    }
  },
  created() {
    this.getCountrySetting()
  },
  methods: {
    async getCountrySetting() {
      const res = await dataApi.data().countrySetting()
      this.$store.commit('admin/setCountrySetting', res.data.data)
      this.countryValues = this.countrySetting[
        this.selectedCountry?.toLocaleLowerCase()
      ]
    },
    async updateSiteData() {
      this.loading = true
      const res = await dataApi.data().updateCountrySetting(this.countryValues)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'Updated Successfully',
        status: true
      })
      this.loading = false
    }
  }
}
</script>

<style></style>
