import Admin from '../layouts/Admin.vue'

// Admin
import Dashboard from '../views/admin/Dashbaord.vue'
import Profile from '../views/admin/Profile.vue'
import KYC from '../views/admin/kyc.vue'
import Airtime from '../views/admin/airtime.vue'
import TransactionCalculator from '../views/admin/TransactionCalculator.vue'
import WalletFundingReq from '../views/admin/WalletFundingReq.vue'

import salesRoutes from './admin/sales.routes'
import purchaseRoutes from './admin/purchase.routes'
// import messageRoutes from './admin/messages.routes'
import userRoutes from './admin/user.routes'
import giftcardRoutes from './admin/giftcard.routes'
import settingRoutes from './admin/settings.routes'
import withdrawalRoutes from './admin/withdrawal.routes'
import promoCodesRoutes from './admin/promoCodes.router'

const adminRoutes = {
  path: '/admin/admin',
  redirect: '/admin/admin/dashboard',
  component: Admin,
  children: [
    {
      path: '/admin/admin/dashboard',
      component: Dashboard,
      name: 'DashboardAdmin',
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/admin/admin/airtime',
      component: Airtime,
      name: 'AdminAirtime',
      meta: {
        title: 'Airtime'
      }
    },
    {
      path: '/admin/admin/profile',
      component: Profile,
      name: 'AdminProfile',
      meta: {
        title: 'Profile'
      }
    },
    {
      path: '/admin/admin/transaction-calculator',
      component: TransactionCalculator,
      name: 'TransactionCalculator',
      meta: {
        title: 'Transaction Calculator'
      }
    },
    {
      path: '/admin/admin/wallet-funding',
      component: WalletFundingReq,
      name: 'WalletFundingReq',
      meta: {
        title: 'Wallet Funding Request'
      }
    },
    {
      path: '/admin/admin/leaderboard',
      component: () => import('../views/admin/Leaderboards.vue'),
      name: 'AdminLeaderboard',
      meta: {
        title: 'Leaderboard'
      }
    },
    {
      path: '/admin/admin/traders-post',
      component: () => import('../views/admin/TraderPost.vue'),
      name: 'AdminTraderPost',
      meta: {
        title: 'Traders Post'
      }
    },
    {
      path: '/admin/admin/kyc',
      component: KYC,
      name: 'AdminKYC',
      meta: {
        title: 'KYC'
      }
    },
    giftcardRoutes,
    purchaseRoutes,
    salesRoutes,
    // messageRoutes,
    userRoutes,
    settingRoutes,
    withdrawalRoutes,
    promoCodesRoutes
  ]
}

export default adminRoutes
