import axios from 'axios'
import Store from '../../store/index'

import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/admin`

export default {
  users() {
    return {
      getAll: async () => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            params: {
              limit: 0,
              offset: 0
            },
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })

          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateAdminDetails: async ({ data, adminId }) => {
        const endpoint = `${BASEURL}/admin/${adminId}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getSingle: async (adminId, params = {}) => {
        const endpoint = `${URL}/${adminId}`
        try {
          const res = await axios.get(endpoint, {
            params,
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateAdminStatus: async (adminId, status) => {
        const endpoint = `${URL}/${adminId}`
        try {
          const res = await axios.post(
            endpoint,
            { status },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      toggleOTP: async ({ adminId, otp }) => {
        const endpoint = `${URL}/${adminId}/toggle-otp`
        try {
          const res = await axios.post(
            endpoint,
            { otp },
            {
              headers: {
                ...Store.getters['admin/getRequestHeaders'].headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
