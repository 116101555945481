const rules = {
  required: (value) => !!value || 'This field cannot be empty',
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Enter a valid email'
  },
  valueWithNumber: (value) => {
    const pattern = /(?=.*\d)/gm
    return pattern.test(value) || 'Must contain a number'
  },
  valueWithUppercase: (value) => {
    const pattern = /(?=.*[A-Z])/gm
    return pattern.test(value) || 'Must contain a uppercase'
  },
  valueWithLowercase: (value) => {
    const pattern = /(?=.*[a-z])/gm
    return pattern.test(value) || 'Must contain a lowercase'
  },
  valueWithAtLeast8Characters: (value) => {
    const pattern = /.{8,}/gm
    return pattern.test(value) || 'Must be at least 8 character'
  }
}

export default rules
