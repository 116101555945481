import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'
import VueSweetalert2 from 'vue-sweetalert2'
import VueScrollTo from 'vue-scrollto'
import AOS from 'aos'
import { mapState } from 'vuex'

import 'aos/dist/aos.css' // You can also use <link> for styles
import './assets/css/helper.scss'
import 'animate.css'
import './assets/css/tailwind.css'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
import { getCountryName, getCurrencyCode } from './utils/countryHelpers'

// Vue.config.devtools = true

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueLazyload)
Vue.use(VueSweetalert2)
Vue.use(VueScrollTo)

Vue.mixin({
  computed: {
    ...mapState({
      userIn: (state) => state.user.user
    }),
    userCurrency() {
      return getCurrencyCode(this.userIn.country)
    },
    userCountry() {
      return getCountryName(this.userIn.country)
    }
  },
  methods: {
    checkIfPermission: function (permissionName, permissions = []) {
      const findPermission = permissions.findIndex(
        (permission) =>
          permission.name.toLowerCase() === permissionName.toLowerCase().trim()
      )
      if (findPermission >= 0) return true
      else return false
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  created() {
    AOS.init()
  },
  render: (h) => h(App)
}).$mount('#app')
