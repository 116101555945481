// Sales
import Sales from '@/views/admin/Sales.vue'
import SDeclined from '@/views/admin/sales/Declined.vue'
import SAcccepted from '@/views/admin/sales/Accepted.vue'
import SPending from '@/views/admin/sales/Pending.vue'

const salesRoutes = {
  path: '/admin/admin/sales',
  component: Sales,
  redirect: '/admin/admin/sales/processed',
  name: 'Sales',
  meta: {
    title: 'Sales'
  },
  children: [
    {
      path: '/admin/admin/sales/declined',
      component: SDeclined,
      name: 'DeclinedSales',
      meta: {
        title: 'Declined Sales'
      }
    },
    {
      path: '/admin/admin/sales/processed',
      component: SAcccepted,
      name: 'AcceptedSales',
      meta: {
        title: 'Accepted Sales'
      }
    },
    {
      path: '/admin/admin/sales/pending',
      component: SPending,
      name: 'PendingSales',
      meta: {
        title: 'Pending Sales'
      }
    }
  ]
}

export default salesRoutes
