<template>
  <div v-if="imageLink !== undefined">
    <img :src="imageLink" />
  </div>
  <div v-else>
    <div class="center">
      <video width="65%" height="65%" controls>
        <source :src="videoLink" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageLink: '',
      videoLink: ''
    }
  },
  mounted() {
    this.imageLink = this.$route.query.imageLink
    this.videoLink = this.$route.query.videoLink
  }
}
</script>

<style>
.image {
  width: 100%;
  height: 100%;
}

.center {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: burlywood;
}
</style>
