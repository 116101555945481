import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/bank-account`

export default {
  profile() {
    return {
      addBankAccount: async ({ accountNumber, accountName, bankCode, otp }) => {
        const endpoint = `${URL}`
        const data = {
          account_number: accountNumber,
          bank_code: bankCode,
          account_name: accountName,
          otp
        }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      editBankAccount: async ({
        bankId,
        accountNumber,
        accountName,
        bankCode
      }) => {
        const endpoint = `${URL}/${bankId}`
        const data = {
          account_number: accountNumber,
          bank_code: bankCode,
          account_name: accountName
        }
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteBankAccount: async ({ id, otp }) => {
        const endpoint = `${URL}/${id}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers,
              'Content-Type': 'application/json'
            },
            data: {
              otp
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getBankAccounts: async () => {
        const endpoint = `${URL}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['user/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
