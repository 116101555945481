import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import admin from './modules/admin'

import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'snappy'
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    security: {
      tokenExpired: false,
      accountSuspended: false
    },
    alert: {
      status: false,
      message: null,
      error: false
    }
  },
  mutations: {
    setAlertState(state, data) {
      state.alert = { ...state.alert, ...data }
    },
    setAccountSuspended(state, status) {
      state.security.accountSuspended = status
    },
    setTokenExpiredState(state, status) {
      state.security.tokenExpired = status
    }
  },
  actions: {
    alert({ commit }, { message, error, status, time = 5500 }) {
      commit('setAlertState', { message, error, status })
      setTimeout(() => {
        commit('setAlertState', { message: null, error: false, status: false })
      }, time)
    }
  },
  modules: {
    user,
    admin
  },
  plugins: [vuexLocal.plugin]
})
