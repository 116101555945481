import Store from '../../store'

const errorHandler = (err) => {
  if (err.response.status === 401) {
    Store.commit('setAccountSuspended', true)
  }

  if (
    err.response &&
    err.response.data &&
    err.response.data &&
    (err.response.data.message === 'Invalid token' ||
      err.response.data.message === 'Format is Authorization: Bearer [token]')
  ) {
    Store.commit('setTokenExpiredState', true)
  } else if (
    err.response &&
    err.response.data &&
    err.response.data.response &&
    err.response.data.response.message ===
      'Your account has been suspended. Please Contact Support'
  ) {
    Store.commit('setAccountSuspended', true)
  }
  return {
    error: true,
    errorMessage: err.response ? err.response.data : '',
    internalError: err
  }
}

const successHandler = (res) => {
  return { error: false, data: res.data, status: res.status }
}

const generateQuery = (populate = [], limit = 0) => {
  if (populate.length === 0) return `?limit=${limit}`
  if (populate.length === 1)
    return `?populate=options.${populate[0]}&limit=${limit}`
  let query = '?'
  populate.forEach((item, index) => {
    query =
      query +
      `populate[]=options.${item}${populate.length === index + 1 ? '' : '&'}`
  })
  return `${query}&limit=${limit}`
}

const generateNoOptionsQuery = (populate = [], limit) => {
  if (populate.length === 0) return `?limit=${limit || 0}`
  if (populate.length === 1)
    return `?populate=${populate[0]}&limit=${limit || 0}`
  let query = '?'
  populate.forEach((item, index) => {
    query =
      query + `populate[]=${item}${populate.length === index + 1 ? '' : '&'}`
  })
  return `${query}&limit=${limit || 0}`
}

export { errorHandler, successHandler, generateQuery, generateNoOptionsQuery }
