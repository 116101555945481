import axios from 'axios'
import Store from '../../store'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/pin`

export default {
  pin() {
    return {
      update: async ({ pin }) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(
            endpoint,
            { pin },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      resetPin: async ({ password, newPin, confirmNewPin }) => {
        const endpoint = `${BASEURL}/reset-pin`
        try {
          const res = await axios.post(
            endpoint,
            { password, newPin, confirmNewPin },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      changePin: async ({ pin, newPin, confirmNewPin, password }) => {
        const endpoint = `${BASEURL}/change-pin`
        try {
          const res = await axios.post(
            endpoint,
            { pin, password, newPin, confirmNewPin },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      forgetPin: async ({ password }) => {
        const endpoint = `${BASEURL}/forgot-pin`
        try {
          const res = await axios.post(
            endpoint,
            { password },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      verifyPin: async ({ pin }) => {
        const endpoint = `${URL}/verify`
        try {
          const res = await axios.post(
            endpoint,
            { pin },
            {
              headers: {
                ...Store.getters['user/getRequestHeaders'].headers,
                'Content-Type': 'application/json'
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
