import Store from '../store/'

/**
 * Takes a valid value, and format with commas
 * e.g
 * Given value 14832 @returns 14,832
 *
 * @param {[Number, String]} digits value to format
 */

import walletApi from '../api/users/wallet'

function numberWithCommas(digits) {
  if (!digits) return 0
  var parts = digits.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

async function updateProfile({ user = 'user', profile }) {
  if (user === 'user') {
    Store.commit('user/updateUserData', profile)
  } else {
    Store.commit('admin/updateAdminData', profile)
  }
}

async function updateWallet() {
  const res = await walletApi.data().getWallet()
  Store.commit('user/setWalletData', res.data.data)
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

function unSlugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, ' ') // Replace spaces with -
    .replace(/-/g, ' ') // Replace - with spaces
    .replace(/--+/g, ' ') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

export { numberWithCommas, updateProfile, updateWallet, slugify, unSlugify, truncate }
