<template>
  <v-container class="pb-10">
    <h1 class="mb-3">Settings</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-dialog max-width="500" v-model="bvnDialog">
      <v-card v-if="toggleView">
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="bvnDialog = false"> mdi-close </v-icon>
        </div>
        <v-card-title>
          <span class="mx-auto">Why do we need your BVN?</span>
        </v-card-title>
        <v-card-text>
          <div>
            <p class="font-weight-bold">Instant bank account</p>
            <p>
              Receive a real bank account for your money and experience
              seamless, secure and swift withdrawals.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large class="primary" @click="toggleView = false"
            >Got It</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <div class="d-flex justify-end mx-4 py-2">
          <v-icon color="error" @click="bvnDialog = false"> mdi-close </v-icon>
        </div>
        <v-card-title>Verify your BVN</v-card-title>
        <v-card-text>
          <v-text-field label="BVN" filled v-model="bvn"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="bvn == ''"
            @click="verifyBVN"
            class="primary"
            :loading="verifyingBvn"
            >Verify</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
          We are a digital bank and just like your regular bank, we need your
          BVN to be able to process transactions. Dial *565*0# on your
          registered phone number to get your BVN.
          <a @click="toggleView = true" class="primary--text"
            >See why we need your BVN</a
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="kycVerificationDialog" max-width="800px">
      <v-card>
        <v-container>
          <div class="d-flex justify-end">
            <v-icon color="error" @click="kycVerificationDialog = false">
              mdi-close
            </v-icon>
          </div>
          <v-stepper class="elevation-0" v-model="kycProgress">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="kycProgress > 1" step="1">
                About
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="kycProgress > 2" step="2">
                Document Upload
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3"> ID details </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-title>Account verification</v-card-title>
                  <v-card-subtitle
                    >Identity verification makes you eligible for coin purchase,
                    promos and offers</v-card-subtitle
                  >
                </v-card>

                <v-btn color="primary" @click="kycProgress = 2">
                  Continue
                </v-btn>

                <v-btn text> Cancel </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form ref="stepTwoValidate" @submit.prevent="stepTwoValidate">
                  <v-card flat>
                    <v-card-title>Document Upload</v-card-title>
                    <v-card-subtitle
                      >To verify your identity, please upload any of your
                      document</v-card-subtitle
                    >
                    <v-divider></v-divider>
                    <v-card-text>
                      <p>
                        In order to complete, please upload any of the following
                        personal document.
                      </p>
                      <v-row>
                        <v-col cols="6" sm="4" md="3">
                          <v-card
                            color="primary"
                            class="pa-1 text-center darken-4"
                            flat
                            outlined
                            elevation="2"
                          >
                            <v-img
                              class="mx-auto"
                              width="55px"
                              src="@/assets/img/icon-national-id-color.png"
                            ></v-img>
                            <span class="overline white--text"
                              >National ID</span
                            >
                          </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                          <v-card
                            color="primary"
                            class="pa-1 text-center darken-4"
                            flat
                            outlined
                            elevation="2"
                          >
                            <v-img
                              class="mx-auto"
                              width="55px"
                              src="@/assets/img/icon-passport-color.png"
                            ></v-img>
                            <span class="overline white--text"
                              >INT. Passport</span
                            >
                          </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                          <v-card
                            color="primary"
                            class="pa-1 text-center darken-4"
                            flat
                            outlined
                            elevation="2"
                          >
                            <v-img
                              class="mx-auto"
                              width="55px"
                              src="@/assets/img/icon-licence-color.png"
                            ></v-img>
                            <span class="overline white--text"
                              >Drivers Licence</span
                            >
                          </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" md="3">
                          <v-card
                            color="primary"
                            class="pa-1 text-center darken-4"
                            flat
                            outlined
                            elevation="2"
                          >
                            <v-img
                              class="mx-auto"
                              width="55px"
                              src="@/assets/img/icon-national-id-color.png"
                            ></v-img>
                            <span class="overline white--text"
                              >Voter's Card</span
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                      <p class="font-weight-bold">
                        To avoid delays when verifying account, Please make sure
                        below:
                      </p>

                      <ul>
                        <li>Chosen credential must not be expired.</li>
                        <li>
                          Document should be good condition and clearly visible.
                        </li>
                        <li>
                          Make sure that there is no light glare on the card.
                        </li>
                      </ul>

                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            label="Front View of Document"
                            prepend-icon="mdi-file-document"
                            counter
                            show-size
                            :rules="[rules.required]"
                            filled
                            clearable
                            v-model="kycData.image1"
                            accept="image/*"
                          ></v-file-input>
                          <v-progress-circular
                            v-if="sendingImage"
                            indeterminate
                          ></v-progress-circular>
                        </v-col>
                        <!-- <v-col cols="12" md="4">
                        <v-img></v-img>
                      </v-col> -->
                        <v-col cols="12">
                          <p>
                            Upload a short <b>video</b> of yourself holding the
                            ID saying
                            <span
                              class="font-weight-bold text--primary text--lighten-2"
                              >I {{ user.fname }} {{ user.lname }}, I am here to
                              trade on Snappy-eXchange.</span
                            >
                          </p>
                          <v-file-input
                            label="Video"
                            accept="video/*"
                            prepend-icon="mdi-file-video"
                            counter
                            v-model="kycData.image2"
                            clearable
                            hint="Please upload only video file"
                            persistent-hint
                            :rules="[rules.required]"
                            show-size
                            filled
                          ></v-file-input>
                          <v-progress-circular
                            v-if="sendingVideo"
                            indeterminate
                          ></v-progress-circular>
                        </v-col>
                        <!-- <v-col cols="12" md="4">
                        <v-img></v-img>
                      </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-btn
                    :disabled="!kycData.image1 || !kycData.image2"
                    color="primary"
                    type="submit"
                  >
                    Continue
                  </v-btn>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-form
                  ref="stepThreeVerification"
                  @submit.prevent="stepThreeVerification"
                >
                  <v-card flat>
                    <v-card-title>ID Details</v-card-title>
                    <v-card-subtitle
                      >Select the ID type, enter the ID number and expiry date
                      on your identification document</v-card-subtitle
                    >
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            :rules="[rules.required]"
                            label="Card Type"
                            v-model="kycData.type"
                            :items="[
                              'Driver\'s Licence',
                              'International Passport',
                              'National Passport',
                              'Voter\'s Card'
                            ]"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :rules="[rules.required]"
                            label="ID Number"
                            v-model="kycData.number"
                            hint="Ensure you enter a complete ID Number."
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="kycData.date"
                            type="date"
                            label="ID Expiry Date"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="kycData.tAndC"
                            :rules="[rules.required]"
                          >
                            <template v-slot:label>
                              <span
                                >I have read the
                                <a href="#" target="blank"
                                  >Terms and Condition</a
                                >
                              </span>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model="kycData.correct"
                            :rules="[rules.required]"
                          >
                            <template v-slot:label>
                              <span
                                >All the personal information I have entered is
                                correct.
                              </span>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-btn
                    color="primary"
                    :loading="sendingVerification"
                    type="submit"
                  >
                    Upload
                  </v-btn>

                  <v-btn
                    text
                    :disabled="sendingVerification"
                    @click="kycProgress = kycProgress - 1"
                  >
                    Back
                  </v-btn>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-sheet>
          <v-tabs
            color="accent"
            v-model="tabs"
            :vertical="$vuetify.breakpoint.smAndDown"
            show-arrows
            class="py-2"
            grow
            :center-active="!$vuetify.breakpoint.smAndDown"
          >
            <v-tab>Profile</v-tab>
            <v-tab>
              {{ userCountry === 'Ghana' ? 'MTN MOMO NUMBER' : 'Bank Account' }}
            </v-tab>
            <v-tab>Notification</v-tab>
            <v-tab>Security</v-tab>
            <v-tab>Verification</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <!-- Profile -->
            <v-tab-item>
              <profile />
            </v-tab-item>
            <!-- Bank Account -->
            <v-tab-item>
              <bank-account />
            </v-tab-item>
            <!-- Notification -->
            <v-tab-item>
              <notification />
            </v-tab-item>
            <!-- Security -->
            <v-tab-item>
              <security />
            </v-tab-item>
            <!-- Verification -->
            <v-tab-item>
              <v-container>
                <h4 class="mb-4 text-h6">Account Upgrade</h4>
                <v-stepper v-model="e6" vertical>
                  <v-stepper-step
                    :color="verify.email ? 'primary' : 'accent'"
                    :complete="verify.email"
                    step="1"
                  >
                    Email Verification
                    <small v-if="!verify.email" class="mt-2"
                      >Your email has not been verified, click on
                      <b>Verify</b> below to verify your email account</small
                    >
                    <small v-else class="mt-2"
                      >Your email has been verified</small
                    >
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-btn
                      color="accent"
                      depressed
                      :loading="sendVerifyEmail"
                      @click="verifyEmail"
                    >
                      Verify
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-step
                    v-if="userCountry !== 'Ghana'"
                    :color="verify.kyc ? 'primary' : 'accent'"
                    :complete="verify.kyc"
                    step="2"
                  >
                    {{
                      kycStatus === 'pending'
                        ? 'Pending KYC Verification'
                        : kycStatus === 'approved'
                        ? 'Approved!'
                        : 'Start Your KYC Verification'
                    }}
                    <small class="mt-2"
                      >In a Bid to curb and prevent the activities of fraudsters
                      from using the Snappy-eXchange platform for any
                      illegal/fraudulent activities, it has now become
                      imperative we know our website users. We would be
                      capturing your personal photo and your ID card. Kindly
                      click on the proceed button to verify your account.</small
                    >
                  </v-stepper-step>

                  <v-stepper-content v-if="userCountry !== 'Ghana'" step="2">
                    <v-btn
                      v-if="kycStatus !== 'pending'"
                      color="primary"
                      @click.stop="kycVerificationDialog = true"
                    >
                      Start
                    </v-btn>
                    <!-- <v-btn text> Cancel </v-btn> -->
                  </v-stepper-content>
                </v-stepper>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BankAccount from '../../components/dashboard/settings/bankAccount.vue'
import Notification from '../../components/dashboard/settings/notification.vue'
import profile from '../../components/dashboard/settings/profile.vue'
import Security from '../../components/dashboard/settings/security.vue'
import api from '../../api/users/auth'
import rules from '../../utils/rules'
import kycApi from '../../api/users/kyc'
import profileApi from '../../api/users/profile'

import { mapState } from 'vuex'
import { updateProfile } from '../../utils/helpers'

export default {
  components: { profile, BankAccount, Notification, Security },

  data: () => ({
    rules,
    toggleView: false,
    kycProgress: 1,
    kycVerificationDialog: false,
    bvnDialog: false,
    sendingVideo: false,
    sendingImage: false,
    message: null,
    errorSendingEmail: false,
    sendVerifyEmail: false,
    sendingVerification: false,
    dialogPassword: false,
    dialogDelete: false,
    tabs: null,
    bvn: '',
    dialog: false,
    verifyingBvn: false,
    kycData: {},
    cloudName: 'snappyexchange',
    preset: 'rwy1auea',
    tags: 'kyc',
    imageUploadProgress: 0,
    showImageUploadProgress: false,
    videoUploadProgress: 0,
    fileContents: null,
    formData: null,
    kycs: []
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    }),
    kycStatus() {
      if (this.kycs.length === 0) return 'declined'
      let approved = 0
      let pending = 0

      for (const kyc of this.kycs) {
        if (kyc.status === 'approved') {
          approved = approved + 1
          break
        }
        if (kyc.status === 'pending') {
          pending = pending + 1
          break
        }
      }

      if (approved > 0) return 'approved'
      if (pending > 0) return 'pending'

      return 'declined'
    },
    verify() {
      return {
        email: this.user.email_verify,
        kyc: this.user.kycverify,
        bvn: !!this.user.bvnVerified
      }
    },
    e6() {
      if (this.verify.email) {
        if (this.verify.kyc) {
          return 3
        }
        return 2
      }
      return 1
    }
  },
  mounted() {
    this.getKYCStatus()
    this.tabs = Number(this.$route.query.tab)
  },

  methods: {
    async getKYCStatus() {
      const res = await kycApi.kyc().getKYCStatus()

      this.kycs = res.data.data
    },
    async verifyBVN() {
      this.verifyingBvn = true
      const res = await profileApi.profile().verifyBVN({ bvn: this.bvn })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        const profile = await profileApi.profile().get()
        updateProfile({ profile: profile })
        this.verifyingBvn = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Your BVN has been verified!',
        status: true
      })
      const profile = await profileApi.profile().get()
      updateProfile({ profile: profile })
      this.verifyingBvn = false
      this.bvnDialog = false
      this.bvn = ''
    },
    prepareFormData(file) {
      this.formData = new FormData()
      this.formData.append('upload_preset', this.preset)
      this.formData.append('tags', this.tags)
      this.formData.append('file', file)
    },
    async sendVerification() {
      this.sendingVerification = true
      const res = await kycApi.kyc().sendVerification({ ...this.kycData })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.sendingVerification = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'KYC Verification sent!',
        status: true
      })
      const profile = await profileApi.profile().get()
      updateProfile({ profile: profile })
      this.getKYCStatus()
      this.sendingVerification = false
      this.kycVerificationDialog = false
      this.kycData = {}
    },
    stepThreeVerification() {
      if (this.$refs.stepThreeVerification.validate()) this.sendVerification()
    },
    stepTwoValidate() {
      if (this.$refs.stepTwoValidate.validate())
        this.kycProgress = this.kycProgress + 1
    },
    async verifyEmail() {
      this.sendVerifyEmail = true
      const res = await api.auth().sendVerificationEmail(this.user.email)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.sendVerifyEmail = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'Email verification sent! Kindly check your email',
        status: true
      })
      this.sendVerifyEmail = false
    },
    copy() {}
  }
}
</script>

<style></style>
