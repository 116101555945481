import axios from 'axios'
import Compressor from 'compressorjs'

import Store from '../../store/index'
import { errorHandler, successHandler } from '../helpers/handler'
import { BASEURL } from '../const'

const URL = `${BASEURL}/giftcards`

export default {
  giftcards() {
    return {
      create: async (data) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      edit: async ({ cardId, data }) => {
        const endpoint = `${URL}/${cardId}`
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      addImageToGiftcard: async ({ cardId, image }) => {
        const endpoint = `${URL}/${cardId}/image`

        // eslint-disable-next-line no-new
        new Compressor(image, {
          quality: 0.6,
          success(result) {
            console.log({ image, result })

            const formData = new FormData()
            formData.append('image', result)
            axios
              .post(endpoint, formData, {
                headers: {
                  ...Store.getters['admin/getRequestHeaders'].headers,
                  'content-type': 'multipart/form-data'
                }
              })
              .then((res) => {
                return successHandler(res)
              })
              .catch((error) => {
                return errorHandler(error)
              })
          }
        })
      },
      /**
       * Get all giftcards
       */
      getAll: async () => {
        const endpoint = `${URL}`
        try {
          const res = axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       * Get a certain giftcard
       * @param {*} param0 CardId
       */
      getGiftCard: async ({ cardId }) => {
        const endpoint = `${URL}/${cardId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       * Delate a giftcard
       * @param {*} {cardId}
       */
      deleteGiftCard: async ({ cardId }) => {
        const endpoint = `${URL}/${cardId}`
        try {
          const res = axios.delete(endpoint, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      /**
       * Create a giftcard type
       * These are card type options that may be replicated across board when creating gift cards. E.g Physical Card
       *
       * @param {*} param0 name
       * @param {*} param1 image
       */
      createGiftCardType: async ({ name, image, details }) => {
        const endpoint = `${URL}/type`
        const formData = new FormData()
        formData.append('name', name)
        formData.append('image', image)
        formData.append('details', details)
        try {
          const res = await axios.post(endpoint, formData, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateGiftCardType: async ({ cardTypeId, name, image, details }) => {
        const endpoint = `${URL}/type/${cardTypeId}`
        const formData = new FormData()
        formData.append('name', name)
        formData.append('image', image)
        formData.append('details', details)
        try {
          const res = await axios.put(endpoint, formData, {
            headers: {
              ...Store.getters['admin/getRequestHeaders'].headers,
              'content-type': 'multipart/form-data'
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
