<template>
  <v-container class="pb-10">
    <div
      v-if="!checkIfPermission('view dashboard', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div v-else>
      <v-overlay opacity="0.8" :value="loading">
        <v-progress-circular indeterminate size="65"></v-progress-circular>
      </v-overlay>
      <div class="d-flex justify-space-between align-center mb-3">
        <h1>Overview</h1>
        <v-btn @click="getSiteData('forced')" color="primary"> Refresh </v-btn>
      </div>
      <div>
        <v-select
          v-model="selectedCountry"
          :items="['Nigeria', 'Ghana', 'Kenya']"
          label="Country"
        />
      </div>

      <div>
        <v-row class="mt-5">
          <v-col cols="12" md="4">
            <v-card color="light-blue" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col class="py-1">
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      TOTAL AMOUNT IN USERS WALLET
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h5 font-weight-bold white--text"
                        >{{ vuNumberWithCommas(siteInfo.totalBalance) }}</span
                      >
                      <span class="caption white--text"> {{ currency }}</span>
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }} {{ siteInfo.pendingWithdraws }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending Withdraw</small
                    >
                  </v-col>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }}
                      {{ siteInfo.totalTransfers }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Total Transfers</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="teal" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col class="py-1">
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      APPROVED SOLD CRYPTOCURRENCY
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h5 font-weight-bold white--text"
                        >{{
                          vuNumberWithCommas(siteInfo.approvedSoldCrypto)
                        }}</span
                      >
                      <span class="caption white--text"> {{ currency }}</span>
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }}
                      {{ vuNumberWithCommas(siteInfo.pendingSoldCrypto) }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending</small
                    >
                  </v-col>
                  <!-- <v-col class="py-0" cols="12" md="4">
                  <p
                    style="font-size: 16px !important"
                    class="text-h6 white--text mb-0"
                  >
                    N {{ vuNumberWithCommas(siteInfo.approvedSoldCrypto) }}
                  </p>
                  <small class="white--text opacity-70 mt-0 text--darken-1"
                    >Approved</small
                  >
                </v-col> -->
                  <v-col class="py-0" cols="12" md="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }}
                      {{ vuNumberWithCommas(siteInfo.declinedSoldCrypto) }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Declined</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="orange" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col class="py-1">
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      APPROVED PURSCHASED CRYPTOCURRENCY
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h5 font-weight-bold white--text"
                        >{{
                          vuNumberWithCommas(siteInfo.approvedPurchasedCrypto)
                        }}
                      </span>
                      <span class="caption white--text"> {{ currency }}</span>
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }}
                      {{ vuNumberWithCommas(siteInfo.pendingPurchasedCrypto) }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending</small
                    >
                  </v-col>
                  <!-- <v-col class="py-0" cols="6">
                  <p
                    style="font-size: 16px !important"
                    class="text-h6 white--text mb-0"
                  >
                    N {{ vuNumberWithCommas(siteInfo.approvedPurchasedCrypto) }}
                  </p>
                  <small class="white--text opacity-70 mt-0 text--darken-1"
                    >Approved</small
                  >
                </v-col> -->
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency }}
                      {{ vuNumberWithCommas(siteInfo.declinedPurchasedCrypto) }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Declined</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="brown" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col>
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      APPROVED SOLD GIFTCARD
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h5 font-weight-bold white--text"
                        >{{
                          vuNumberWithCommas(siteInfo.approvedSoldGiftcard)
                        }}</span
                      >
                      <span class="caption white--text"> {{ currency }}</span>
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{ vuNumberWithCommas(siteInfo.pendingSoldGiftcard) }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending</small
                    >
                  </v-col>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{ vuNumberWithCommas(siteInfo.declinedSoldCrypto) }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Declined</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="blue" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col class="py-1">
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      TOTAL SNAPPY TOKEN
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h4 font-weight-bold white--text"
                        >{{
                          vuNumberWithCommas(siteInfo.totalTokenConversions)
                        }}</span
                      >
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{
                        vuNumberWithCommas(siteInfo.pendingTokenConversions)
                      }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending</small
                    >
                  </v-col>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{
                        vuNumberWithCommas(siteInfo.declinedTokenConversions)
                      }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Declined</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="red" rounded="lg" class="h-100 pa-2">
              <v-container>
                <v-row>
                  <v-col class="py-1">
                    <p
                      style="font-size: 14px"
                      class="text-uppercase opacity-60 text-uppercase white--text mb-0"
                    >
                      TOTAL BONUS
                    </p>
                    <p class="mb-0">
                      <span
                        style="font-size: 18px !important"
                        class="text-h4 font-weight-bold white--text"
                        >{{
                          vuNumberWithCommas(siteInfo.totalBonusConversions)
                        }}</span
                      >
                      <span class="caption white--text"> {{ currency }}</span>
                    </p>
                  </v-col>
                </v-row>
                <p
                  style="font-size: 14px; margin-top: 5px"
                  class="white--text opacity-60 mb-0"
                >
                  Summary
                </p>
                <v-row>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{
                        vuNumberWithCommas(siteInfo.pendingBonusConversions)
                      }}
                    </p>
                    <small class="white--text opacity-70 mt-0 text--darken-1"
                      >Pending</small
                    >
                  </v-col>
                  <v-col class="py-0" cols="6">
                    <p
                      style="font-size: 16px !important"
                      class="text-h6 white--text mb-0"
                    >
                      {{ currency
                      }}{{
                        vuNumberWithCommas(siteInfo.declinedBonusConversions)
                      }}
                    </p>
                    <small class="white--text opacity-70 text--darken-1"
                      >Declined</small
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" md="12">
            <v-card rounded="lg" :loading="messages.length == 0" class="h-100">
              <v-toolbar color="indigo">
                <v-card-text class="white--text"
                  >Request &amp; Messages</v-card-text
                >
                <v-spacer></v-spacer>
                <v-btn small to="/admin/admin/messages/customers-request"
                  >View Message</v-btn
                >
              </v-toolbar>
              <v-divider></v-divider>
              <v-virtual-scroll
                v-if="checkIfPermission('view message', admin.permissions)"
                :items="messages"
                :item-height="75"
                height="270"
              >
                <template v-slot:default="{ item }">
                  <v-list-item
                    :to="`/admin/admin/messages/view/${item._id}`"
                    three-line
                    class="my-2"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="accent" size="56" class="white--text">
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.sender
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{
                        item.date
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        :to="`/admin/messages/view/${item._id}`"
                        depressed
                        small
                      >
                        View Message
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
              <p class="text-center pa-3" v-else>
                No don't have access to messages
              </p>
            </v-card>
          </v-col> -->
          <v-col cols="12" md="6">
            <v-card rounded="lg" color="black" class="pa-5 h-100 white--text">
              <v-row>
                <v-col cols="12" md="6">
                  <p class="overline white--text opacity-60 mb-0">
                    TOTAL USERS
                  </p>
                  <p class="mb-0">
                    <span
                      style="font-size: 18px !important"
                      class="text-h5 font-weight-bold white--text"
                      >{{ siteInfo.totalUsers }}</span
                    >
                    <span class="white--text opacity-80"> Users</span>
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="overline white--text opacity-60 mb-0">
                    Active Users
                  </p>
                  <p class="mb-0">
                    <span
                      style="font-size: 18px !important"
                      class="text-h5 font-weight-bold white--text"
                      >{{ siteInfo.totalActiveUsers }}</span
                    >
                    <span class="white--text opacity-80"> Users</span>
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="overline white--text opacity-60 mb-0">
                    Verified Users
                  </p>
                  <p class="mb-0">
                    <span
                      style="font-size: 18px !important"
                      class="text-h5 font-weight-bold white--text"
                      >{{ siteInfo.totalVerifiedUsers }}</span
                    >
                    <span class="white--text opacity-80"> Users</span>
                  </p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="overline white--text opacity-60 mb-0">
                    Suspended Users
                  </p>
                  <p class="mb-0">
                    <span
                      style="font-size: 18px !important"
                      class="text-h5 font-weight-bold white--text"
                      >{{ siteInfo.totalInactiveUsers }}</span
                    >
                    <span class="white--text opacity-80"> Users</span>
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>Recent Users</v-card-title>
              <v-card-text>
                <v-virtual-scroll
                  :items="resentUsers"
                  height="150"
                  item-height="70"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item three-line class="my-2">
                      <v-list-item-avatar>
                        <v-avatar color="accent" size="56" class="white--text">
                          <v-img :src="item.image"></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{
                          item.username
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.email
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <span style="font-size: 12px">{{
                            new Date(item.created_at || item.createdAt)
                          }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import dataApi from '../../api/systemData'
import userApi from '../../api/admin/userManagement'
import { numberWithCommas } from '../../utils/helpers'
import { mapState } from 'vuex'
import { getCurrencySymbol } from '../../utils/countryHelpers'

export default {
  data: () => ({
    messages: [],
    data: {},
    loading: false,
    resentUsers: [],
    selectedCountry: 'Nigeria'
  }),

  computed: {
    ...mapState({
      siteInfo: (state) => state.admin.siteInfo,
      admin: (state) => state.admin.admin
    }),
    currency() {
      return getCurrencySymbol(this.selectedCountry)
    }
  },
  created() {
    this.getSiteData('normal')
    // this.getMessages()
    this.getMostResetUsers()
  },
  watch: {
    selectedCountry(val) {
      this.getSiteData('forced')
    }
  },
  methods: {
    vuNumberWithCommas(number) {
      return numberWithCommas(number)
    },
    async getSiteData(type) {
      if (type === 'forced') {
        this.loading = true
        const res = await dataApi.data().dashboardInfo({
          country: this.selectedCountry
        })
        this.$store.commit('admin/setSiteInfo', res.data.data)
        this.loading = false
        return
      }
      if (Object.keys(this.siteInfo).length === 0) {
        this.loading = true
      }
      const res = await dataApi.data().dashboardInfo({
        country: this.selectedCountry
      })
      this.$store.commit('admin/setSiteInfo', res.data.data)
      this.loading = false
    },
    async getMostResetUsers() {
      const res = await userApi.users().getAll('true', { limit: 5 })
      // console.log(res.data.data)
      this.resentUsers = res.data.data.data
    },
    async getMessages() {
      const res = await userApi
        .users()
        .getMessages('received', { admin: false })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.messages = res.data.data.data.map((message) => ({
        ...message,
        sender: message.user.username,
        title: message.details,
        date: new Date(message.createdAt).toDateString()
      }))
    }
  }
}
</script>

<style></style>
