<template>
  <div class="w-100 h-100">
    <div v-if="checkIfPermission('view purchase', admin.permissions)">
      <router-view></router-view>
    </div>
    <div v-else class="w-100 h-100 d-flex justify-center align-center">
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  }
}
</script>

<style></style>
